/* eslint-disable no-unused-vars */
import React from "react";
import { AlertDialog, Box, ButtonIcon, FormWithValidation } from "_components";
import { PropTypes } from "prop-types";
import { Input } from "_components/Input";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import {
  PersonnelService,
  CiviliteService,
  AttributionSpecifiqueService,
  StatutsPersonnelService,
  PositionService,
  CoefficientService,
  ServicePersonnelService,
  TypeContratService,
  FonctionPersonnelService,
  FonctionInformatiqueService,
  ProfilInformatiqueService,
  DiplomeService,
  PoleService,
  ModesTeletravailService,
} from "_services";

import { Helmet } from "react-helmet";

class AjoutPersonnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      personnel: {
        id: 0,
        num_Personnel: 0,
        nom: null,
        prenom: null,
        initiales: null,
        civilite: null,
        date_Naissance: null,
        photo: null,
        actif: true,
        service: null,
        pole: null,
        fonction: null,
        attribution_Specifique: null,
        sst: false,
        type_Contrat: null,
        statut: null,
        position: null,
        coefficient: null,
        date_Embauche: null,
        date_Fin_De_Contrat: null,
        date_Fin_Periode_Essai: null,
        responsable: null,
        matricule: null,
        taux_Horaire: null,
        temps_Partiel: null,
        diplome: null,
        fonction_Informatique: null,
        profil_Informatique: null,
        ligne_Pro: null,
        mobile_Pro: null,
        email: null,
        teletravail: null,
        compte_Comptable: null,
        code_Comptable: null,
        code_Journal: null,
        nationalite: "Française",
        fin_Titre_Sejour: null,
        permis: false,
        rib: null,
        immatriculation_Vehicule: null,
        adresse: null,
        portable: null,
        email_Pero: null,
        fixe: null,
        fiche_Renseignement: false,
        date_Derniere_MAJ_Fiche_Renseignement: null,
        date_Entretien_Indiv: null,
        disciplinaire: null,
        incidence: null,
        arret_Maladie: null,
        actif: true,
        date_Modif: null,
      },
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    this.setState((state) => ({
      loaded: true,
      personnel: {
        ...state.personnel,
        client: this.props.history.location.state
          ? this.props.history.location.state.client
          : null,
      },
    }));
  }

  handleStateChange(
    accessor,
    value = "__ACCES_DIRECT__",
    afterUpdateFunction = null
  ) {
    if (value != "__ACCES_DIRECT__") {
      let updatedConsultation = { ...this.state.personnel };
      updatedConsultation[accessor] = value;
      this.setState({ personnel: updatedConsultation }, afterUpdateFunction);
    } else {
      this.setState(
        { personnel: { ...this.state.personnel, ...accessor } },
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    PersonnelService.post(this.state.personnel)
      .then((retour) => {
        this.props.history.push("/personnels/" + retour.data.id);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error.message,
        });
      });
  }

  handleClose() {
    this.setState({ error: false });
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.state) && isEqual(prevStates, this.state));
  }

  form() {
    const informationsGeneral = [
      {
        label: "Civilité",
        accessor: "civilite",
        type: "selectSearch",
        service: CiviliteService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 4,
      },
      {
        label: "Nom",
        accessor: "nom",
        type: "text",
        colSize: 4,
      },
      {
        label: "Prénom",
        accessor: "prenom",
        type: "text",
        colSize: 4,
      },
      {
        label: "Initiales",
        accessor: "initiales",
        type: "text",
        colSize: 4,
      },
      {
        label: "Responsable (initiales)",
        accessor: "responsable",
        type: "text",
        colSize: 4,
      },
    ];
    const informationsContrat = [
      {
        label: "Service",
        accessor: "service",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: ServicePersonnelService.getAll,
        colSize: 4,
      },
      {
        label: "Pôle",
        accessor: "pole",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: PoleService.getAll,
        colSize: 4,
      },
      {
        label: "Fonction",
        accessor: "fonction",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: FonctionPersonnelService.getAll,
        colSize: 4,
      },
      {
        label: "Date d'entrée",
        accessor: "date_Embauche",
        type: "date",
        colSize: 4,
      },
      {
        label: "Date de fin de période d'essai",
        accessor: "date_Fin_Periode_Essai",
        type: "date",
        colSize: 4,
      },
      {
        label: "Date de sortie",
        accessor: "date_Fin_De_Contrat",
        type: "date",
        colSize: 4,
      },
      {
        label: "Type de contrat",
        accessor: "type_Contrat",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: TypeContratService.getAll,
        colSize: 4,
      },
      {
        label: "Statut",
        accessor: "statut",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: StatutsPersonnelService.getAll,
        colSize: 4,
      },
      {
        label: "Position",
        accessor: "position",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: PositionService.getAll,
        colSize: 4,
      },
      {
        label: "Coefficient",
        accessor: "coefficient",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: CoefficientService.getAll,
        colSize: 4,
      },
      {
        label: "Temps partiel",
        accessor: "temps_Partiel",
        type: "text",
        colSize: 4,
      },
      // {
      //   label: "Jours travaillé (TODO)",
      //   accessor: "jours_Travaille",
      //   type: "text",
      //   colSize: 4,
      // },
      {
        label: "Télétravail",
        accessor: "teletravail",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: ModesTeletravailService.getAll,
        colSize: 4,
      },
    ];
    const InformationsContact = [
      {
        label: "Ligne pro",
        accessor: "ligne_Pro",
        type: "text",
        colSize: 4,
      },
      {
        label: "Mobile",
        accessor: "mobile_Pro",
        type: "text",
        colSize: 4,
      },
      {
        label: "Email",
        accessor: "email",
        type: "email",
        colSize: 4,
      },
      {
        label: "Email Pro",
        accessor: "email_Pro",
        type: "email",
        colSize: 4,
      },
      {
        label: "Immatriculation Vehicule",
        accessor: "immatriculation_Vehicule",
        type: "text",
        colSize: 4,
      },
    ];
    const informationsFormation = [
      {
        label: "Date de naissance",
        accessor: "date_Naissance",
        type: "text",
        type: "date",
        colSize: 4,
      },
      {
        label: "Diplôme",
        accessor: "diplome",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: DiplomeService.getAll,
        colSize: 4,
      },
      {
        label: "Nationalité",
        accessor: "nationalite",
        type: "text",
        colSize: 4,
      },
      {
        label: "Fin du titre de séjour",
        accessor: "fin_Titre_Sejour",
        type: "date",
        colSize: 4,
      },
      {
        label: "Permis",
        accessor: "permis",
        type: "checkbox",
        colSize: 4,
      },
      {
        label: "RIB",
        accessor: "rib",
        type: "text",
        colSize: 4,
      },
    ];
    const informationsCompta = [
      {
        label: "SST",
        accessor: "sst",
        type: "checkbox",
        colSize: 4,
      },
      {
        label: "Attribution spécifique",
        accessor: "attribution_Specifique",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: AttributionSpecifiqueService.getAll,
        colSize: 4,
      },
      {
        label: "Numéro matricule",
        accessor: "matricule",
        type: "text",
        colSize: 4,
      },
      {
        label: "Taux horaire",
        accessor: "taux_Horaire",
        type: "decimal",
        colSize: 4,
      },
      {
        label: "Fonction informatique",
        accessor: "fonction_Informatique",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: FonctionInformatiqueService.getAll,
        colSize: 4,
      },
      {
        label: "Profil informatique",
        accessor: "profil_Informatique",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: ProfilInformatiqueService.getAll,
        colSize: 4,
      },
      {
        label: "Code journal",
        accessor: "code_Journal",
        type: "text",
        colSize: 4,
      },
      {
        label: "Compte compta",
        accessor: "compte_Comptable",
        type: "text",
        colSize: 4,
      },
      {
        label: "Code Compta",
        accessor: "code_Comptable",
        type: "text",
        colSize: 4,
      },
    ];
    const infos = [
      { info: informationsGeneral, titre: "Informations générales" },
      { info: informationsContrat, titre: "Informations sur le contrat" },
      { info: InformationsContact, titre: "Contact" },
      { info: informationsFormation, titre: "Informations sur la formation" },
      { info: informationsCompta, titre: "Informations comptables" },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div
                            className={"col-lg-" + field.colSize + " pt-2"}
                            key={fieldsIndex}
                          >
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleStateChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              // Non implémenté
                              // creationRedirectionFunction={
                              //   field.creationRedirectionFunction
                              // } // fonction permettant la redirection pour ajouter un élément à la liste déroulante
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer le personnel"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'un personnel</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du personnel a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div className="p-3 m-4" id="ajoutConsultation">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;un personnel
              {this.state.personnel.client
                ? " pour " + this.state.personnel.client.nom_Client
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4 mx-1">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutPersonnel.propTypes = {
  history: PropTypes.object,
};

export { AjoutPersonnel };
