import React from "react";
import { App } from "App";

import { LigneNdF } from "./LigneNdF";

import { PropTypes } from "prop-types";

import { Box, ButtonIcon, WarningBar } from "_components";
import { Input } from "_components/Input";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faFileExport,
  faDownload,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import { NoteDeFraisService } from "_services";
import * as FileSaver from "file-saver";

class NotesDeFrais extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedPersonnel: null,
    };

    this.handleClickEditionNotesDeFrais =
      this.handleClickEditionNotesDeFrais.bind(this);
    this.handleClickUndoNotesDeFrais =
      this.handleClickUndoNotesDeFrais.bind(this);
    this.handleClickValidateNotesDeFrais =
      this.handleClickValidateNotesDeFrais.bind(this);
    this.handleChangeDateFilter = this.handleChangeDateFilter.bind(this);
  }

  handleClickEditionNotesDeFrais() {
    this.setState({
      savedcb: { ...this.props.cartebancaire },
    });
    this.props.handleEditingNotesDeFrais(true);
  }

  handleClickUndoNotesDeFrais() {
    this.props.handleChange("cartebancaire", this.state.savedcb);
    this.props.handleEditingNotesDeFrais(false);
  }

  handleClickValidateNotesDeFrais() {
    this.props.handleUpdate();
    this.props.handleEditingNotesDeFrais(false);
  }

  handleChangeDateFilter(acc, date) {
    this.props.filtrerSelonDate?.(date);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Cartes bleues",
      "Notes de frais"
    );

    const rightExtraction = App.RightsGuard?.current?.hasRight(
      "Cartes bleues",
      "Extraction"
    );

    const rightCreationNDF = App.RightsGuard?.current?.hasRight(
      "Cartes bleues",
      "CreationNdF"
    );

    const rightSuppression = App.RightsGuard?.current?.hasRight(
      "Cartes bleues",
      "Suppression"
    );

    const rightImport = App.RightsGuard?.current?.hasRight(
      "Affaire",
      "Import excel"
    );

    if (right == "RW" || right == "RO") {
      let total = 0;
      this.props.cartebancaire.notesDeFrais?.map(
        (ndf) => (total = total + (ndf.export ? ndf.montant_TTC : 0))
      );
      let start;
      let end;
      let dateArr = this.props.dateFiltreNotesDeFraisAffichees?.split("|");
      if (this.props.dateFiltreNotesDeFraisAffichees) {
        start = dateArr[0];
        end = dateArr[1];
      }
      return (
        <>
          {rightImport == "RW" ? (
            <ButtonIcon
              icon={faFileUpload}
              onClick={() => {
                this.props.history.push("/notesDeFraisCb/");
              }}
              className="btn btn-primary text-light mt-1"
              textOption=" Import notes de frais"
            ></ButtonIcon>
          ) : null}
          <div className="row">
            <div className="col-12 d-flex">
              <Box
                header={
                  <>
                    <div className="col-6 align-self-center py-2">
                      <div>Note de frais</div>
                    </div>
                    <div className="col-6 text-end py-2">
                      <ButtonIcon
                        icon={faPlus}
                        iconSize="sm"
                        onClick={() => this.props.addLineNoteDeFrais?.()}
                        className="btn btn-success addHeure"
                        style={
                          !this.props.editingNotesDeFrais ||
                          rightCreationNDF != "RW"
                            ? { display: "none" }
                            : {}
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success text-light ms-1"
                        style={
                          !this.props.editingNotesDeFrais
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        onClick={this.handleClickValidateNotesDeFrais}
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger text-light ms-1"
                        onClick={this.handleClickUndoNotesDeFrais}
                        style={
                          !this.props.editingNotesDeFrais
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary text-light ms-1"
                        onClick={this.handleClickEditionNotesDeFrais}
                        style={
                          this.props.editing
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        disabled={!(right == "RW")}
                      ></ButtonIcon>
                    </div>
                  </>
                }
                body={
                  <>
                    <div className="col-4 align-self-center py-2">
                      Filtrer selon la période du :
                      <Input
                        value={this.props.dateFiltreNotesDeFraisAffichees}
                        type="period"
                        handleChange={this.handleChangeDateFilter}
                      />
                    </div>
                    <div className="col-4 align-self-center py-2">
                      <div>
                        Nombre de lignes sélectionnées:{" "}
                        {
                          this.props.cartebancaire.notesDeFrais?.filter(
                            (e) => e.export
                          ).length
                        }{" "}
                      </div>
                      <div>
                        Total des lignes sélectionnées :{" "}
                        {Number(total.toFixed(2))}{" "}
                      </div>
                      <ButtonIcon
                        icon={faFileExport}
                        className="btn btn-secondary text-light ms-1"
                        onClick={() => {
                          this.props.openDialogue();
                        }}
                        style={{ width: "42px" }}
                        tooltip={
                          this.props.editing ||
                          this.props.cartebancaire.notesDeFrais?.filter(
                            (e) => e.export
                          ).length != 0
                            ? "Extraire et numéroter"
                            : "Vous ne pouvez pas extraire car aucune ligne n'est sélectionnée"
                        }
                        disabled={
                          rightExtraction != "RW" ||
                          this.props.cartebancaire?.notesDeFrais?.filter(
                            (e) => e.export
                          )?.length < 1
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faDownload}
                        className="btn btn-secondary text-light ms-1"
                        onClick={() => {
                          let exportCB =
                            this.props.cartebancaire.notesDeFrais.filter(
                              (e) => e.export
                            );
                          NoteDeFraisService.extract(exportCB).then((res) => {
                            var blob = new Blob([res?.data], {
                              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            FileSaver.saveAs(blob, "extractionNdF" + ".xlsx");
                          });
                        }}
                        style={{ width: "42px" }}
                        tooltip={
                          this.props.editing ||
                          this.props.cartebancaire.notesDeFrais?.filter(
                            (e) => e.export
                          ).length != 0
                            ? "Extraire"
                            : "Vous ne pouvez pas extraire car aucune ligne n'est sélectionnée"
                        }
                        disabled={
                          rightExtraction != "RW" ||
                          this.props.cartebancaire?.notesDeFrais?.filter(
                            (e) => e.export
                          )?.length < 1
                        }
                      ></ButtonIcon>
                    </div>

                    <table className="mt-1 table table-sm table-striped table-bordered">
                      <thead>
                        <tr>
                          <th className="ps-2" style={{ width: "30px" }}>
                            <Input
                              accessor={"exportAll"}
                              type="checkbox"
                              placeholder=""
                              value={this.props.exportAll}
                              handleChange={this.props.handleChange}
                              showValidator={false}
                              showClearButton={false}
                            />
                          </th>
                          <th className="ps-2" style={{ width: "135px" }}>
                            Numéro de Frais
                          </th>
                          <th className="ps-2">Libelle</th>
                          <th className="ps-2">Affaire</th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Montant TTC
                          </th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Montant TVA
                          </th>
                          <th className="ps-2">Rubrique</th>
                          <th className="ps-2" style={{ maxWidth: "100px" }}>
                            Nombre
                          </th>
                          <th className="ps-2">Date de Frais</th>
                          <th className="ps-2">BDC Existant</th>

                          <th style={{ width: "41px" }}></th>
                          <th style={{ width: "41px" }}></th>
                          <th
                            style={
                              rightSuppression != "RW"
                                ? { display: "none" }
                                : { width: "41px" }
                            }
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.cartebancaire.notesDeFrais
                          ?.slice()
                          ?.filter((e) => e.new)

                          .map((ndf) => (
                            <LigneNdF
                              key={ndf.id}
                              ndf={ndf}
                              handleChange={this.props.handleChange}
                              editing={this.props.editingNotesDeFrais}
                              deleteLineNoteDeFrais={
                                this.props.deleteLineNoteDeFrais
                              }
                              copierLineNotesDeFrais={
                                this.props.copierLineNotesDeFrais
                              }
                              collerLineNotesDeFrais={
                                this.props.collerLineNotesDeFrais
                              }
                              rightSuppression={rightSuppression}
                            />
                          ))}
                        {this.props.cartebancaire.notesDeFrais
                          ?.filter((e) => {
                            let date = new Date(e.dateFrais);
                            if (!this.props.dateFiltreNotesDeFraisAffichees)
                              return (
                                !e.new &&
                                date >
                                  new Date().setMonth(new Date().getMonth() - 2)
                              );
                            else {
                              var bool = true;
                              if (start && start !== "undefined") {
                                bool = bool && date >= new Date(start);
                              }
                              if (end && end !== "undefined")
                                bool = bool && date <= new Date(end);

                              return bool && !e.new;
                            }
                          })
                          .slice()
                          .sort(
                            (a, b) =>
                              new Date(b.dateFrais) - new Date(a.dateFrais)
                          )
                          .map((ndf) => (
                            <LigneNdF
                              key={ndf.id}
                              ndf={ndf}
                              handleChange={this.props.handleChange}
                              editing={this.props.editingNotesDeFrais}
                              deleteLineNoteDeFrais={
                                this.props.deleteLineNoteDeFrais
                              }
                              copierLineNotesDeFrais={
                                this.props.copierLineNotesDeFrais
                              }
                              collerLineNotesDeFrais={
                                this.props.collerLineNotesDeFrais
                              }
                              rightSuppression={rightSuppression}
                            />
                          ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

NotesDeFrais.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { NotesDeFrais };
