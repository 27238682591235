import React from "react";
import { App } from "App";

import { Box, WarningBar } from "_components";

import { AccordionDevis } from "./AccordionDevis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class Devis extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Devis");
    if (right == "RW" || right == "RO") {
      if (this.props.loadingDevisCommandes) {
        return (
          <div className="d-flex justify-content-center py-2">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        );
      }
      var arrDevisIds = [];
      var arrDevis = [];
      this.props.affaire.commandes?.forEach((c) => {
        if (c.devis != null && !arrDevisIds.includes(c.devis.id)) {
          arrDevisIds.push(c.devis.id);
          arrDevis.push(c.devis);
        }
      });

      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Devis</div>
              </div>
            }
            body={
              <table className="table table-sm table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (arrDevis?.length == 0 ? " theadBorderRadius" : "")
                  }
                >
                  <tr>
                    <th>Num</th>
                    <th>Date</th>
                    <th>Réf. article</th>
                    <th>Description</th>
                    <th>Quantité</th>
                    <th>Prix Unitaire</th>
                    <th>Prix HT</th>
                    <th>Prix TTC</th>
                  </tr>
                </thead>
                <tbody>
                  {arrDevis
                    ?.slice()

                    .sort(
                      (a, b) =>
                        new Date(b.date_Create) - new Date(a.date_Create)
                    )
                    .map((devis, index) => (
                      <AccordionDevis index={index} devis={devis} key={index} />
                    ))}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Devis };
