import React from "react";

import {
  ProduitInterneService,
  PersonnelService,
  MissionService,
  InterneExterneService,
  ClientService,
  FamilleAffaireService,
  ContactService,
  StatutAffaireService,
} from "_services";
import { App } from "App";

import {
  BoxInformationsInline,
  ButtonIcon,
  WarningBar,
  Box,
} from "_components";
import { ToLocaleDateString, IfNullToText } from "_utils";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

class InformationsAffaire extends React.PureComponent {
  constructor(props) {
    super(props);

    this.serviceGetContactsByClientId =
      this.serviceGetContactsByClientId.bind(this);

    this.setForceUpdateContactMaitreDOeuvre =
      this.setForceUpdateContactMaitreDOeuvre.bind(this);
    this.setForceUpdateContactMaitreDOuvrage =
      this.setForceUpdateContactMaitreDOuvrage.bind(this);
    this.setForceUpdateContactEntreprise =
      this.setForceUpdateContactEntreprise.bind(this);
  }

  serviceGetContactsByClientId(clientId) {
    if (clientId) {
      return () => {
        return ContactService.getByIdClient(clientId);
      };
    }
  }

  setForceUpdateContactMaitreDOeuvre(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOeuvre: callbackFunction,
    });
  }

  setForceUpdateContactMaitreDOuvrage(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOuvrage: callbackFunction,
    });
  }

  setForceUpdateContactEntreprise(callbackFunction) {
    this.setState({ callbackForceUpdateContactEntreprise: callbackFunction });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "General");
    const role = App.RightsGuard?.current?.hasRight("Affaire", "Specifique");

    if (right == "RW" || right == "RO") {
      const isAffaireEmpty = !Object.entries(this.props.affaire).length;
      if (isAffaireEmpty) {
        return (
          <div className="alert alert-danger mt-2 mx-auto" role="alert">
            Une erreur a été rencontrée ! (Les informations de l'affaire ne sont
            pas disponibles)
          </div>
        );
      } else {
        const tauxAvancementOptions = [
          {
            id: 1,
            value: 0,
            display: "0%",
          },
          {
            id: 2,
            value: 5,
            display: "5%",
          },
          {
            id: 3,
            value: 10,
            display: "10%",
          },
          {
            id: 4,
            value: 15,
            display: "15%",
          },
          {
            id: 5,
            value: 20,
            display: "20%",
          },
          {
            id: 6,
            value: 25,
            display: "25%",
          },
          {
            id: 7,
            value: 30,
            display: "30%",
          },
          {
            id: 8,
            value: 35,
            display: "35%",
          },
          {
            id: 9,
            value: 40,
            display: "40%",
          },
          {
            id: 10,
            value: 45,
            display: "45%",
          },
          {
            id: 11,
            value: 50,
            display: "50%",
          },
          {
            id: 12,
            value: 55,
            display: "55%",
          },
          {
            id: 13,
            value: 60,
            display: "60%",
          },
          {
            id: 14,
            value: 65,
            display: "65%",
          },
          {
            id: 15,
            value: 70,
            display: "70%",
          },
          {
            id: 16,
            value: 75,
            display: "75%",
          },
          {
            id: 17,
            value: 80,
            display: "80%",
          },
          {
            id: 18,
            value: 85,
            display: "85%",
          },
          {
            id: 19,
            value: 90,
            display: "90%",
          },
          {
            id: 20,
            value: 95,
            display: "95%",
          },
          {
            id: 21,
            value: 100,
            display: "100%",
          },
        ];

        const informationAffaire = [
          {
            label: "Numero Affaire",
            accessor: "reference",
            colSize: 4,
          },
          {
            label: "Nom",
            accessor: "nomAffaire",
            type: role == "RW" ? "text" : null,
            colSize: 4,
          },
          {
            label: "Date d'ouverture",
            accessor: "dateOuverture",
            colSize: 4,
            functionAppliedToValue: (value) => {
              return value ? ToLocaleDateString(value) : "-";
            },
          },
          {
            label: "Client",
            accessor: "client",
            required: true,
            type: "selectSearch",
            service: ClientService.getForSelectSearch,
            optionFieldToDisplay: "nom_Client",
            valueFieldToDisplay: "nom_Client",
            colSize: 4,
            functionAppliedToValue: (value) => {
              return (
                <div className="d-flex justify-content-start align-items-center">
                  {value ? (
                    <a
                      href={"/clients/" + value.id}
                      target="_blank"
                      data-text={value.nom_Client}
                      className="fixWidthHover"
                    >
                      {value.nom_Client}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              );
            },
          },
          {
            label: "Contact",
            type: "selectSearch",
            functionAppliedToValue: (value) => {
              return (
                <div className="d-flex justify-content-start align-items-center">
                  {value ? (
                    <a
                      href={"/contacts/" + value.id}
                      target="_blank"
                      data-text={value.nom_Contact}
                      className="fixWidthHover"
                    >
                      {IfNullToText(value.nom_Contact, null) +
                        " " +
                        IfNullToText(value.prenom_Contact, null)}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              );
            },
            accessor: "contact",
            colSize: 4,
            service: this.serviceGetContactsByClientId(
              this.props.affaire.client?.id
            ),
            optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          },
          {
            label: "Chargé d'affaire",
            accessor: "chargeAffaire",
            functionAppliedToValue: (value) => {
              return value ? value.nom + " " + value.prenom : "-";
            },
            type: "selectSearch",
            service: PersonnelService.getForSelectSearch,
            optionFieldToDisplay: ["nom", "prenom"],
            valueFieldToDisplay: ["nom", "prenom"],
            colSize: 4,
          },
          {
            label: "Assistant(e)",
            accessor: "assistante",
            functionAppliedToValue: (value) => {
              return value ? value.nom + " " + value.prenom : "-";
            },
            type: "selectSearch",
            service: PersonnelService.getForSelectSearch,
            optionFieldToDisplay: ["nom", "prenom"],
            valueFieldToDisplay: ["nom", "prenom"],
            colSize: 4,
          },
          {
            label: "Famille d'affaires",
            accessor: "famille_Affaire",
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            colSize: 4,
            service: FamilleAffaireService.getAll,
            required: this.props.affaire.produitInterne?.isPreta ?? false,
            optionFieldToDisplay: "designation",
            valueFieldToDisplay: "designation",
            type: role == "RW" ? "selectSearch" : null,
          },
          {
            label: "Produit interne",
            accessor: "produitInterne",
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            service: ProduitInterneService.getAll,
            optionFieldToDisplay: "designation",
            valueFieldToDisplay: "designation",
            colSize: 4,
            type: role == "RW" ? "selectSearch" : null,
          },
          ...(this.props.affaire?.produitInterne?.designation === "R&D"
            ? [
                {
                  label: "Interne/Externe",
                  accessor: "interneExterne",
                  type: "selectSearch",
                  functionAppliedToValue: (value) => {
                    return value ? value.designation : null;
                  },
                  service: InterneExterneService.getAll,
                  optionFieldToDisplay: "designation",
                  valueFieldToDisplay: "designation",
                  colSize: 4,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.designation === "R&D"
            ? [
                {
                  label: "Analyse CIR",
                  accessor: "analyseCIR",
                  type: "checkbox",
                  colSize: 4,
                },
              ]
            : []),
          {
            label: "Type mission",
            accessor: "mission",
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            service: MissionService.getAll,
            optionFieldToDisplay: "designation",
            valueFieldToDisplay: "designation",
            colSize: 4,
            type: role == "RW" ? "selectSearch" : null,
          },
          // {
          //   label: "Soldée",
          //   accessor: "soldee",
          //   type: "checkbox",
          //   functionAppliedToValue: (value) => {
          //     return value ? "Oui" : "Non";
          //   },
          //   colSize: 4,
          // },
          {
            label: "Statut",
            accessor: "statut",
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            service: StatutAffaireService.getAll,
            optionFieldToDisplay: "designation",
            valueFieldToDisplay: "designation",
            colSize: 4,
            type: "selectSearch",
          },
          {
            label: "International",
            accessor: "international",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 4,
          },
          {
            label: "Actif",
            accessor: "actif",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 4,
          },
          {
            label: "Mission complémentaire",
            accessor: "missionComplementaire",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 4,
          },

          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Taux d'avancement",
                  accessor: "tauxAvancement",
                  type: "selectSearch",
                  optionFieldToReturn: "value",
                  optionFieldToDisplay: "display",
                  valueFieldToDisplay: "display",
                  options: tauxAvancementOptions,
                  value:
                    tauxAvancementOptions.find(
                      (el) => el.value === this.props.affaire?.tauxAvancement
                    ) ?? null,
                  functionAppliedToValue: (el) => {
                    return (el ?? "0") + "%";
                  },
                  colSize: 4,
                },
              ]
            : []),
          {
            label: "Commentaire",
            accessor: "commentaire",
            type: "textArea",
            functionAppliedToValue: (el) => {
              if (el != null) {
                return <div className="keepWhiteSpace">{el}</div>;
              }
            },
            colSize: 12,
          },
        ].filter((e) => e != null);

        const informationsContact = [
          {
            label: "Entreprise",
            accessor: "entreprise",
            type: "selectSearch",
            service: ClientService.getForSelectSearch,
            optionFieldToDisplay: "nom_Client",
            valueFieldToDisplay: "nom_Client",
            functionAppliedToValue: (value) => {
              return (
                <div className="d-flex justify-content-start align-items-center">
                  {value ? (
                    <a
                      href={"/clients/" + value.id}
                      target="_blank"
                      data-text={value.nom_Client}
                      className="fixWidthHover"
                    >
                      {value.nom_Client}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              );
            },
            colSize: 4,
            afterUpdateFunction: () => {
              this.props.handleChange(
                "contact_Entreprise",
                null,
                this.state.callbackForceUpdateContactEntreprise
              );
            },
          },
          {
            label: "Contact Entreprise",
            accessor: "contact_Entreprise",
            type: "selectSearch",
            service: this.serviceGetContactsByClientId(
              this.props.affaire.entreprise?.id
            ),
            optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            functionAppliedToValue: (value) => {
              return (
                <>
                  <div className="d-flex justify-content-start align-items-center">
                    {value != null ? (
                      <a
                        href={"/contacts/" + value.id}
                        target="_blank"
                        data-text={
                          value.nom_Contact + " " + value.prenom_Contact
                        }
                        className="fixWidthHover"
                      >
                        {value.nom_Contact + " " + value.prenom_Contact}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </>
              );
            },
            setForceUpdateFunction: this.setForceUpdateContactEntreprise,
            colSize: 6,
          },
          {
            label: "Maitre d'Oeuvre",
            accessor: "maitre_Oeuvre",
            type: "selectSearch",
            optionFieldToDisplay: "nom_Client",
            valueFieldToDisplay: "nom_Client",
            service: ClientService.getForSelectSearch,
            functionAppliedToValue: (value) => {
              return (
                <div className="d-flex justify-content-start align-items-center">
                  {value ? (
                    <a
                      href={"/clients/" + value.id}
                      target="_blank"
                      data-text={value.nom_Client}
                      className="fixWidthHover"
                    >
                      {value.nom_Client}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              );
            },
            afterUpdateFunction: () => {
              this.props.handleChange(
                "contact_Maitre_Oeuvre",
                null,
                this.state.callbackForceUpdateContactMaitreDOeuvre
              );
            },
            colSize: 4,
          },
          {
            label: "Contact maitre d'oeuvre",
            accessor: "contact_Maitre_Oeuvre",
            type: "selectSearch",
            service: this.serviceGetContactsByClientId(
              this.props.affaire.maitre_Oeuvre?.id
            ),
            optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            functionAppliedToValue: (value) => {
              return (
                <>
                  <div className="d-flex justify-content-start align-items-center">
                    {value != null ? (
                      <a
                        href={"/contacts/" + value.id}
                        target="_blank"
                        data-text={
                          value.nom_Contact + " " + value.prenom_Contact
                        }
                        className="fixWidthHover"
                      >
                        {value.nom_Contact + " " + value.prenom_Contact}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </>
              );
            },
            setForceUpdateFunction: this.setForceUpdateContactMaitreDOeuvre,
            colSize: 6,
          },
          {
            label: "Maitre d'Ouvrage",
            accessor: "maitre_Ouvrage",
            type: "selectSearch",
            optionFieldToDisplay: "nom_Client",
            valueFieldToDisplay: "nom_Client",
            service: ClientService.getForSelectSearch,
            functionAppliedToValue: (value) => {
              return (
                <div className="d-flex justify-content-start align-items-center">
                  {value ? (
                    <a
                      href={"/clients/" + value.id}
                      target="_blank"
                      data-text={value.nom_Client}
                      className="fixWidthHover"
                    >
                      {value.nom_Client}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              );
            },
            afterUpdateFunction: () => {
              this.props.handleChange(
                "contact_Maitre_Ouvrage",
                null,
                this.state.callbackForceUpdateContactMaitreDOuvrage
              );
            },
            colSize: 4,
          },
          {
            label: "Contact maitre d'ouvrage",
            accessor: "contact_Maitre_Ouvrage",
            type: "selectSearch",
            service: this.serviceGetContactsByClientId(
              this.props.affaire.maitre_Ouvrage?.id
            ),
            optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
            functionAppliedToValue: (value) => {
              return (
                <>
                  <div className="d-flex justify-content-start align-items-center">
                    {value != null ? (
                      <a
                        href={"/contacts/" + value.id}
                        target="_blank"
                        data-text={
                          value.nom_Contact + " " + value.prenom_Contact
                        }
                        className="fixWidthHover"
                      >
                        {value.nom_Contact + " " + value.prenom_Contact}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </>
              );
            },
            setForceUpdateFunction: this.setForceUpdateContactMaitreDOuvrage,
            colSize: 6,
          },
        ];

        const informationsFiche = [
          {
            label: "Date de création",
            accessor: "dateCreate",
            functionAppliedToValue: (value) => {
              return value
                ? ToLocaleDateString(value) +
                    " par " +
                    IfNullToText(
                      this.props.affaire.userCreate?.nom,
                      "inconnu"
                    ) +
                    " " +
                    IfNullToText(this.props.affaire.userCreate?.prenom, "")
                : "-";
            },
            colSize: 6,
          },
          {
            label: "Date de modification",
            accessor: "dateModif",
            functionAppliedToValue: (value) => {
              return value
                ? ToLocaleDateString(value) +
                    " par " +
                    IfNullToText(this.props.affaire.userModif?.nom, "inconnu") +
                    " " +
                    IfNullToText(this.props.affaire.userModif?.prenom, "")
                : "-";
            },
            colSize: 6,
          },
        ];

        let commandeReverse = this.props.affaire.commandes?.slice();
        commandeReverse.reverse();
        return (
          <>
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <BoxInformationsInline
                  title="Information affaire"
                  datas={informationAffaire}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  headerToast="Affaire"
                  NoEdition={right == "RO"}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
              <div className="col-md-12 col-lg-12">
                <BoxInformationsInline
                  title="Information "
                  datas={informationsContact}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  headerToast="Affaire"
                  NoEdition={right == "RO"}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
              <div className="col-lg-6">
                <Box
                  noPaddingBottom={true}
                  header={
                    <div className="col-12 align-self-center py-2">
                      <div>Informations Client</div>
                    </div>
                  }
                  body={
                    <table className="table table-striped table-bordered table-sm">
                      <thead
                        className={
                          "sticky-top" +
                          (commandeReverse?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Num Arc Client</th>
                          <th>Num Marché</th>
                          <th>Contact Achats</th>
                          <th>Date ARC</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commandeReverse?.map((commande) => (
                          <tr key={commande.id}>
                            <td>
                              {commande.num_Arc_Client}
                              <ButtonIcon
                                icon={faExternalLinkAlt}
                                className="btn btn-secondary btn-sm text-light ms-1"
                                onClick={() =>
                                  window.open(
                                    "/commandes/" + commande.id,
                                    "_blank"
                                  )
                                }
                                style={{ width: "32px" }}
                              ></ButtonIcon>
                            </td>
                            <td>{commande.num_Marche ?? "-"}</td>
                            <td>{commande.emetteur ?? "-"}</td>
                            <td>{ToLocaleDateString(commande.date_Arc)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                />
              </div>

              <div className="col-lg-6">
                <BoxInformationsInline
                  title="Informations sur la fiche"
                  datas={informationsFiche}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="3"
                  boxSize="small"
                  NoEdition
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { InformationsAffaire };
