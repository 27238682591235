import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { isEqual, debounce } from "lodash";
import { formatValueDecimal } from "_utils/DecimalFunctions";

/**
 * Génère le composant Input
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'objet lié à cet input
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Input
 */
class IntervalDecimal extends React.Component {
  constructor(props) {
    super(props);

    var vals = this.props.value?.split("|");
    this.state = {
      valueMin: formatValueDecimal(
        vals?.[0],
        props.ignoredValues,
        this.props.numberOfDecimals
      ),
      valueMax: formatValueDecimal(
        vals?.[1],
        props.ignoredValues,
        this.props.numberOfDecimals
      ),
      focus: false,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.parseValue = this.parseValue.bind(this);

    this.handleOnChangeDebounced = debounce((value) => {
      this.props.handleChange?.(this.props.accessor, value);
    }, 300);
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  componentDidUpdate(prevProps) {
    if (!this.state.focus && !isEqual(prevProps?.value, this.props?.value)) {
      var vals = this.props.value?.split("|");
      this.setState({
        valueMin: formatValueDecimal(
          vals?.[0],
          this.props.ignoredValues,
          this.props.numberOfDecimals
        ),
        valueMax: formatValueDecimal(
          vals?.[1],
          this.props.ignoredValues,
          this.props.numberOfDecimals
        ),
      });
    }
  }

  onFocus() {
    this.setState({ focus: true });
  }

  handleBlur(event, selector) {
    this.setState({ focus: false });
    if (selector === "min") {
      var valueMin = formatValueDecimal(
        event.target.value,
        this.props.ignoredValues,
        this.props.numberOfDecimals
      );
      this.setState({ valueMin: valueMin });
      this.props.handleBlur?.(
        this.props.accessor,
        this.parseValue(valueMin) + "|" + this.parseValue(this.state.valueMax)
      );
    } else if (selector === "max") {
      var valueMax = formatValueDecimal(
        event.target.value,
        this.props.ignoredValues,
        this.props.numberOfDecimals
      );
      this.setState({ valueMax: valueMax });
      this.props.handleBlur?.(
        this.props.accessor,
        this.parseValue(this.state.valueMin) + "|" + this.parseValue(valueMax)
      );
    }
  }

  parseValue(value) {
    var v = parseFloat(value.toString().replace(/\s+/g, "").replace(/,/g, "."));
    if (v) {
      return v;
    } else {
      return "";
    }
  }

  handleChange(event, selector) {
    if (selector === "min") {
      this.setState({ valueMin: event.target.value });
      var valueMin = formatValueDecimal(
        event.target.value,
        this.props.ignoredValues,
        this.props.numberOfDecimals
      );
      this.handleOnChangeDebounced(
        this.parseValue(valueMin) + "|" + this.parseValue(this.state.valueMax)
      );
    }

    if (selector === "max") {
      this.setState({ valueMax: event.target.value });
      var valueMax = formatValueDecimal(
        event.target.value,
        this.props.ignoredValues,
        this.props.numberOfDecimals
      );
      this.handleOnChangeDebounced(
        this.parseValue(this.state.valueMin) + "|" + this.parseValue(valueMax)
      );
    }
  }

  render() {
    return (
      <>
        {this.props.label ? (
          <div className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </div>
        ) : (
          ""
        )}
        <div className="input-decimal input-group has-validation">
          <input
            className="form-control"
            name={this.props.accessor}
            required={this.props.required}
            value={this.state.valueMin}
            onChange={(event) => {
              this.handleChange(event, "min");
            }}
            type="text"
            onFocus={this.onFocus}
            onBlur={(event) => {
              this.handleBlur(event, "min");
            }}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          <input
            className="form-control"
            name={this.props.accessor}
            required={this.props.required}
            value={this.state.valueMax}
            onChange={(event) => {
              this.handleChange(event, "max");
            }}
            type="text"
            onFocus={this.onFocus}
            onBlur={(event) => {
              this.handleBlur(event, "max");
            }}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          {this.props.showClearButton ? (
            <ButtonIcon
              id={"inputGroup" + this.props.accessor}
              smallText=""
              icon={faTimes}
              iconSize="sm"
              onClick={() => {
                this.setState({ value: null });
                this.props.handleChange(this.props.accessor, null);
              }}
              className="btn btn-danger"
            ></ButtonIcon>
          ) : null}
          {this.props.showValidator ? (
            <div
              id={"validation" + this.props.accessor}
              className="invalid-feedback"
            >
              {this.props.invalidText}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

IntervalDecimal.propTypes = {
  value: PropTypes.any,
  ignoredValues: PropTypes.any,
  numberOfDecimals: PropTypes.number,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  invalidText: PropTypes.string,
  disabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  showClearButton: PropTypes.bool,
};

IntervalDecimal.defaultProps = {
  value: "0|0",
  ignoredValues: [],
  numberOfDecimals: 2,
  required: false,
};

export { IntervalDecimal };
