import ReactSelectSearch, { fuzzySearch } from "react-select-search";
import { PropTypes } from "prop-types";
import { ButtonIcon } from "_components/Buttons/ButtonIcon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { isEqual } from "lodash";

import { getNestedProperty } from "_utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { TooltipEncapsulator } from "_components";

class SelectSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectSearchOptions: [], // Format {value: 92, name: "France"}, liste des éléments affichés par le component react-select-search
      options: [], // Format {id: 92, nom_Fr: 'France', …}, données associées aux éléments du component react-select-search
      selectedOption: this.props.value ? this.props.value : null,
      clear: false,
      blur: true,
    };

    this.getOptionFieldToDisplay = this.getOptionFieldToDisplay.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderValueOnBlur = this.renderValueOnBlur.bind(this);
    this.getValueOfSelect = this.getValueOfSelect.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.customForceUpdate = this.customForceUpdate.bind(this);
  }

  componentDidMount() {
    this.props.setForceUpdateFunction?.(this.customForceUpdate);
  }

  customForceUpdate() {
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Generally, this component should not trigger an update. The child component ReactSelectSearch will manage its updates by itself.
    // The only case when we need to trigger an update is when we change the value of the ReactSelectSearch from the outside, for example when we clear it.
    return (
      (this.state.blur && !isEqual(nextProps?.value, this.props?.value)) ||
      nextState.clear != this.state.clear ||
      nextProps.disabled != this.props.disabled ||
      nextProps.required != this.props.required ||
      nextProps.showClearButton != this.props.showClearButton
    );
  }

  componentDidUpdate() {
    if (this.state.clear) {
      this.setState((prevState) => ({ ...prevState, clear: false }));
    }
    if (this.state.selectedOption != this.props.value) {
      this.setState((prevState) => ({
        ...prevState,
        selectedOption: this.props.value,
      }));
    }
    // if (this.state.blur) {
    //   this.setState(prevState => ({...prevState, blur: false }));
    // }
  }

  /**
   * Retourne la chaine de caractère a afficher pour une option, en fonction de props.optionFieldToDisplay
   * Si props.optionFieldToDisplay est une chaine de caractère, on considère donc que c'est le nom du champ de l'option à afficher.
   * Si props.optionFieldToDisplay est un array, on récupère la valeur de chacun de ces champs dans l'option, et on les concatène.
   * @param {*} option, option dont on souhaite afficher la valeur
   * @returns la valeur de l'option
   */
  getOptionFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.optionFieldToDisplay && option) {
      if (Array.isArray(this.props.optionFieldToDisplay)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.optionFieldToDisplay
          .map((key) => getNestedProperty(option, key))
          .join(" | ");
        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.optionFieldToDisplay]?.toString();
      }
    }
    // Si on utilise un type primitif
    return option ? option : "";
  }

  /**
   * Récupère la liste des options a afficher en appelant le service fournit par props.service
   * @param {*} query
   * @returns Une promise qui retourne la liste des options de la liste
   */
  getOptions = (query) => {
    if (this.props.service && typeof this.props.service === "function") {
      if (
        this.props.functionAppliedToGroupByName &&
        typeof this.props.functionAppliedToGroupByName === "function"
      ) {
        return this.props
          .service(query)
          .then((res) => {
            // on check data.data si le back nous renvoie les données comme pour le composant "base de recherche"
            let resDatas =
              res.data && res.data.datas ? res.data.datas : res.data;
            // On enlève les valeurs null du tableau des résultats
            resDatas = resDatas.filter((n) => n);

            // On écrase le résultat pour avoir une liste des options en une seule dimension.
            let resDatasFlatten = resDatas.flatMap((x) => x);

            // On transforme le tableau des résultats en éléments exploitables
            let selectSearchOptions = resDatas.map((optionGroup) => {
              let groupItems = optionGroup.map((option, index) => ({
                value: option && option.id ? option.id : index,
                name: this.getOptionFieldToDisplay(option)?.toString(),
              }));
              return {
                name: this.props.functionAppliedToGroupByName(optionGroup[0]),
                type: "group",
                items: groupItems,
              };
            });

            selectSearchOptions.unshift({
              value: -1,
              name: "",
            });

            if (
              this.props.customFirstOption &&
              query &&
              (!this.props.pattern ||
                new RegExp(this.props.pattern).test(query))
            ) {
              let firstOption = this.props.customFirstOption(query);
              firstOption.option.id = -2;
              resDatasFlatten.unshift(firstOption.option);
              selectSearchOptions.unshift({
                value: -2,
                name: firstOption.name,
              });
            }

            this.setState({
              options: resDatasFlatten,
              selectSearchOptions: selectSearchOptions,
            });
            return selectSearchOptions;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return this.props
          .service(query)
          .then((res) => {
            // on check data.data si le back nous renvoie les données comme pour le composant "base de recherche"
            let resDatas =
              res.data && res.data.datas ? res.data.datas : res.data;

            // On enlève les valeurs null du tableau des résultats
            resDatas = resDatas.filter((n) => n);
            // On transforme le tableau des résultats en éléments exploitables
            let selectSearchOptions = resDatas.map((option, index) => ({
              value: option && option.id ? option.id : index,
              name: this.getOptionFieldToDisplay(option)?.toString(),
            }));

            selectSearchOptions.unshift({
              value: -1,
              name: "",
            });

            if (
              this.props.customFirstOption &&
              query &&
              (!this.props.pattern ||
                new RegExp(this.props.pattern).test(query))
            ) {
              let firstOption = this.props.customFirstOption(query);
              firstOption.option.id = -2;
              resDatas.unshift(firstOption.option);
              selectSearchOptions.unshift({
                value: -2,
                name: firstOption.name,
              });
            }

            this.setState({
              options: resDatas,
              selectSearchOptions: selectSearchOptions,
            });
            return selectSearchOptions;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (Array.isArray(this.props.options)) {
      if (
        this.props.functionAppliedToGroupByName &&
        typeof this.props.functionAppliedToGroupByName === "function"
      ) {
        let resDatas = this.props.options;

        // On enlève les valeurs null du tableau des résultats
        resDatas = resDatas.filter((n) => n);

        // On écrase le résultat pour avoir une liste des options en une seule dimension.
        let resDatasFlatten = resDatas.flatMap((x) => x);

        // On transforme le tableau des résultats en éléments exploitables
        let selectSearchOptions = resDatas.map((optionGroup) => {
          let groupItems = optionGroup.map((option, index) => ({
            value: option && option.id ? option.id : index,
            name: this.getOptionFieldToDisplay(option)?.toString(),
          }));
          return {
            name: this.props.functionAppliedToGroupByName(optionGroup[0]),
            type: "group",
            items: groupItems,
          };
        });

        selectSearchOptions.unshift({
          value: -1,
          name: "",
        });

        if (
          this.props.customFirstOption &&
          query &&
          (!this.props.pattern || new RegExp(this.props.pattern).test(query))
        ) {
          let firstOption = this.props.customFirstOption(query);
          firstOption.option.id = -2;
          resDatasFlatten.unshift(firstOption.option);
          selectSearchOptions.unshift({
            value: -2,
            name: firstOption.name,
          });
        }

        this.setState({
          options: resDatasFlatten,
          selectSearchOptions: selectSearchOptions,
        });
        return selectSearchOptions;
      } else {
        let selectSearchOptions = this.props.options.map((option, index) => ({
          value: option && option.id ? option.id : index,
          name: this.getOptionFieldToDisplay(option).toString(),
        }));

        selectSearchOptions.unshift({
          value: -1,
          name: "",
        });

        this.setState({
          options: this.props.options,
          selectSearchOptions: selectSearchOptions,
        });

        return selectSearchOptions;
      }
    } else {
      this.setState({ options: [], selectSearchOptions: [] });
      return [];
    }
  };

  /**
   * Handle appelée par le composant react-select-search.
   * Ne met pas à jour l'état du component lui-même.
   * Appelle la handleChange du formulaire, pour indiquer le changement de valeur (via accessor/value)
   * Suite à ça, l'état du component est récupéré du parent via props.value lors du render.
   *
   * @param {*} selectedOptionId Id de l'option séléctionnée.
   */
  handleChange = (selectedOptionId) => {
    let selectedOption = this.state.options.find((option, index) =>
      option.id ? option.id === selectedOptionId : index === selectedOptionId
    );

    this.props.handleChange(
      this.props.accessor,
      this.getValueOfSelect(selectedOption)
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedOption: selectedOption,
    }));

    if (this.props.handleBlur) {
      this.props.handleBlur(
        this.props.accessor,
        this.getValueOfSelect(selectedOption)
      );
    }
  };

  /**
   * Fonction permettant de récupérer la valeur de l'option selectionnée
   * @param {*} selectedOption, l'option selectionnée
   * @returns le champ this.props.optionFieldToReturn de l'option selectionnée, si this.props.optionFieldToReturn est null, retourne l'option.
   */
  getValueOfSelect(selectedOption) {
    let res = null;
    try {
      // Si on utilise un objet, et que this.props.optionFieldToReturn n'est pas null, on retourne la valeur de ce champ de l'option.
      if (selectedOption && this.props.optionFieldToReturn) {
        res = selectedOption[this.props.optionFieldToReturn];
      } else {
        // Si on utilise un type primitif ou l'objet entier.
        res = selectedOption;
      }
    } catch (e) {
      console.log(e);
    }
    // Dans tous les autres cas, on retourne null
    // Exemple : Option par défaut (vide)
    if (res === undefined) {
      res = null;
    }
    return res;
  }

  // Controls the rendering of the value/input element
  renderValue = (prevValueProps) => {
    let value = {};
    if (this.state.blur) {
      value.value = this.renderValueOnBlur();
    }

    return (
      <div className="input-selectSearch input-group has-validation">
        <input
          {...prevValueProps}
          {...value}
          placeholder={this.props.placeholder}
          className="form-select"
          required={this.props.required}
        />
        {this.props.showClearButton ? (
          <ButtonIcon
            id={"inputGroup" + this.props.accessor}
            smallText=""
            icon={faTimes}
            iconSize="sm"
            onClick={() => {
              this.setState((prevState) => ({ ...prevState, clear: true }));
              this.handleChange(-1);
              this.forceUpdate();
            }}
            className="btn btn-danger"
            style={this.props.disabled ? { display: "none" } : {}}
          ></ButtonIcon>
        ) : null}
        <div
          id={"validation" + this.props.accessor}
          className="invalid-feedback"
        >
          {this.props.invalidText}
        </div>
      </div>
    );
  };

  /**
   *  Returns the value to display when the reactSelectSearch loses focus.
   *  When the ReactSelectSearch component gains focus again, it reverts to its orginal behaviour.
   * @returns The string to display
   */
  renderValueOnBlur() {
    // let selectedOption = this.state.options.find(
    //   (option, index) => option.id ? option.id === this.state.selectedOptionId : index === this.state.selectedOptionId
    // );
    let selectedOption = this.state.selectedOption;

    if (!selectedOption) {
      return "";
    } else {
      if (!this.props.valueFieldToDisplay) {
        // Si on utilise un type primitif
        return selectedOption;
      } else {
        // Si on utilise un objet auquel on veut une propriété
        if (Array.isArray(this.props.valueFieldToDisplay)) {
          // Si on passe un tableau de propriete
          return this.props.valueFieldToDisplay
            .map((key) => selectedOption[key])
            .join(" ")
            .toString();
        } else {
          // Si on passe une seul propriete
          return selectedOption[this.props.valueFieldToDisplay]?.toString();
        }
      }
    }
  }

  render() {
    return (
      <>
        {this.props.label ? (
          <span className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </span>
        ) : (
          ""
        )}
        {this.props.tooltip ? (
          <TooltipEncapsulator tooltip={this.props.tooltip}>
            <span className="ps-2">
              <FontAwesomeIcon icon={faInfoCircle} size="sm" />
            </span>
          </TooltipEncapsulator>
        ) : (
          ""
        )}
        <ReactSelectSearch
          options={[]}
          getOptions={this.getOptions}
          emptyMessage="Aucun résultat trouvé."
          debounce={this.props.debounce}
          name={this.props.accessor}
          value={this.state.clear ? "" : -1}
          search
          filterOptions={
            this.props.customFilter ? this.props.customFilter : fuzzySearch
          }
          renderOption={(props, option, snapshot, className) => (
            <button
              {...props}
              className={className}
              type="button"
              style={{ height: "auto", minHeight: "30px" }}
            >
              <span>
                <span>{option.name}</span>
              </span>
            </button>
          )}
          onChange={this.handleChange}
          onBlur={() => {
            this.setState((prevState) => ({ ...prevState, blur: true }));
          }}
          onFocus={() => {
            this.setState((prevState) => ({ ...prevState, blur: false }));
          }}
          renderValue={this.renderValue}
          aria-describedby={
            "inputGroup" +
            this.props.accessor +
            " validation" +
            this.props.accessor
          }
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

SelectSearch.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  placeholder: PropTypes.string,
  service: PropTypes.any,
  options: PropTypes.array,
  valueFieldToDisplay: PropTypes.any,
  optionFieldToDisplay: PropTypes.any,
  optionFieldToReturn: PropTypes.string,
  debounce: PropTypes.number,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  customFirstOption: PropTypes.func,
  customFilter: PropTypes.func,
  functionAppliedToGroupByName: PropTypes.func,
  showClearButton: PropTypes.bool,
  pattern: PropTypes.string,
  tooltip: PropTypes.any,
};

SelectSearch.defaultProps = {
  value: PropTypes.any,
  optionFieldToDisplay: "",
  valueFieldToDisplay: "",
  placeholder: "",
  debounce: 300,
  handleChange: () => {
    return null;
  },
  handleBlur: () => {
    return null;
  },
};

export { SelectSearch };
