import http from "http-common";

const MaterielService = class {
  /**
   * Fonction permettant de récuperer tous les materiels avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des materiels respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/materiels/", { params });
  }

  /**
   * Fonction permettant de créer un nouveau materiel pour un materiel donné
   * @param {*} materiel, le materiel à ajouter
   * @returns le materiel crée avec son id
   */
  static post(materiel) {
    return http.post("/materiels", materiel);
  }

  /**
   * Fonction permettant de mettre à jour un materiel
   * @param {*} materiel, le materiel à mettre à jour
   * @returns
   */
  static put(materiel) {
    return http.put("/materiels/" + materiel.id, materiel);
  }

  /**
   * Fonction permettant de récuperer un materiel via son id
   * @param {*} id, id de du materiel à récupérer
   * @returns le materiel souhaité
   */
  static getById(id) {
    return http.get("/materiels/" + id);
  }

  /**
   * Fonction permettant de récuperer un materiel via son id
   * @param {*} id, id de du materiel à récupérer
   * @returns le materiel souhaité
   */
  static getAllByIdClient(id) {
    return http.get("/materiels/clients/" + id);
  }

  /**
   * Fonction permettant de supprimer un materiel
   * @param {*} materiel, le materiel à supprimer
   * @returns
   */
  static delete(id) {
    return http.delete("/materiels/" + id);
  }

  /**
   * Fonction permettant d'actualiser la liste des matériels.
   * @param {*} materiel, le materiel à supprimer
   * @returns
   */
  static uploadAndUpdate() {
    return http.get("/materiels/uploadupdate/latest");
  }

  /**
   * @returns un fichier.
   */
  static printCVM(impression, idDevis) {
    return http({
      url: "/materiels/impressionCVM/" + impression + "/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static printRelanceCVM(impression, idMat) {
    return http({
      url: "/materiels/impressionRelanceCVM/" + impression + "/" + idMat,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static printDCE(impression, idMat) {
    return http({
      url: "/materiels/impressionDCE/" + impression + "/" + idMat,
      method: "GET",
      responseType: "blob",
    });
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/materiels/extraction?" + params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { MaterielService };
