import React from "react";

//import { App } from "App";

import { Input } from "_components/Input";

import { faTrash, faCopy, faPaste } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";

import { AffaireService, TypeNoteDeFraisService } from "_services";

function LigneNdF(props) {
  return (
    <tr className={"ligneNdF"}>
      <td>
        <div
          style={
            props.editing || props.ndf.numeroFrais || props.ndf.id < 0
              ? { display: "none" }
              : {}
          }
        >
          <Input
            accessor={"ndf." + props.ndf.id + ".export"}
            type="checkbox"
            placeholder=""
            value={props.ndf.export}
            handleChange={props.handleChange}
            showValidator={false}
            showClearButton={false}
          />
        </div>
      </td>
      <td style={{ maxWidth: "132px" }}>{props.ndf.numeroFrais}</td>
      <td className="col1">
        <Input
          accessor={"ndf." + props.ndf.id + ".libelle"}
          value={props.ndf.libelle}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col2">
        <Input
          accessor={"ndf." + props.ndf.id + ".affaire"}
          value={props.ndf.affaire}
          type="selectSearch"
          placeholder=""
          service={AffaireService.getNonSoldees}
          optionFieldToDisplay={["reference", "nomAffaire"]}
          valueFieldToDisplay={["reference", "nomAffaire"]}
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>

      <td className="col3" style={{ maxWidth: "100px" }}>
        <Input
          accessor={"ndf." + props.ndf.id + ".montant_TTC"}
          value={props.ndf.montant_TTC}
          type="decimal"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col4" style={{ maxWidth: "100px" }}>
        <Input
          accessor={"ndf." + props.ndf.id + ".montant_TVA"}
          value={props.ndf.montant_TVA}
          type="decimal"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col5" style={{ maxWidth: "150px" }}>
        <Input
          accessor={"ndf." + props.ndf.id + ".typeNoteDeFrais"}
          value={props.ndf.typeNoteDeFrais}
          type="selectSearch"
          placeholder=""
          service={TypeNoteDeFraisService.getAll}
          optionFieldToDisplay="code_Secteur"
          valueFieldToDisplay="code_Secteur"
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>

      <td className="col6" style={{ maxWidth: "100px" }}>
        <Input
          accessor={"ndf." + props.ndf.id + ".nombre"}
          value={props.ndf.nombre}
          type="decimal"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col7">
        <Input
          accessor={"ndf." + props.ndf.id + ".dateFrais"}
          value={props.ndf.dateFrais}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col8">
        <Input
          accessor={"ndf." + props.ndf.id + ".bdcExistant"}
          value={props.ndf.bdcExistant}
          type="checkbox"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing || props.ndf.numeroFrais != null}
        />
      </td>
      <td className="col9">
        <ButtonIcon
          smallText=""
          icon={faCopy}
          iconSize="sm"
          tooltip="Copier"
          onClick={() => {
            props?.copierLineNotesDeFrais(props.ndf.id);
          }}
          className="btn btn-success"
          style={
            !props.editing || props.ndf.numeroFrais != null
              ? { display: "none" }
              : {}
          }
        />
      </td>
      <td className="col10">
        <ButtonIcon
          smallText=""
          icon={faPaste}
          iconSize="sm"
          tooltip="Coller"
          onClick={() => {
            props?.collerLineNotesDeFrais(props.ndf.id);
          }}
          className="btn btn-success"
          style={
            !props.editing || props.ndf.numeroFrais != null
              ? { display: "none" }
              : {}
          }
        />
      </td>
      <td className="col11">
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          tooltip="Supprimer"
          onClick={() => {
            props?.deleteLineNoteDeFrais(props.ndf.id);
          }}
          className="btn btn-danger deleteLineNdF"
          style={
            !props.editing ||
            props.ndf.numeroFrais != null ||
            props.rightSuppression != "RW"
              ? { display: "none" }
              : {}
          }
        />
      </td>
    </tr>
  );
}

export { LigneNdF };
