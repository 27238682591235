import React from "react";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";

import * as InputTypes from "./InputTypes";

/**
 * Génère un composant input avec son label en colonne (l'un en dessous de l'autre) permettant de mettre à jour un state
 * @param {string} label, le label de l'input
 * @param {*} value, l'objet ou la valeur à afficher
 * @param {string} accessor, l'accesseur pour changer le state
 * @param {function} handleChange, fonction permettant de mettre à jour le state parent (le formulaire) en fonction de la valeur saisie dans l'Input
 * @param {string} type, le type de l'input souhaité (select, selectSearch, text, number, etc...), par défaut text
 * @param {array} inputOptions, les options de l'input notamment pour le select
 * @param {string} optionFieldToDisplay, si on utilise un objet -> le champ de l'objet à afficher
 * @param {string} optionFieldToReturn, si on utilise un objet -> le champ de l'objet à utiliser comme valeur, si non défini on utilise l'objet lui même en tant que valeur
 * @returns un composant input mettant à jour un state
 */
class Input extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextStates) {
    return !(isEqual(nextProps, this.props) && isEqual(nextStates, this.state));
  }

  render() {
    let input = null;
    let label =
      this.props.label != undefined && this.props.required
        ? this.props.label + " *"
        : this.props.label;

    switch (this.props.type) {
      case "selectSearch":
        input = (
          <InputTypes.SelectSearch
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            placeholder={this.props.placeholder}
            debounce={this.props.debounce}
            service={this.props.service}
            options={this.props.options}
            valueFieldToDisplay={this.props.valueFieldToDisplay}
            optionFieldToDisplay={this.props.optionFieldToDisplay}
            optionFieldToReturn={this.props.optionFieldToReturn}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            customFirstOption={this.props.customFirstOption}
            customFilter={this.props.customFilter}
            functionAppliedToGroupByName={
              this.props.functionAppliedToGroupByName
            }
            setForceUpdateFunction={this.props.setForceUpdateFunction}
            showClearButton={this.props.showClearButton}
            afterUpdateFunction={this.props.afterUpdateFunction}
            pattern={this.props.pattern}
            tooltip={this.props.tooltip}
          />
        );
        break;
      case "multipleSelectSearch":
        input = (
          <InputTypes.MultipleSelectSearch
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            placeholder={this.props.placeholder}
            debounce={this.props.debounce}
            service={this.props.service}
            options={this.props.options}
            valueFieldToDisplay={this.props.valueFieldToDisplay}
            optionFieldToDisplay={this.props.optionFieldToDisplay}
            optionFieldToReturn={this.props.optionFieldToReturn}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            customFirstOption={this.props.customFirstOption}
            customFilter={this.props.customFilter}
            functionAppliedToGroupByName={
              this.props.functionAppliedToGroupByName
            }
            setForceUpdateFunction={this.props.setForceUpdateFunction}
            showClearButton={this.props.showClearButton}
            afterUpdateFunction={this.props.afterUpdateFunction}
            pattern={this.props.pattern}
            tooltip={this.props.tooltip}
          />
        );
        break;
      case "selectSearchDisabledValues":
        input = (
          <InputTypes.SelectSearchDisabledValues
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            placeholder={this.props.placeholder}
            debounce={this.props.debounce}
            service={this.props.service}
            options={this.props.options}
            valueFieldToDisplay={this.props.valueFieldToDisplay}
            optionFieldToDisplay={this.props.optionFieldToDisplay}
            optionFieldToReturn={this.props.optionFieldToReturn}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            customFirstOption={this.props.customFirstOption}
            customFilter={this.props.customFilter}
            functionAppliedToGroupByName={
              this.props.functionAppliedToGroupByName
            }
            setForceUpdateFunction={this.props.setForceUpdateFunction}
            showClearButton={this.props.showClearButton}
            afterUpdateFunction={this.props.afterUpdateFunction}
            pattern={this.props.pattern}
            tooltip={this.props.tooltip}
            disableOption={this.props.disableOption}
          />
        );
        break;
      case "address":
        input = (
          <InputTypes.Address
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            requiredIntitule={this.props.requiredIntitule}
            requiredPays={this.props.requiredPays}
            requiredVoie={this.props.requiredVoie}
            requiredComplement={this.props.requiredComplement}
            requiredVille={this.props.requiredVille}
            requiredCp={this.props.requiredCp}
            invalidText={this.props.invalidText}
            colSizeInnerFields={this.props.colSizeInnerFields}
            intituleLabel={this.props.intituleLabel}
            afficherIntitule={this.props.afficherIntitule}
          />
        );
        break;
      case "multipleSelect":
        input = (
          <InputTypes.MultipleSelect
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            service={this.props.service}
            optionFieldToDisplay={this.props.optionFieldToDisplay}
            valueFieldToDisplay={this.props.valueFieldToDisplay}
            optionFieldToReturn={this.props.optionFieldToReturn}
            required={this.props.required}
            invalidText={this.props.invalidText}
          />
        );
        break;
      case "checkbox":
        input = (
          <InputTypes.CheckBox
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            disabled={this.props.disabled}
          />
        );
        break;
      case "selectDays":
        input = (
          <InputTypes.SelectDays
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
          />
        );
        break;
      case "imageInput":
        input = (
          <InputTypes.ImageInput
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
          />
        );
        break;
      case "decimal":
        input = (
          <InputTypes.Decimal
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            ignoredValues={this.props.ignoredValues}
            numberOfDecimals={this.props.numberOfDecimals}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
            style={this.props.style}
          />
        );
        break;
      case "period":
        input = (
          <InputTypes.Period
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
          />
        );
        break;
      case "intervalDecimal":
        input = (
          <InputTypes.IntervalDecimal
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            ignoredValues={this.props.ignoredValues}
            numberOfDecimals={this.props.numberOfDecimals}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
          />
        );
        break;
      case "textArea":
        input = (
          <InputTypes.TextArea
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            type={this.props.type}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
            cols={this.props.cols}
            rows={this.props.rows}
          />
        );
        break;
      case "date":
        input = (
          <InputTypes.Date
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            type={this.props.type}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
          />
        );
        break;
      case "email":
      case "text":
      default:
        input = (
          <InputTypes.Text
            label={label}
            value={this.props.value}
            accessor={this.props.accessor}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            type={this.props.type}
            required={this.props.required}
            disabled={this.props.disabled}
            showValidator={this.props.showValidator}
            showClearButton={this.props.showClearButton}
            placeholder={this.props.placeholder}
          />
        );
        break;
    }
    return input;
  }
}

Input.propTypes = {
  label: PropTypes.string,
  ignoredValues: PropTypes.any,
  numberOfDecimals: PropTypes.number,
  value: PropTypes.any,
  accessor: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  service: PropTypes.any,
  debounce: PropTypes.number,
  options: PropTypes.array,
  valueFieldToDisplay: PropTypes.any,
  optionFieldToDisplay: PropTypes.any,
  optionFieldToReturn: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  requiredIntitule: PropTypes.bool,
  disabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  showClearButton: PropTypes.bool,
  customFilter: PropTypes.func,
  setForceUpdateFunction: PropTypes.func,
  colSizeInnerFields: PropTypes.number,
  pattern: PropTypes.string,
  tooltip: PropTypes.any,
};

Input.defaultProps = {
  type: "text",
  optionFieldToDisplay: null,
  optionFieldToReturn: null,
  required: false,
  requiredIntitule: false,
  invalidText: "Erreur",
  disabled: false,
  showValidator: true,
  showClearButton: true,
  setForceUpdateFunction: null,
};

export { Input };
