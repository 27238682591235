import React from "react";
import { PropTypes } from "prop-types";

import { BoxInformationsInline } from "_components/FicheComponents/BoxInformationsInline";
import {
  CiviliteService,
  PoleService,
  AttributionSpecifiqueService,
  ServicePersonnelService,
  FonctionPersonnelService,
  PersonnelService,
  ModesTeletravailService,
  SpecialiteService,
} from "_services";
import { WarningBar } from "_components";
import { App } from "App";

class GeneralPersonnel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ancienneInitiales = this.props.personnel.initiales;
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Personnel", "General"); // on obtient les droits généraux

    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Civilité",
          accessor: "civilite",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: CiviliteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 4,
        },
        {
          label: "Nom",
          accessor: "nom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Prénom",
          accessor: "prenom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Initiales",
          accessor: "initiales",
          type: "text",
          invalidText: "Les initiales existent déjà",
          handleBlur: () => {
            // Permet de vérifier si les initiales existent déjà en vérifiant que ce ne sont pas celles déjà utilisées
            PersonnelService.getAllInitiales().then((res) => {
              let inputInitiales = document.getElementsByName("initiales");
              if (
                res.data
                  .filter((val) => val !== this.ancienneInitiales)
                  .find(
                    (element) =>
                      element === this.props.personnel.initiales.toUpperCase()
                  ) === undefined
              ) {
                inputInitiales[0].classList.remove("is-invalid");
              } else {
                inputInitiales[0].classList.add("is-invalid");
              }
            });
          },
          colSize: 4,
        },
        {
          label: "Responsable (initiales)",
          accessor: "responsable",
          type: "selectSearch",
          service: PersonnelService.getAllInitiales,
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];
      const photo = [
        {
          label: null,
          accessor: "photo",
          type: "imageInput",
          functionAppliedToValue: () => {
            return (
              <img
                src={this.props.imageSource}
                alt="Photo indisponible"
                style={{ width: "100%" }}
              />
            );
          },
          colSize: 12,
        },
      ];
      const situationPoste = [
        {
          label: "Service",
          accessor: "service",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: ServicePersonnelService.getAll,
          colSize: 6,
        },
        {
          label: "Pôle",
          accessor: "pole",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PoleService.getAll,
          colSize: 6,
        },
        {
          label: "Fonction",
          accessor: "fonction",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: FonctionPersonnelService.getAll,
          colSize: 6,
        },
        {
          label: "Attribution spécifique",
          accessor: "attribution_Specifique",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: AttributionSpecifiqueService.getAll,
          colSize: 6,
        },

        {
          label: "Spécialité",
          accessor: "specialite",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: SpecialiteService.getAll,
          colSize: 6,
        },
      ];

      const organisationTravail = [
        {
          label: "Télétravail",
          accessor: "teletravail",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: ModesTeletravailService.getAll,
          colSize: 6,
        },
        {
          label: "Jours télétravaillés",
          accessor: "joursTeletravailles",
          type: "selectDays",
          functionAppliedToValue: (value) => {
            let lettresjours = ["L", "M", "M", "J", "V"];
            return (
              <div className="d-flex">
                {lettresjours.map((jour, index) => {
                  return (
                    <div className="ms-2 flex-grow-1" key={index}>
                      <div
                        className="text-center"
                        style={{ width: "fit-content" }}
                      >
                        <div>{jour}</div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={value?.[index * 2] === "1"}
                          value={value?.[index * 2] === "1"}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        ></input>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={value?.[index * 2 + 1] === "1"}
                          value={value?.[index * 2 + 1] === "1"}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        ></input>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
          colSize: 12,
        },
        {
          label: "Nombre de jours maximum hebdomadaire téletravaillés",
          accessor: "nombreJoursMaxHebdomadaireTeletravailles",
          type: "decimal",
          handleBlur: this.props.handleChange,
          colSize: 12,
        },
        {
          label: "Nombre de jours maximum mensuel téletravaillés",
          accessor: "nombreJoursMaxMensuelTeletravailles",
          type: "decimal",
          handleBlur: this.props.handleChange,
          colSize: 12,
        },
        {
          label: "Temps partiel",
          accessor: "temps_Partiel",
          functionAppliedToValue: (value) => value + "%",
          type: "decimal",
          handleBlur: this.props.handleChange,
          colSize: 6,
        },
        {
          label: "Jours travaillés",
          accessor: "jours_travailles",
          type: "selectDays",
          handleBlur: (joursTravailles) => {
            let total = 0;
            for (let i = 0; i < joursTravailles?.length; i++) {
              if (joursTravailles?.[i] === "1") {
                total += 10;
              }
            }
            this.props.handleChange("temps_Partiel", total);
          },
          functionAppliedToValue: (value) => {
            let lettresjours = ["L", "M", "M", "J", "V"];
            return (
              <div className="d-flex">
                {lettresjours.map((jour, index) => {
                  return (
                    <div className="ms-2 flex-grow-1" key={index}>
                      <div
                        className="text-center"
                        style={{ width: "fit-content" }}
                      >
                        <div>{jour}</div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={value[index * 2] === "1"}
                          value={value[index * 2] === "1"}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        ></input>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={value[index * 2 + 1] === "1"}
                          value={value[index * 2 + 1] === "1"}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        ></input>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
          colSize: 12,
        },
      ];

      const InformationsContact = [
        {
          label: "Ligne pro",
          accessor: "ligne_Pro",
          type: "text",
          colSize: 6,
        },
        {
          label: "Mobile pro",
          accessor: "mobile_Pro",
          type: "text",
          colSize: 6,
        },
        {
          label: "Email Pro",
          accessor: "email_Pro",
          type: "email",
          colSize: 6,
        },
        {
          label: "Immatriculation véhicule",
          accessor: "immatriculation_Vehicule",
          type: "text",
          colSize: 6,
        },
      ];

      return (
        <>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-12 col-lg-10 d-flex infosGeneralFichePerso">
                <BoxInformationsInline
                  title="général"
                  datas={informationsGeneral}
                  state={this.props.personnel}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  NoEdition={right != "RW"}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
              <div className="col-2 d-none d-lg-block">
                <BoxInformationsInline
                  title="Photo"
                  datas={photo}
                  state={this.props.personnel}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="12"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  NoEdition={true}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Situation du poste"
                datas={situationPoste}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right != "RW"}
                handleUpdate={this.props.handleUpdate}
              />
              <BoxInformationsInline
                title="Organisation du travail"
                datas={organisationTravail}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right != "RW"}
                handleUpdate={this.props.handleUpdate}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Contact"
                datas={InformationsContact}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right != "RW"}
                handleUpdate={this.props.handleUpdate}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

GeneralPersonnel.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { GeneralPersonnel };
