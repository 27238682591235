import React from "react";

import { App } from "App";
import {
  AlertDialog,
  Box,
  ButtonIcon,
  FormWithValidation,
  WarningBar,
} from "_components";
import { Input } from "_components/Input";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PropTypes } from "prop-types";

import { Helmet } from "react-helmet";

import {
  FicheDeSuiviService,
  EtatAvancementService,
  FournisseurService,
  FamilleArticleService,
  TypePanneService,
  UtilisationService,
  PersonnelService,
  ContactService,
  ClientService,
  MaterielService,
} from "_services";

class AjoutFicheDeSuivi extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isClientLoaded =
      this.props.history.location.state &&
      this.props.history.location.state.client;

    this.state = {
      errorText: null,
      error: false,
      isSubmitting: false,
      callBackforceUpdateTypePanne: null,
      callbackForceUpdateContact: null,
      callbackForceUpdateMateriel: null,

      ficheDeSuivi: {
        id: 0,
        date_Creation: null,
        date_Reception: null,
        emplacement: null,
        etat_Avancement: null,
        gerant: null,
        materiel: null,
        type_Materiel: null,
        utilisation: null,
        type_Panne: null,
        lLD: null,
        resultat_Test: null,
        pret: false,
        materielLocation: null,
        dureeContrat: null,
        numeroContrat: null,
        utilisateur: null,
        contactUtilisateur: null,
        testeur: null,
        date_test: null,
        commentaire: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAllMaterielProxy = this.getAllMaterielProxy.bind(this);

    this.setForceUpdateTypePanne = this.setForceUpdateTypePanne.bind(this);
    this.setForceUpdateContact = this.setForceUpdateContact.bind(this);
    this.setForceUpdateMateriel = this.setForceUpdateMateriel.bind(this);
    this.getAllMaterielLocationProxy =
      this.getAllMaterielLocationProxy.bind(this);
  }

  setForceUpdateTypePanne(callbackFunction) {
    this.setState({ callBackforceUpdateTypePanne: callbackFunction });
  }

  setForceUpdateContact(callbackFunction) {
    this.setState({
      callbackForceUpdateContact: callbackFunction,
    });
  }
  setForceUpdateMateriel(callbackFunction) {
    this.setState({
      callbackForceUpdateMateriel: callbackFunction,
    });
  }

  async componentDidMount() {
    var geranT = App.RightsGuard?.current?.getPersonnelConnecte();
    this.setState((state) => ({
      loaded: true,
      ficheDeSuivi: {
        ...state.ficheDeSuivi,
        utilisateur: this.props.history.location.state
          ? this.props.history.location.state.utilisateur
          : null,
        materiel: this.props.history.location.state
          ? this.props.history.location.state.materiel
          : null,
        type_Materiel: this.props.history.location.state
          ? this.props.history.location.state.materiel?.type_Materiel
          : null,
        gerant: geranT,
      },
    }));
  }

  handleChange(
    accessor,
    value = "__ACCES_DIRECT__",
    afterUpdateFunction = null
  ) {
    if (value != "__ACCES_DIRECT__") {
      let updatedFicheDeSuivi = { ...this.state.ficheDeSuivi };
      updatedFicheDeSuivi[accessor] = value;
      this.setState({ ficheDeSuivi: updatedFicheDeSuivi }, afterUpdateFunction);
    } else {
      this.setState(
        { ficheDeSuivi: { ...this.state.ficheDeSuivi, ...accessor } },
        afterUpdateFunction
      );
    }
  }

  getAllMaterielProxy(search) {
    return MaterielService.getAll({
      size: 50,
      global: search,
      en_Sommeil: false,
      nom_utilisateur: this.state.ficheDeSuivi?.utilisateur?.nom_client,
    });
  }

  getAllMaterielLocationProxy(search) {
    return MaterielService.getAll({
      size: 25,
      global: search,
      location: true,
      en_sommeil: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    FicheDeSuiviService.post(this.state.ficheDeSuivi)
      .then((retour) => {
        this.props.history.push("/fichesDeSuivi/" + retour.data.id);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error.message,
        });
      });
  }

  form() {
    const numeroEtatAvancement =
      this.state.ficheDeSuivi.etat_Avancement?.numero.toString();

    const informationsClient = [
      {
        value: this.state.ficheDeSuivi.utilisateur,
        label: "Client utilisateur",
        accessor: "utilisateur",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        afterUpdateFunction: () => {
          this.handleChange("contact", null, () => {
            this.state.callbackForceUpdateContact();
            this.state.callbackForceUpdateMateriel();
          });
        },
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.contact,
        label: "Contact",
        accessor: "contactUtilisateur",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.ficheDeSuivi.utilisateur?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        invalidText: "Vous devez sélectionner un contact.",
        setForceUpdateFunction: this.setForceUpdateContact,
        colSize: 4,
      },
    ];

    const InformationsFicheDeSuivi = [
      {
        value: this.state.ficheDeSuivi.date_Reception,

        label: "Date de reception",
        accessor: "date_Reception",
        type: "date",
        colSize: 4,
      },

      {
        value: this.state.ficheDeSuivi.emplacement,

        label: "Emplacement",
        accessor: "emplacement",
        type: "selectSearch",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        service: FournisseurService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner un emplacement.",
        colSize: 8,
      },
      {
        value: this.state.ficheDeSuivi.etat_Avancement,
        label: "état d'avancement",
        accessor: "etat_Avancement",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        type: "selectSearch",
        service: EtatAvancementService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 4,
        required: true,
      },
      {
        value: this.state.ficheDeSuivi.gerant,

        label: "Gerant",
        accessor: "gerant",
        functionAppliedToValue: (value) => {
          return value ? "".concat(value.nom, " ", value.prenom) : null;
        },
        service: PersonnelService.getForSelectSearch,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
        type: "selectSearch",
        colSize: 4,
      },
    ];

    const infoMat = [
      {
        value: this.state.ficheDeSuivi.type_Materiel,

        label: "Type Materiel",
        accessor: "type_Materiel",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        required: ["2", "3", "4"].indexOf(numeroEtatAvancement) !== -1,
        type: "selectSearch",
        service: FamilleArticleService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        afterUpdateFunction: () => {
          this.handleChange(
            "type_Panne",
            null,
            this.state.callBackforceUpdateTypePanne
          );
        },
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.materiel,

        label: "Numéro de série",
        accessor: "materiel",
        type: "selectSearch",
        required: ["2", "3"].indexOf(numeroEtatAvancement) !== -1,
        colSize: 4,
        service: this.getAllMaterielProxy,
        optionFieldToDisplay: ["type_Materiel.designation", "num_Serie"],
        valueFieldToDisplay: ["type_Materiel.designation", "num_Serie"],
        functionAppliedToValue: (value) => {
          return value ? value.num_Serie : null;
        },
        setForceUpdateFunction: this.setForceUpdateMateriel,
      },
      {
        value: this.state.ficheDeSuivi.utilisation,

        label: "Utilisation",
        accessor: "utilisation",
        required: ["3", "4"].indexOf(numeroEtatAvancement) !== -1,
        type: "selectSearch",
        service: UtilisationService.getAll,
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        invalidText: "Vous devez sélectionner le type d'utilisation.",
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.type_Panne,

        label: "Type de panne",
        accessor: "type_Panne",
        functionAppliedToValue: (value) => {
          return value ? value.panne : null;
        },
        required: ["3", "4"].indexOf(numeroEtatAvancement) !== -1,
        type: "selectSearch",
        service: () =>
          TypePanneService.getByIdFamilleAffaire(
            this.state.ficheDeSuivi.type_Materiel?.id
          ),
        optionFieldToDisplay: "panne",
        valueFieldToDisplay: "panne",
        setForceUpdateFunction: this.setForceUpdateTypePanne,
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.lLD,

        label: "LLD",
        accessor: "lLD",
        colSize: 4,
      },
    ];
    const infoMatLoc = [
      {
        value: this.state.ficheDeSuivi.pret,
        label: "Matériel de location",
        accessor: "pret",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        type: "checkbox",
        colSize: 12,
      },

      {
        value: this.state.ficheDeSuivi.materielLocation,
        label: "Numéro de série",
        accessor: "materielLocation",
        colSize: 4,
        type: "selectSearch",
        service: this.getAllMaterielLocationProxy,
        optionFieldToDisplay: ["num_Serie", "type_Materiel.designation"],
        valueFieldToDisplay: ["num_Serie", "type_Materiel.designation"],
        functionAppliedToValue: (value) => {
          return value ? value.num_Serie : null;
        },
      },
      {
        value: this.state.ficheDeSuivi.dureeContrat,
        label: "Durée du contrat",
        accessor: "dureeContrat",
        type: "decimal",
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.numeroContrat,

        label: "N° contrat",
        accessor: "numeroContrat",
        type: "text",
        colSize: 4,
      },
    ];
    const infoTest = [
      {
        value: this.state.ficheDeSuivi.resultat_Test,

        label: "Résultat test",
        accessor: "resultat_Test",
        functionAppliedToValue: (value) => {
          return value ? "Réussi" : "Échoué";
        },
        type: "checkbox",
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.date_test,

        label: "Date test",
        accessor: "date_test",
        type: "date",
        colSize: 4,
      },
      {
        value: this.state.ficheDeSuivi.testeur,

        label: "Testeur",
        accessor: "testeur",
        functionAppliedToValue: (value) => {
          return value ? "".concat(value.nom, " ", value.prenom) : null;
        },
        service: PersonnelService.getForSelectSearch,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
        type: "selectSearch",
        colSize: 4,
      },
    ];
    const commentaire = [
      {
        value: this.state.ficheDeSuivi.commentaire,

        label: "Commentaire",
        accessor: "commentaire",
        type: "textArea",
        colSize: 8,
      },
    ];

    const infos = [
      { info: informationsClient, titre: "Informations client" },

      { info: InformationsFicheDeSuivi, titre: "Informations fiche de suivi" },
      { info: infoMat, titre: "Informations sur le matériel" },
      { info: infoMatLoc, titre: "Informations sur le matériel de location" },

      { info: infoTest, titre: "Informations sur les tests" },

      { info: commentaire, titre: "Commentaire" },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div className="col-lg-4 pt-2" key={fieldsIndex}>
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={(acc, val) =>
                                this.handleChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer la fiche de suivi"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'une fiche de suivi</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création de la fiche de suivi a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={() => this.setState({ error: false })}
        />
        <div className="p-3 m-4" id="ajoutFicheDeSuivi">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;une fiche de suivi
              {this.state.ficheDeSuivi.client
                ? " pour " + this.state.ficheDeSuivi.client.nom_Client
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <WarningBar
            active={!this.state.ficheDeSuivi.materiel?.isStolen}
            content={"Ce Materiel a été volé"}
            background={"bg-danger"}
          />
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutFicheDeSuivi.propTypes = {
  history: PropTypes.object,
};

export { AjoutFicheDeSuivi };
