import React from "react";
import { Route, Switch } from "react-router";

import "./App.css";
import "./Table.css";

import { Helmet } from "react-helmet";

import { HomePage } from "HomePage";

import { BaseClient } from "BaseClient";
import { BaseContact } from "BaseContact";
import { BaseMateriel } from "BaseMateriel";
import { BasePersonnel } from "BasePersonnel";
import { BaseDevis } from "BaseDevis";
import { BaseConsultation } from "BaseConsultation";
import { BaseArticle } from "BaseArticle";
import { BaseAffaire } from "BaseAffaire";
import { BaseFicheDeSuivi } from "BaseFicheDeSuivi";
import { BaseCommande } from "BaseCommande";
import { BaseCB } from "BaseCB";
import { BaseContrat } from "BaseContrat";

import { FicheDeSuivi } from "FicheDeSuivi";
import { FicheClient } from "FicheClient";
import { FichePersonnel } from "FichePersonnel";
import { FicheContact } from "FicheContact";
import { FicheDevis } from "FicheDevis";
import { FicheArticle } from "FicheArticle";
import { FicheConsultation } from "FicheConsultation";
import { FicheCommande } from "FicheCommande";
import { FicheMateriel } from "FicheMateriel";
import { FicheAffaire } from "FicheAffaire";
import { FicheCarteBancaire } from "FicheCarteBancaire";
import { FicheContrat } from "FicheContrat";

import { AjoutContact } from "AjoutContact";
import { AjoutFicheDeSuivi } from "AjoutFicheDeSuivi";
import { AjoutPersonnel } from "AjoutPersonnel";
import { AjoutDevis } from "AjoutDevis";
import { AjoutConsultation } from "AjoutConsultation";
import { AjoutClient } from "AjoutClient";
import { AjoutAffaire } from "AjoutAffaire";
import { AjoutMateriel } from "AjoutMateriel";
import { AjoutCarteBancaire } from "AjoutCarteBancaire";
import { AjoutContrat } from "AjoutContrat";

import { RapportActivite } from "RapportActivite";

import { Geomarket } from "Geomarket";

import { NoMatchPage } from "NoMatchPage";
import { NotConnectedPage } from "NotConnectedPage";

import { PageAdmin } from "PageAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { DroitsService, PersonnelService } from "_services";

import { Menu, Toaster, RightsGuard } from "_components";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { NoteDeFraisHub } from "NotesDeFrais/NotesDeFrais";
import { NoteDeFraisCBHub } from "NotesDeFrais";
import { BaseLicence } from "BaseLicence";
import { AjoutLicences } from "AjoutLicences/AjoutLicence";

//import ImgAttente from "_assets/pandaAttente.gif";

class App extends React.PureComponent {
  static Toaster = null;
  static RightsGuard = null;

  constructor(props) {
    super(props);
    this.state = {
      rights: null,
      loading: false,
    };

    this.toasterRef = React.createRef();
    App.Toaster = this.toasterRef;

    this.guardian = React.createRef();
    App.RightsGuard = this.guardian;

    this.setState = this.setState.bind(this);
  }

  async componentDidMount() {
    // Pour gérer le cas où on se connecte
    this.props.instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        this.props.instance.setActiveAccount(account);
        this.setState({ loading: true });
        if (this.state.rights == null) {
          DroitsService.getAll().then((response) =>
            this.setState({ rights: response.data, loading: false })
          );
          PersonnelService.getByOid(
            this.props.instance.getActiveAccount()?.idTokenClaims?.["oid"]
          )
            .then((response) =>
              this.setState({ personnelConnecte: response.data })
            )
            .catch(() =>
              setTimeout(
                () =>
                  App.Toaster.current?.createToast({
                    body: "La connexion a échouée : l'OID du user connecté ne correspond à personne dans la table personnel",
                    header: "Echec",
                    type: "failure",
                  }),
                3000
              )
            );
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log(JSON.stringify(event));
      }

      if (
        this.props.instance.getAllAccounts().length > 0 &&
        this.state.rights == null
      ) {
        // cas où on est déjà connecté
        this.setState({ loading: true });
        DroitsService.getAll().then((response) =>
          this.setState({ rights: response.data, loading: false })
        );
        PersonnelService.getByOid(
          this.props.instance.getActiveAccount()?.idTokenClaims?.["oid"]
        )
          .then((response) =>
            this.setState({ personnelConnecte: response.data })
          )
          .catch(() =>
            setTimeout(
              () =>
                App.Toaster.current?.createToast({
                  body: "La connexion a échouée : l'OID du user connecté ne correspond à personne dans la table personnel",
                  header: "Echec",
                  type: "failure",
                }),
              3000
            )
          );
      }
    });
  }

  render() {
    let routes = (
      <Switch>
        <Route
          exact
          path="/"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <HomePage {...routeProps} />
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* CLIENT */}
        <Route
          exact
          path="/clients"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseClient {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/clients/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutClient {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/clients/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheClient {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* CONTACTS */}
        <Route
          exact
          path="/contacts"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseContact {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/contacts/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutContact {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/contacts/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheContact {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* FICHES DE SUIVI */}
        <Route
          exact
          path="/fichesDeSuivi"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseFicheDeSuivi {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/fichesDeSuivi/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutFicheDeSuivi {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/fichesDeSuivi/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheDeSuivi {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* CONSULTATION */}
        <Route
          exact
          path="/consultations"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseConsultation {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/consultations/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutConsultation {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/consultations/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheConsultation {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* MATERIEL */}
        <Route
          exact
          path="/materiels"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseMateriel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/materiels/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutMateriel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/materiels/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheMateriel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* DEVIS */}
        <Route
          exact
          path="/devis"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseDevis {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/devis/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutDevis {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/devis/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheDevis {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* PERSONNEL */}
        <Route
          exact
          path="/personnels"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BasePersonnel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/personnels/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutPersonnel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="/personnels/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FichePersonnel {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* ARTICLE */}
        <Route
          exact
          path="/articles"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseArticle {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/articles/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheArticle {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* AFFAIRE */}
        <Route
          exact
          path="/affaires"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseAffaire {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/affaires/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutAffaire {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/affaires/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheAffaire {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* Contrat */}
        <Route
          exact
          path="/contrats"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseContrat {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/contrats/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutContrat {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/contrats/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheContrat {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />

        {/* CARTE BANCAIRE */}
        <Route
          exact
          path="/cartesbancaires"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseCB {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/cartesbancaires/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutCarteBancaire {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/cartesbancaires/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheCarteBancaire {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />

        {/* COMMANDE */}
        <Route
          exact
          path="/commandes"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseCommande {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/commandes/:id"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <FicheCommande {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />

        {/* LICENCES */}
        <Route
          exact
          path="/licences"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <BaseLicence {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/licences/add"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <AjoutLicences {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps} />
              </UnauthenticatedTemplate>
            </>
          )}
        />

        {/* RAPPORT ACTIVITE*/}
        <Route
          exact
          path="/rapportActivite"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <RapportActivite {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* GEOMARKET */}
        <Route
          exact
          path="/geomarket"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <Geomarket {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* Notes de frais*/}
        <Route
          exact
          path="/notesDeFrais"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <NoteDeFraisHub {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          exact
          path="/notesDeFraisCb"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <NoteDeFraisCBHub {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* Page admin*/}
        <Route
          exact
          path="/administration"
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <PageAdmin {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
        {/* NON TROUVE */}
        <Route
          render={(routeProps) => (
            <>
              <AuthenticatedTemplate>
                <NoMatchPage {...routeProps} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotConnectedPage {...routeProps}></NotConnectedPage>
              </UnauthenticatedTemplate>
            </>
          )}
        />
      </Switch>
    );
    if (this.state.rights == null) {
      return (
        <>
          <Helmet>
            <title>Bamboo</title>
          </Helmet>

          <MsalProvider instance={this.props.instance}>
            <Menu steps={this.state.steps}></Menu>
            <AuthenticatedTemplate>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  className="fa-spin"
                />
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>{routes}</UnauthenticatedTemplate>
          </MsalProvider>
        </>
      );
    } // cas droits chargés et disponibles
    else
      return (
        <>
          <Helmet>
            <title>Bamboo</title>
          </Helmet>
          <MsalProvider instance={this.props.instance}>
            <AuthenticatedTemplate>
              <RightsGuard
                ref={this.guardian}
                droits={this.state.rights}
                personnelConnecte={this.state.personnelConnecte}
              >
                <Menu steps={this.state.steps} />
                {routes}
              </RightsGuard>
              <Toaster ref={this.toasterRef} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Menu steps={this.state.steps}></Menu>
              {routes}
            </UnauthenticatedTemplate>
          </MsalProvider>
        </>
      );
  }
}

export { App };
