import http from "http-common";

const ProfilInformatiqueService = class {
  /**
   * Fonction permettant de récuperer toutes les profils informatiques
   * @returns la liste de toutes les profils informatiques
   */
  static getAll(params) {
    let url = "/ProfilsInformatique";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer un profil informatique via son id
   * @param {*} id, id du profil informatique à récupérer
   * @returns le profil informatique souhaitée
   */
  static getById(id) {
    return http.get("/ProfilsInformatique/" + id);
  }
};

export { ProfilInformatiqueService };
