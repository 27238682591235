import http from "http-common";

const PositionService = class {
  /**
   * Fonction permettant de récuperer toutes les positions
   * @returns la liste de toutes les positions
   */
  static getAll(params) {
    let url = "/Positions";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une position via son id
   * @param {*} id, id de la position à récupérer
   * @returns la position souhaitée
   */
  static getById(id) {
    return http.get("/Positions/" + id);
  }

  /**
   * Fonction permettant de récupérer des positions via l'id d'un statut
   * @param {*} id, id de la position à récupérer
   * @returns la position souhaitée
   */
  static getByIdStatut(id) {
    return http.get("/personnels/statuts/" + id);
  }
};

export { PositionService };
