import React from "react";

import { Helmet } from "react-helmet";

import { ButtonIcon, DialogForm } from "_components";

import { produce } from "immer";

import MapGeomarket from "./MapGeomarket";

import {
  faMinusSquare,
  faPlusSquare,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { isNullOrWhiteSpaces } from "_utils";
import { FiltrePersonnaliseService } from "_services/FiltrePersonnalise";
import { Input } from "_components/Input";

import green from "_assets/MarkersIcons/marker-icon-2x-green.png";
import orange from "_assets/MarkersIcons/marker-icon-2x-orange.png";
import red from "_assets/MarkersIcons/marker-icon-2x-red.png";
import violet from "_assets/MarkersIcons/marker-icon-2x-violet.png";
import black from "_assets/MarkersIcons/marker-icon-2x-black.png";

const filters = [
  {
    title: "Précision :",
    lvl1: "precision",
    content: [
      { display: "Imprécise", lvl2: "2" },
      { display: "Précise", lvl2: "1" },
    ],
  },
  {
    title: "Qualification client :",
    lvl1: "etats",
    content: [
      { display: "Abonné", lvl2: "Abonné" },
      { display: "Client", lvl2: "Client" },
      { display: "Distributeur", lvl2: "Distributeur" },
      { display: "Prospect", lvl2: "Prospect" },
      { display: "Suspect", lvl2: "Suspect" },
    ],
  },
  {
    title: "Activité client :",
    lvl1: "activite",
    checkNullLabel: "Clients sans activité",
    content: [
      { display: "Administration", lvl2: "Administration" },
      { display: "Aéroport", lvl2: "Aeroport" },
      { display: "AFPA", lvl2: "AFPA" },
      { display: "Archi / géomètre / topo", lvl2: "ArchiGeometreTopo" },
      { display: "Armée", lvl2: "Armee" },
      { display: "Autoroute", lvl2: "Autoroute" },
      { display: "Bâtiment", lvl2: "Batiment" },
      { display: "Bureau de contrôle", lvl2: "BureauDeControle" },
      { display: "Bureau d'études", lvl2: "BureauEtudes" },
      { display: "Centres de recherche", lvl2: "CentresDeRecherche" },
      { display: "Cmistes", lvl2: "Cmistes" },
      { display: "Communauté", lvl2: "Communaute" },
      { display: "Communauté de communes", lvl2: "CommunauteDeCommunes" },
      { display: "Conseil Départemental", lvl2: "ConseilDepartemental" },
      { display: "Conseil Régional", lvl2: "ConseilRegional" },
      { display: "DDT", lvl2: "DDT" },
      { display: "Distributeur", lvl2: "Distributeur" },
      { display: "Eaux", lvl2: "Eaux" },
      { display: "Energie", lvl2: "Energie" },
      { display: "Enseignement", lvl2: "Enseignement" },
      { display: "Entreprise Bâtiment", lvl2: "EntrepriseBatiment" },
      { display: "Entreprise Electricité", lvl2: "EntrepriseElectricite" },
      { display: "Entreprise privée", lvl2: "EntreprisePrivee" },
      { display: "Entreprise TP", lvl2: "EntrepriseTP" },
      { display: "Fluvial - digues", lvl2: "FluvialDigues" },
      { display: "Forêts - parcs", lvl2: "ForetsParcs" },
      { display: "Lotisseur/Promoteur", lvl2: "LotisseurPromoteur" },
      { display: "Lycées - BTS", lvl2: "LyceesBTS" },
      { display: "Maitre d'oeuvre", lvl2: "MaitreOeuvre" },
      { display: "Organismes de formation", lvl2: "OrganismesDeFormation" },
      { display: "Particuliers", lvl2: "Particuliers" },
      { display: "Paysagistes", lvl2: "Paysagistes" },
      { display: "Promoteur", lvl2: "Promoteur" },
      { display: "Rail", lvl2: "Rail" },
      { display: "Régie", lvl2: "Regie" },
      { display: "Télécom", lvl2: "Telecom" },
      { display: "Tunnels", lvl2: "Tunnels" },
      { display: "Universités - IUT", lvl2: "UniversitésIUT" },
      { display: "Ville / Métropole", lvl2: "VilleMetropole" },
    ],
  },
  {
    title: "Produits interne client :",
    lvl1: "produitsInternes",
    checkNullLabel: "Clients sans produit interne",
    content: [
      { display: "3CO - 3 Contrôles", lvl2: "3CO" },
      { display: "3C+PHY - 3 contrôles + géophysique", lvl2: "3C+PHY" },
      { display: "DIG - Digues", lvl2: "DIG" },
      { display: "DO - DO", lvl2: "DO" },
      { display: "ECG - ECG", lvl2: "ECG" },
      {
        display: "ENDO - Analyse image (Pandoscope, granuloscope..)",
        lvl2: "ENDO",
      },
      { display: "ETU - Etudes géotechniques", lvl2: "ETU" },
      {
        display: "ETU+ENDO - Etudes géo + analyse d'images ",
        lvl2: "ETU+ENDO",
      },
      { display: "ETU+PHY - Etudes géo + géophysique", lvl2: "ETU+PHY" },
      { display: "FORM - Formation", lvl2: "FORM" },
      { display: "GG - Demande générale", lvl2: "GG" },
      { display: "GRZ - Grizzly®", lvl2: "GRZ" },
      { display: "KDK - Kodiak®", lvl2: "KDK" },
      { display: "LOC - Location", lvl2: "LOC" },
      { display: "LOG - Logiciel", lvl2: "LOG" },
      { display: "M3S - M3S", lvl2: "M3S" },
      { display: "MAT - Matériel", lvl2: "MAT" },
      { display: "PDA - Panda® et ses accessoires", lvl2: "PDA" },
      { display: "PDI - Pandito®", lvl2: "PDI" },
      { display: "PDO - Pandoscope®", lvl2: "PDO" },
      { display: "PHY - Géophysique", lvl2: "PHY" },
      { display: "PLAK - Plaque", lvl2: "PLAK" },
      { display: "R&D - R&D, études spécifiques", lvl2: "R&D" },
      { display: "SAV - Service après-vente et consommables", lvl2: "SAV" },
    ],
  },
  {
    title: "Matériels propriétaires :",
    lvl1: "materielsProprietaires",
    checkNullLabel: "Aucun filtre",
    content: [
      { display: "Sans matériel", lvl2: "SansMat" },
      { display: "Batt. Légère", lvl2: "BattLegere" },
      { display: "Batt. Lourde", lvl2: "BattLourde" },
      { display: "Colonne de battage", lvl2: "Colonnedebattage" },
      { display: "Combiné", lvl2: "Combine" },
      { display: "Depthbox", lvl2: "Depthbox" },
      { display: "E-Kodiak", lvl2: "EKodiak" },
      { display: "Kodiak", lvl2: "Kodiak" },
      { display: "Kodiak 400", lvl2: "Kodiak400" },
      { display: "Panda 1", lvl2: "Panda1" },
      { display: "Panda 2", lvl2: "Panda2" },
      { display: "Panda 3", lvl2: "Panda3" },
      { display: "Panda 4", lvl2: "Panda4" },
      { display: "Pandito", lvl2: "Pandito" },
      { display: "Pandoscope", lvl2: "Pandoscope" },
      { display: "Pénétro Std", lvl2: "PenetroStd" },
      { display: "PLAK", lvl2: "PLAK" },
      { display: "Sans acq. Combiné", lvl2: "SansAcqCombine" },
      { display: "Sans acq. Std", lvl2: "SansAcqStd" },
    ],
  },
  {
    title: "Actions CCE :",
    lvl1: "actionsTaches",
    checkNullLabel: "Aucun filtre",
    content: [
      { display: "Sans tache", lvl2: "SansTache" },
      { display: "Prospection", lvl2: "Prospection" },
      { display: "Relance devis", lvl2: "Relance devis" },
      { display: "Remise devis", lvl2: "Remise devis" },
      { display: "Suivi client", lvl2: "Suivi client" },
      { display: "Réunion de suivi", lvl2: "Réunion de suivi" },
      { display: "Relance mailings", lvl2: "Relance mailings" },
      { display: "Relance", lvl2: "Relance" },
      { display: "RDV", lvl2: "RDV" },
      { display: "JIT / salon", lvl2: "JIT / salon" },
      { display: "Informations techniques", lvl2: "Informations techniques" },
      { display: "Envoi doc techniques", lvl2: "Envoi doc techniques" },
      { display: "Contact entrant", lvl2: "Contact entrant" },
      { display: "Formation", lvl2: "Formation" },
      { display: "Relance LLD", lvl2: "Relance LLD" },
      { display: "Relance Matériel Neuf", lvl2: "Relance Matériel Neuf" },
      { display: "Rdv Prospection", lvl2: "Rdv Prospection" },
      { display: "Demi pensionnaire", lvl2: "Demi pensionnaire" },
      { display: "Relance commande", lvl2: "Relance commande" },
    ],
  },
];

class Geomarket extends React.PureComponent {
  constructor(props) {
    super(props);

    let filter = {
      precision: {
        1: true,
        2: true,
      },
      etats: {
        Client: true,
        Prospect: true,
        Suspect: true,
        Abonné: true,
        Distributeur: true,
      },
      activite: {
        Distributeur: true,
        LotisseurPromoteur: true,
        Cmistes: true,
        MaitreOeuvre: true,
        Paysagistes: true,
        Promoteur: true,
        LyceesBTS: true,
        UniversitésIUT: true,
        OrganismesDeFormation: true,
        AFPA: true,
        CentresDeRecherche: true,
        EntrepriseBatiment: true,
        EntrepriseElectricite: true,
        EntrepriseTP: true,
        EntreprisePrivee: true,
        Aeroport: true,
        Autoroute: true,
        Batiment: true,
        Communaute: true,
        CommunauteDeCommunes: true,
        ConseilDepartemental: true,
        ConseilRegional: true,
        Energie: true,
        Eaux: true,
        ForetsParcs: true,
        FluvialDigues: true,
        Rail: true,
        Regie: true,
        Telecom: true,
        Tunnels: true,
        VilleMetropole: true,
        ArchiGeometreTopo: true,
        BureauEtudes: true,
        BureauDeControle: true,
        Administration: true,
        Armee: true,
        DDT: true,
        Enseignement: true,
        Particuliers: true,
      },
      produitsInternes: {
        "3CO": true,
        DIG: true,
        M3S: true,
        ETU: true,
        ENDO: true,
        PHY: true,
        FORM: true,
        GRZ: true,
        KDK: true,
        LOG: true,
        MAT: true,
        LOC: true,
        PDA: true,
        PDI: true,
        PDO: true,
        SAV: true,
        PLAK: true,
        "R&D": true,
        GG: true,
        DO: true,
        "ETU+ENDO": true,
        ECG: true,
        "3C+PHY": true,
        "ETU+PHY": true,
      },
      materielsProprietaires: {
        SansMat: true,
        BattLegere: true,
        BattLourde: true,
        Colonnedebattage: true,
        Depthbox: true,
        EKodiak: true,
        Kodiak: true,
        Kodiak400: true,
        Panda1: true,
        Panda2: true,
        Panda3: true,
        Panda4: true,
        Pandito: true,
        Pandoscope: true,
        PenetroStd: true,
        PLAK: true,
        SansAcqCombine: true,
        SansAcqStd: true,
        Combine: true,
      },
      actionsTaches: {
        SansTache: true,
        Prospection: true,
        "Relance devis": true,
        "Remise devis": true,
        "Suivi client": true,
        "Réunion de suivi": true,
        "Relance mailings": true,
        Relance: true,
        RDV: true,
        "JIT / salon": true,
        "Informations techniques": true,
        "Envoi doc techniques": true,
        "Contact entrant": true,
        Formation: true,
        "Relance LLD": true,
        "Relance Matériel Neuf": true,
        "Rdv Prospection": true,
        "Demi pensionnaire": true,
        "Relance commande": true,
      },
    };

    let accordionsOpen = {
      precision: true,
      etats: true,
      activite: false,
      produitsInternes: false,
      materielsProprietaires: false,
      actionsTaches: false,
    };

    this.state = {
      filter: filter,
      accordionsOpen: accordionsOpen,
      numberOfPointsDisplayed: 0,
      numberOfPointsMatched: 0,
      page: "geomarket",
    };

    this.renderFilters = this.renderFilters.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeFilterCategorie =
      this.handleChangeFilterCategorie.bind(this);
    this.handleOpenCloseFilterAccordion =
      this.handleOpenCloseFilterAccordion.bind(this);
    this.computeQueryFromFilter = this.computeQueryFromFilter.bind(this);
    this.setNumberOfPointsDisplayedAndMatched =
      this.setNumberOfPointsDisplayedAndMatched.bind(this);

    this.setOpenDialogFiltre = this.setOpenDialogFiltre.bind(this);
    this.handleNewParameter = this.handleNewParameter.bind(this);
    this.PostFiltreServiceProxy = this.PostFiltreServiceProxy.bind(this);
    this.GetFiltreServiceProxy = this.GetFiltreServiceProxy.bind(this);
  }

  handleNewParameter(accessor, value) {
    if (value != null) {
      let newParameter = JSON.parse(value.filtreJSONstringified);
      this.setState({
        filter: {
          ...newParameter,
        },
      });
    } else {
      this.setState({
        filter: {
          precision: {
            1: true,
            2: true,
          },
          etats: {
            Client: true,
            Prospect: true,
            Suspect: true,
            Abonné: true,
            Distributeur: true,
          },
          activite: {
            Distributeur: true,
            LotisseurPromoteur: true,
            Cmistes: true,
            MaitreOeuvre: true,
            Paysagistes: true,
            Promoteur: true,
            LyceesBTS: true,
            UniversitésIUT: true,
            OrganismesDeFormation: true,
            AFPA: true,
            CentresDeRecherche: true,
            EntrepriseBatiment: true,
            EntrepriseElectricite: true,
            EntrepriseTP: true,
            EntreprisePrivee: true,
            Aeroport: true,
            Autoroute: true,
            Batiment: true,
            Communaute: true,
            CommunauteDeCommunes: true,
            ConseilDepartemental: true,
            ConseilRegional: true,
            Energie: true,
            Eaux: true,
            ForetsParcs: true,
            FluvialDigues: true,
            Rail: true,
            Regie: true,
            Telecom: true,
            Tunnels: true,
            VilleMetropole: true,
            ArchiGeometreTopo: true,
            BureauEtudes: true,
            BureauDeControle: true,
            Administration: true,
            Armee: true,
            DDT: true,
            Enseignement: true,
            Particuliers: true,
          },
          produitsInternes: {
            "3CO": true,
            DIG: true,
            M3S: true,
            ETU: true,
            ENDO: true,
            PHY: true,
            FORM: true,
            GRZ: true,
            KDK: true,
            LOG: true,
            MAT: true,
            LOC: true,
            PDA: true,
            PDI: true,
            PDO: true,
            SAV: true,
            PLAK: true,
            "R&D": true,
            GG: true,
            DO: true,
            "ETU+ENDO": true,
            ECG: true,
            "3C+PHY": true,
            "ETU+PHY": true,
          },
          materielsProprietaires: {
            BattLegere: true,
            BattLourde: true,
            Colonnedebattage: true,
            Depthbox: true,
            EKodiak: true,
            Kodiak: true,
            Kodiak400: true,
            Panda1: true,
            Panda2: true,
            Panda3: true,
            Panda4: true,
            Pandito: true,
            Pandoscope: true,
            PenetroStd: true,
            PLAK: true,
            SansAcqCombine: true,
            SansAcqStd: true,
            Combine: true,
          },
          actionsTaches: {
            Prospection: true,
            "Relance devis": true,
            "Remise devis": true,
            "Suivi client": true,
            "Réunion de suivi": true,
            "Relance mailings": true,
            Relance: true,
            RDV: true,
            "JIT / salon": true,
            "Informations techniques": true,
            "Envoi doc techniques": true,
            "Contact entrant": true,
            Formation: true,
            "Relance LLD": true,
            "Relance Matériel Neuf": true,
            "Rdv Prospection": true,
            "Demi pensionnaire": true,
            "Relance commande": true,
          },
        },
      });
    }
  }

  PostFiltreServiceProxy() {
    FiltrePersonnaliseService.post({
      ...this.state.filter,
      page: this.state.page,
      filtreJSONstringified: JSON.stringify(this.state.filter),
    });
  }

  GetFiltreServiceProxy() {
    return FiltrePersonnaliseService.getByPageAndOid(this.state.page);
  }

  setOpenDialogFiltre(openDialogFunc) {
    this.setState({ openDialogFiltreFunc: openDialogFunc });
  }

  handleChangeFilter(accessor) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.filter[accessorSplit[0]][accessorSplit[1]] =
            !prevState.filter[accessorSplit[0]][accessorSplit[1]];
        })
      );
    }
  }

  handleChangeFilterCategorie(accessor, value) {
    this.setState(
      produce((prevState) => {
        for (const key of Object.keys(prevState.filter[accessor])) {
          prevState.filter[accessor][key] = value;
        }
      })
    );
  }

  handleOpenCloseFilterAccordion(accessor) {
    this.setState(
      produce((prevState) => {
        prevState.accordionsOpen[accessor] =
          !prevState.accordionsOpen[accessor];
      })
    );
  }

  setNumberOfPointsDisplayedAndMatched(
    numberOfPointsDisplayed,
    numberOfPointsMatched
  ) {
    this.setState({ numberOfPointsDisplayed, numberOfPointsMatched });
  }

  renderFilters() {
    return (
      <>
        <DialogForm
          tooltip="Imprimer contrat"
          classNameButton="btn btn-success"
          dialogTitle="Impression"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogFiltre}
          onValidate={() => {
            this.PostFiltreServiceProxy();
          }}
          onClose={() => {}}
          body={
            <div id="PopupCommande">
              <div>
                Veuillez choisir un nom pour ce filtre <br />
              </div>

              <Input
                label="Nom du filtre"
                accessor="nomFiltre"
                type="text"
                value={this.state.filter.nomFiltre}
                handleChange={(acc, value) =>
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      nomFiltre: value,
                    },
                  })
                }
                required={true}
              />
            </div>
          }
        />

        <div className=" d-flex flex-row " style={{ marginBottom: "10px" }}>
          <h3 style={{ flex: "1 0 auto", marginRight: "10px" }}>Filtres : </h3>
          <Input
            accessor="filter"
            type="selectSearch"
            service={this.GetFiltreServiceProxy}
            optionFieldToDisplay="nomFiltre"
            valueFieldToDisplay="nomFiltre"
            colSize={12}
            handleChange={this.handleNewParameter}
          ></Input>
          <ButtonIcon
            icon={faSave}
            onClick={() => {
              this.state.openDialogFiltreFunc();
            }}
            className="btn btn-success text-light ms-1"
            style={{ width: "40px" }}
            iconSize="sm"
            tooltip="Enregistrer le filtre "
          ></ButtonIcon>
        </div>
        <div id="filters" style={{ overflow: "auto", height: "65%" }}>
          {filters.map((elT) => (
            <div key={elT.lvl1}>
              <h5 style={{ margin: "5px 0px", whiteSpace: "nowrap" }}>
                <ButtonIcon
                  icon={
                    this.state.accordionsOpen[elT.lvl1]
                      ? faMinusSquare
                      : faPlusSquare
                  }
                  iconSize={window.innerWidth <= 768 ? "2x" : undefined}
                  className="btn"
                  onClick={() => this.handleOpenCloseFilterAccordion(elT.lvl1)}
                  style={{
                    padding: "0",
                    margin: "0",
                    lineHeight: "normal",
                  }}
                  throttleOnClick={false}
                ></ButtonIcon>
                <input
                  type="checkbox"
                  value={true}
                  checked={Object.values(this.state.filter[elT.lvl1]).every(
                    (c) => c === true
                  )}
                  onChange={() =>
                    this.handleChangeFilterCategorie(
                      elT.lvl1,
                      !Object.values(this.state.filter[elT.lvl1]).every(
                        (c) => c === true
                      )
                    )
                  }
                  ref={(el) => {
                    if (el) {
                      el.indeterminate =
                        Object.values(this.state.filter[elT.lvl1]).some(
                          (c) => c === true
                        ) &&
                        Object.values(this.state.filter[elT.lvl1]).some(
                          (c) => c === false
                        );
                    }
                  }}
                  style={
                    window.innerWidth <= 768
                      ? {
                          margin: "0 5px",
                          verticalAlign: "middle",
                          width: "30px",
                          height: "30px",
                        }
                      : { marginRight: "3px", verticalAlign: "middle" }
                  }
                />
                {elT.title}
                {elT.checkNullLabel &&
                Object.values(this.state.filter[elT.lvl1]).every(
                  (c) => c === false
                ) ? (
                  <div className="fst-italic">{elT.checkNullLabel}</div>
                ) : (
                  ""
                )}
              </h5>
              {this.state.accordionsOpen[elT.lvl1] ? (
                elT.content.map((el, i, arr) => (
                  <div
                    key={el.lvl2}
                    style={{ lineHeight: "normal", whiteSpace: "nowrap" }}
                    className="d-flex align-items-center"
                  >
                    <span
                      className="symbol"
                      style={
                        window.innerWidth <= 768
                          ? { fontSize: "3.2rem" }
                          : { fontSize: "1.25rem" }
                      }
                    >
                      {i + 1 !== arr.length ? "├─" : "└─"}
                    </span>
                    <input
                      type="checkbox"
                      value={true}
                      checked={this.state.filter[elT.lvl1][el.lvl2] === true}
                      onChange={() =>
                        this.handleChangeFilter(elT.lvl1 + "." + el.lvl2)
                      }
                      style={
                        window.innerWidth <= 768
                          ? {
                              marginRight: "3px",
                              width: "30px",
                              height: "30px",
                            }
                          : { marginRight: "3px" }
                      }
                    />
                    {el.display}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <div id="legende" style={{ marginTop: "10px" }}>
          <h3>Légende :</h3>

          <div className="blockLegende">
            <div className="legende">
              <img className="imageLegende" src={red} />
              <div>Suspect</div>
            </div>
            <div className="d-flex legende">
              <img className="imageLegende" src={orange} />
              <div>Prospect</div>
            </div>
          </div>
          <div className="blockLegende">
            <div className="legende">
              <img className="imageLegende" src={green} />
              <div>Client</div>
            </div>
            <div className="legende">
              <img className="imageLegende" src={violet} />
              <div>Abonné</div>
            </div>
          </div>
          <div className="blockLegende">
            <div className="legende">
              <img className="imageLegende" src={black} />
              <div>Distributeur</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  computeQueryFromFilter(filter) {
    // Précision
    let queryElementsPrecision = [];
    filters
      .find((elT) => elT.lvl1 == "precision")
      .content.forEach((el) => {
        if (!filter.precision[el.lvl2]) {
          queryElementsPrecision.push("Precision<>'" + el.lvl2 + "'");
        }
      });

    // Qualifications
    let queryElementsQualifications = [];
    filters
      .find((elT) => elT.lvl1 == "etats")
      .content.forEach((el) => {
        if (!filter.etats[el.lvl2]) {
          queryElementsQualifications.push("Qualification<>'" + el.lvl2 + "'");
        }
      });

    // Types clients
    let queryElementsTypes = [];
    filters
      .find((elT) => elT.lvl1 == "activite")
      .content.forEach((el) => {
        if (filter.activite[el.lvl2]) {
          queryElementsTypes.push(
            "Type='" + el.display.replace("'", "''") + "'"
          );
        }
      });

    // Produits internes
    let queryElementsProduitsInternes = [];
    filters
      .find((elT) => elT.lvl1 == "produitsInternes")
      .content.forEach((el) => {
        if (filter.produitsInternes[el.lvl2]) {
          queryElementsProduitsInternes.push(
            "ProduitsInternes LIKE '% " + el.lvl2 + "%'"
          );
        }
      });

    // Matériels Propriétaires
    let queryElementsMateriels = [];
    filters
      .find((elT) => elT.lvl1 == "materielsProprietaires")
      .content.forEach((el) => {
        if (filter.materielsProprietaires[el.lvl2]) {
          if (el.lvl2 == "SansMat") {
            queryElementsMateriels.push("MaterielsProprietaires is NULL  ");
          } else {
            queryElementsMateriels.push(
              "MaterielsProprietaires LIKE '%x " + el.display + "%'"
            );
          }
        }
      });

    // Actions
    let queryActionsTaches = [];
    filters
      .find((elT) => elT.lvl1 == "actionsTaches")
      .content.forEach((el) => {
        if (filter.actionsTaches[el.lvl2]) {
          if (el.lvl2 == "SansTache") {
            queryActionsTaches.push("Actions is NULL  ");
          } else {
            queryActionsTaches.push("Actions LIKE '%" + el.display + "%'");
          }
        }
      });

    // Construction de la requête

    let query = "";

    if (queryElementsPrecision.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += queryElementsPrecision.join(" AND ");
    }

    if (queryElementsQualifications.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += queryElementsQualifications.join(" AND ");
    }

    if (queryElementsTypes.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "(" + queryElementsTypes.join(" OR ") + ")";
    } else if (queryElementsTypes?.length == 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "Type IS NULL";
    }

    if (queryElementsProduitsInternes.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "(" + queryElementsProduitsInternes.join(" OR ") + ")";
    } else if (queryElementsProduitsInternes?.length == 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "ProduitsInternes IS NULL";
    }

    if (queryElementsMateriels.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "(" + queryElementsMateriels.join(" OR ") + ")";
    } else if (queryElementsMateriels?.length == 0) {
      // if (!isNullOrWhiteSpaces(query)) {
      //   query += " AND ";
      // }
      // query += "MaterielsProprietaires IS NULL";
    }

    if (queryActionsTaches.length > 0) {
      if (!isNullOrWhiteSpaces(query)) {
        query += " AND ";
      }
      query += "(" + queryActionsTaches.join(" OR ") + ")";
    } else if (queryActionsTaches?.length == 0) {
      // if (!isNullOrWhiteSpaces(query)) {
      //   query += " AND ";
      // }
      // query += "Actions IS NULL";
    }
    return query;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Géomarket</title>
        </Helmet>
        <div
          className="shadow m-4 bg-body rounded bg-light"
          id="geomarket"
          style={
            window.innerWidth <= 768
              ? { display: "flex" }
              : { display: "flex", height: "calc(100vh - 120px)" }
          }
        >
          <div className="row m-1" style={{ flex: "1 1 auto" }}>
            <div
              className="col-md-3 col-sm-12 p-4"
              style={
                window.innerWidth <= 768
                  ? {}
                  : {
                      overflow: "auto",
                      maxHeight: "100%",
                    }
              }
            >
              {this.renderFilters()}
            </div>
            <div
              className="col-md-9 col-sm-12"
              style={window.innerWidth <= 768 ? { padding: "0" } : {}}
            >
              <div
                style={
                  window.innerWidth <= 768
                    ? { height: "90vh" }
                    : { height: "calc(100% - 30px)", padding: "10px" }
                }
              >
                <MapGeomarket
                  queryFilter={this.computeQueryFromFilter(this.state.filter)}
                  setNumberOfPointsDisplayedAndMatched={
                    this.setNumberOfPointsDisplayedAndMatched
                  }
                  history={this.props.history}
                ></MapGeomarket>
                <span style={{ float: "right" }}>
                  Points affichés : {this.state.numberOfPointsDisplayed ?? 0}/
                  {this.state.numberOfPointsMatched ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export { Geomarket };
