import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "App/";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { frFR } from "@material-ui/core/locale";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://573954561ec343739bd5940923db702f@sentry.sol-solution.com/8",
  integrations: [new Sentry.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export const msalInstance = new PublicClientApplication(msalConfig);

let accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  frFR
);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App instance={msalInstance} />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
