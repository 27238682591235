import React from "react";
import { Helmet } from "react-helmet";

import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CalculetteAdmin, ReferentielDroit, ReferentielSimple } from ".";
import { GestionLicences } from "./GestionLicences/GestionLicences";

class PageAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        "Gestion référentiels",
        "Gestion droits",
        "Gestion matériels sous licences",
        "Gestion Calculette",
      ],
      active: "Gestion référentiels",
    };

    this.generateTabs = this.generateTabs.bind(this);
    this.renderActive = this.renderActive.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
  }

  componentDidMount() {}

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange() {}

  handleDelete() {}

  handleEditing(value) {
    this.setState({ editing: value });
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <ul
        className="nav nav-tabs"
        style={{ overflowX: "auto", overflowY: "clip" }}
      >
        {tabs.map((tab, index) => {
          return (
            <li
              className={"nav-item nav-tab align-self-end".concat(
                this.state.editing ? " not-allowed" : ""
              )}
              key={index}
            >
              <a
                className={
                  this.state.active == tab
                    ? "nav-link active bg-primary text-light fw-bold".concat(
                        this.state.editing ? " disabled" : ""
                      )
                    : "nav-link text-black-50".concat(
                        this.state.editing ? " disabled" : ""
                      )
                }
                onClick={() => {
                  this.setState({ active: tab });
                  sessionStorage.setItem("AdminOnglet", tab);
                }}
              >
                {tab}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Gestion référentiels":
        return (
          <ReferentielSimple
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Gestion droits":
        return (
          <ReferentielDroit
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Gestion matériels sous licences":
        return (
          <GestionLicences
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Gestion Calculette":
        return (
          <CalculetteAdmin
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          ></CalculetteAdmin>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Page d'Administration</title>
        </Helmet>

        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">Page d'Administration</h4>
              <div className="d-flex">
                <Link to="/" className="btn btn-primary nowrap ms-1">
                  Accueil
                </Link>
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            {this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
PageAdmin.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { PageAdmin };
