import http from "http-common";

const FonctionInformatiqueService = class {
  /**
   * Fonction permettant de récuperer toutes les fonctions informatiques
   * @returns la liste de toutes les fonctions informatiques
   */
  static getAll(params) {
    let url = "/FonctionsInformatique";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une fonction informatique via son id
   * @param {*} id, id de la fonction informatique à récupérer
   * @returns la fonction informatique souhaitée
   */
  static getById(id) {
    return http.get("/FonctionsInformatique/" + id);
  }
};

export { FonctionInformatiqueService };
