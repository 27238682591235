import http from "http-common";

class AffaireService {
  static cache = null;
  static loading = true;

  static cacheNS = null;
  static loadingNS = true;

  /**
   * Fonction permettant de récuperer tous les affaires avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des affaires respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params, refresh) {
    if (params) {
      return AffaireService.get(params);
    } else {
      if (refresh == true || AffaireService.loading) {
        AffaireService.cache = AffaireService.get();
        AffaireService.loading = false;
      }
      return AffaireService.cache;
    }
  }

  static get(params) {
    return http.get("/affaires/", { params });
  }

  static getAllViaPost(params) {
    return http.post("/affaires/get", params);
  }

  static getNonSoldees(params) {
    if (params) {
      return AffaireService.getNonSoldeesReq(params);
    } else {
      if (AffaireService.loadingNS) {
        AffaireService.cacheNS = AffaireService.getNonSoldeesReq();
        AffaireService.loadingNS = false;
      }
      return AffaireService.cacheNS;
    }
  }

  static getNonSoldeesReq(params) {
    return http.get("/affaires/", {
      params: {
        size: 25,
        global: params,
        actif: true,
        soldee: "false",
      },
    });
  }

  /**
   * Fonction permettant de créer un nouveau affaire pour un affaire donné
   * @param {*} affaire, le affaire à ajouter
   * @returns le affaire crée avec son id
   */
  static post(affaire) {
    return http.post("/affaires", affaire);
  }

  /**
   * Fonction permettant d'ajouter un historique à une affaire
   * @param {*} historique, l'historique à ajouter
   * @returns
   */
  static postHistorique(historique) {
    return http.post("/affaires/historique", historique);
  }

  /**
   * Fonction permettant de mettre à jour un affaire
   * @param {*} affaire, le affaire à mettre à jour
   * @returns
   */
  static put(affaire) {
    return http.put("/affaires", affaire);
  }

  static getHeures(idAff) {
    return http.get("/affaires/heures/" + idAff);
  }

  static getNdF(idAff) {
    return http.get("/affaires/ndf/" + idAff);
  }

  static getCommandes(idAff) {
    return http.get("/affaires/commandes/" + idAff);
  }

  /**
   * Fonction permettant de récuperer un affaire via son id
   * @param {*} id, id de du affaire à récupérer
   * @returns le affaire souhaité
   */
  static getById(id) {
    return http.get("/affaires/" + id);
  }

  /**
   * Fonction permettant de supprimer un affaire
   * @param {*} affaire, le affaire à supprimer
   * @returns
   */
  static delete(affaire) {
    return http.delete("/affaires/" + affaire.id);
  }

  static Extract(payload) {
    //const params = new URLSearchParams(payload);
    return http({
      url: "/affaires/extraction",
      data: payload,
      method: "POST",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static print(impression, idDevis) {
    return http({
      url: "/affaires/impression/" + impression + "/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }
}

export { AffaireService };
