import React from "react";

import { App } from "App";

import { PropTypes } from "prop-types";

import {
  Box,
  ButtonIcon,
  WarningBar,
  FormWithValidation,
  DialogFormHideable,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Input } from "_components/Input";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { LigneHeure } from "./LigneHeure";
import { LigneNewHeure } from "./LigneNewHeure";

import moment from "moment";

import "./Heures.css";
import { RubriqueHeurePersonnelService } from "_services";

class Heures extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedHeures: null,
      filterByMonth: false,
      filterByWeek: true,
      filterByPeriod: false,
      sortBy: "date",
      searchDesc: true,
    };

    this.handleClickEditionHeuresPersonnel =
      this.handleClickEditionHeuresPersonnel.bind(this);
    this.handleClickUndoHeuresPersonnel =
      this.handleClickUndoHeuresPersonnel.bind(this);
    this.handleChangeWeekFilter = this.handleChangeWeekFilter.bind(this);
    this.handleChangePeriodFilter = this.handleChangePeriodFilter.bind(this);
    this.handleChangeMonthFilter = this.handleChangeMonthFilter.bind(this);
    this.handleChangePeriodFilterCond =
      this.handleChangePeriodFilterCond.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.setOpenDialogAjoutHeures = this.setOpenDialogAjoutHeures.bind(this);
  }

  handleClickEditionHeuresPersonnel() {
    this.setState({
      savedHeures: [...this.props.heures],
    });
    this.props.handleEditingHeuresPersonnel(true);
  }

  handleClickUndoHeuresPersonnel() {
    this.props.handleChange("heures", this.state.savedHeures);
    this.props.handleEditingHeuresPersonnel(false);
  }

  handleChangeWeekFilter(acc, bool) {
    if (this.state.filterByWeek != bool) {
      this.props.filtrerSelonSemaine?.(bool);
      this.setState({
        filterByWeek: bool,
        filterByMonth: false,
        filterByPeriod: false,
      });
    }
  }

  handleChangeMonthFilter(acc, bool) {
    if (this.state.filterByMonth != bool) {
      this.props.filtrerSelonMois?.(bool);
      this.setState({
        filterByMonth: bool,
        filterByWeek: false,
        filterByPeriod: false,
      });
    }
  }

  handleChangePeriodFilterCond(acc, bool) {
    this.props.filtrerByPeriod?.(bool);

    this.setState({
      filterByPeriod: bool,
      filterByWeek: false,
      filterByMonth: false,
    });
  }

  handleChangePeriodFilter(acc, date) {
    this.props.filtrerSelonDate?.(date);
  }

  onSubmitForm(event) {
    event?.preventDefault();
    this.props.handleUpdateHeure();
    this.props.handleEditingHeuresPersonnel(false);
  }

  setOpenDialogAjoutHeures(openDialogFunc) {
    this.setState({ openDialogAjoutHeuresFunc: openDialogFunc });
  }

  getSortIcon(accessor) {
    let res = faSort;
    if (accessor == this.state.sortBy) {
      if (this.state.searchDesc) {
        res = faSortDown;
      } else {
        res = faSortUp;
      }
    }
    return res;
  }

  generateDialogForms() {
    return (
      <DialogFormHideable
        classNameButton="btn btn-success"
        tooltip={"Ajouter heure(s)"}
        dialogTitle={"Ajout de nouvelles heures"}
        labelCancelButton="Annuler"
        labelValidateButton="Ajouter"
        setOpenDialog={this.setOpenDialogAjoutHeures}
        onValidate={() => {
          this.props.submitNewHeuresPersonnel(
            document.getElementById("buttonValidateModifHeures")
          );
        }}
        onClose={this.props.clearNewHeuresPersonnel}
        fullScreen={true}
        body={
          <div
            id="PopupCommande"
            className={"col-lg-12 row"}
            style={{ minWidth: "1000px" }}
          >
            <table className="mt-1 text-uppercase heuresTable">
              <thead className="sticky-top bg-light">
                <tr>
                  <th className="maxWidth75">Produit interne</th>
                  <th className="maxWidth150">Rubrique</th>
                  <th>Affaire</th>
                  <th>Libellé</th>
                  <th className="maxWidth130">Date</th>
                  <th className="maxWidth75">Nombre</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.newHeures?.map((heure) => (
                  <LigneNewHeure
                    key={heure.id}
                    heure={heure}
                    handleChange={this.props.handleChange}
                    deleteLineNewHeurePersonnel={
                      this.props.deleteLineNewHeurePersonnel
                    }
                    copierLigneHeure={this.props.copierLigneHeure}
                    collerLigneHeure={this.props.collerLigneHeure}
                  />
                ))}
                <tr>
                  <td>
                    <ButtonIcon
                      className="btn btn-success addHeure"
                      icon={faPlus}
                      iconSize="sm"
                      onClick={() => this.props.addLineNewHeurePersonnel?.()}
                      throttleOnClick={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      />
    );
  }

  render() {
    const isResponsable = App.RightsGuard?.current?.isResponsable();

    const isRH = App.RightsGuard?.current?.isRH();

    const isPersonne =
      App.RightsGuard?.current?.isPersonne(this.props.personnel.oid) == "RW";

    const rightVisibiliteCocheHeure = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "VisibiliteCoches"
    );

    const rightModifCocheResponsable = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "Validation Heures Responsable"
    );

    if (isRH || isResponsable || isPersonne) {
      return (
        <>
          {this.generateDialogForms()}

          <div className="row">
            <div className="col-12 d-flex">
              <FormWithValidation
                className="d-flex flex-grow-1"
                onSubmit={this.onSubmitForm}
              >
                <Box
                  header={
                    <>
                      <div className="col-6 align-self-center py-2">
                        <div>Heures</div>
                      </div>
                      <div className="col-6 text-end py-2">
                        <ButtonIcon
                          icon={faPlus}
                          iconSize="sm"
                          onClick={this.state.openDialogAjoutHeuresFunc}
                          className="btn btn-success addHeure"
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : {}
                          }
                        ></ButtonIcon>
                        <ButtonIcon
                          id="buttonValidateModifHeures"
                          icon={faCheck}
                          className="btn btn-success text-light ms-1"
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                          type="submit"
                        ></ButtonIcon>
                        <ButtonIcon
                          icon={faTimes}
                          className="btn btn-danger text-light ms-1"
                          onClick={this.handleClickUndoHeuresPersonnel}
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                        ></ButtonIcon>
                        <ButtonIcon
                          icon={faPen}
                          className="btn btn-secondary text-light ms-1"
                          onClick={this.handleClickEditionHeuresPersonnel}
                          style={
                            this.props.editing
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                        ></ButtonIcon>
                      </div>
                    </>
                  }
                  body={
                    <>
                      <div className="col-3 align-self-center py-2">
                        Filtrer de date à date :
                        <div className="d-flex flex-row">
                          <div className="me-2">
                            <Input
                              value={this.state.filterByPeriod}
                              type="checkbox"
                              handleChange={this.handleChangePeriodFilterCond}
                            />
                          </div>
                          <Input
                            value={this.props.dateFiltrePeriodAffichees}
                            type="period"
                            handleChange={this.handleChangePeriodFilter}
                          />
                        </div>
                      </div>
                      <div className="col-2 align-self-center py-2">
                        Filtrer selon la semaine du :
                        <div className="d-flex flex-row">
                          <Input
                            value={this.state.filterByWeek}
                            type="checkbox"
                            handleChange={this.handleChangeWeekFilter}
                          />
                          <div className="d-flex flex-row">
                            <ButtonIcon
                              icon={faAngleLeft}
                              className="btn"
                              onClick={() => {
                                this.handleChangeWeekFilter(null, true);
                                this.props.changeDateWeek(-1);
                              }}
                              style={{
                                paddingLeft: "6px",
                                paddingBottom: "6px",
                                paddingTop: "0px",
                                paddingRight: "10px",
                              }}
                              iconSize="sm"
                              throttleOnClick={false}
                            ></ButtonIcon>

                            <div>
                              {"S" +
                                moment(
                                  this.props.dateFiltreSemaineAffichees
                                ).isoWeek() +
                                " (" +
                                moment(
                                  this.props.dateFiltreSemaineAffichees
                                ).isoWeekYear() +
                                ")"}
                            </div>

                            <ButtonIcon
                              icon={faAngleRight}
                              className="btn"
                              onClick={() => {
                                this.handleChangeWeekFilter(null, true);
                                this.props.changeDateWeek(1);
                              }}
                              style={{
                                paddingLeft: "10px",
                                paddingBottom: "6px",
                                paddingTop: "0px",
                              }}
                              iconSize="sm"
                              throttleOnClick={false}
                            ></ButtonIcon>
                          </div>
                        </div>
                        <div style={{ fontSize: "0.75rem" }}>
                          {moment(this.props.dateFiltreSemaineAffichees)
                            .isoWeekday(1)
                            .format("DD/MM/YY") +
                            " au " +
                            moment(this.props.dateFiltreSemaineAffichees)
                              .isoWeekday(7)
                              .format("DD/MM/YY")}
                        </div>
                      </div>
                      <div className="col-2 align-self-center py-2">
                        Filtrer selon ce mois :
                        <Input
                          value={this.state.filterByMonth}
                          type="checkbox"
                          handleChange={this.handleChangeMonthFilter}
                        />
                        <ButtonIcon
                          icon={faAngleLeft}
                          className="btn"
                          onClick={() => {
                            this.handleChangeMonthFilter(null, true);
                            this.props.changeDateMois(-1);
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingBottom: "6px",
                            paddingTop: "0px",
                            paddingRight: "10px",
                          }}
                          iconSize="sm"
                          throttleOnClick={false}
                        ></ButtonIcon>
                        {moment(this.props.dateFiltreMoisAffichees).format(
                          "MM/YYYY"
                        )}
                        <ButtonIcon
                          icon={faAngleRight}
                          className="btn"
                          onClick={() => {
                            this.handleChangeMonthFilter(null, true);
                            this.props.changeDateMois(1);
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingBottom: "6px",
                            paddingTop: "0px",
                          }}
                          iconSize="sm"
                          throttleOnClick={false}
                        ></ButtonIcon>
                      </div>
                      <div className="col-3 align-self-center py-2">
                        <div>
                          Nombre total d'heures pendant cette période :
                          <br />
                          <Input
                            type="decimal"
                            disabled
                            showClearButton={false}
                            value={this.props.heures
                              ?.slice()
                              .filter((h) => {
                                return !h.deleted;
                              })

                              .filter((h) => {
                                if (
                                  [
                                    "ARRET",
                                    "HRECU",
                                    "RPNUI",
                                    "RPSAM",
                                    "RPDIM",
                                    "RPFER",
                                    "REPAS",
                                    "DEPLA",
                                  ].includes(h.rubrique?.code)
                                )
                                  return false;
                                else return true;
                              })
                              .map((h) => h.nombre ?? 0)
                              .reduce((a, b) => a + b, 0)}
                          />
                        </div>
                      </div>

                      <div className="col-2 align-self-center py-2">
                        <div
                          style={{
                            width: "80px",
                            flexShrink: "0",
                          }}
                        >
                          Rubrique :
                        </div>
                        <Input
                          accessor="rubriqueId"
                          type="selectSearch"
                          service={RubriqueHeurePersonnelService.getAll}
                          valueFieldToDisplay={"designation"}
                          optionFieldToDisplay={"designation"}
                          optionFieldToReturn={"id"}
                          handleChange={this.props.handleChange}
                        />
                      </div>
                      <table className="mt-1 text-uppercase heuresTable table table-sm table-striped table-bordered">
                        <thead
                          className={
                            "sticky-top" +
                            (this.props.heures?.length != 0
                              ? ""
                              : " theadBorderRadius")
                          }
                        >
                          <tr>
                            <th className="maxWidth75">Produit interne</th>
                            <th>Rubrique</th>
                            <th>Affaire</th>
                            <th>Libellé</th>
                            <th
                              className="maxWidth130 hover"
                              onClick={() => {
                                if (this.state.sortBy != "date")
                                  this.setState({
                                    sortBy: "date",
                                    searchDesc: true,
                                  });
                                else
                                  this.setState({
                                    searchDesc: !this.state.searchDesc,
                                  });
                              }}
                            >
                              <div className="d-flex">
                                <div className="me-1">Date</div>
                                <FontAwesomeIcon
                                  icon={this.getSortIcon("date")}
                                  size="lg"
                                  className="orderby sortColumns"
                                />
                              </div>
                            </th>
                            <th className="maxWidth75">Nombre</th>
                            <th
                              style={
                                !isResponsable &&
                                !isRH &&
                                rightVisibiliteCocheHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              RESP
                              <Input
                                accessor={"selectAllRESP"}
                                type="checkbox"
                                value={this.props.selectAllRESP}
                                placeholder=""
                                showValidator={false}
                                showClearButton={false}
                                handleChange={this.props.handleChange}
                                disabled={
                                  (!(rightModifCocheResponsable == "RW") &&
                                    !isRH) ||
                                  !this.props.editing
                                }
                              />
                            </th>
                            <th
                              style={
                                !isRH && rightVisibiliteCocheHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              RH
                              <Input
                                accessor={"selectAllRH"}
                                value={this.props.selectAllRH}
                                type="checkbox"
                                placeholder=""
                                handleChange={this.props.handleChange}
                                showValidator={false}
                                showClearButton={false}
                                disabled={!isRH || !this.props.editing}
                              />
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.loadingHeure ? (
                            <tr
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <td colSpan={8}>
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  size="lg"
                                  className="fa-spin"
                                />
                              </td>
                            </tr>
                          ) : (
                            this.props.heures
                              ?.slice()
                              .filter((h) => {
                                return !h.Fille;
                              })
                              .filter((h) => {
                                return !h.deleted;
                              })

                              .sort((a, b) => {
                                //premier critère d'ordre : la date/rubrique
                                let bDate = new Date(b.date);
                                let aDate = new Date(a.date);

                                if (
                                  bDate.getDate() != aDate.getDate() ||
                                  bDate.getMonth() != aDate.getMonth() ||
                                  bDate.getFullYear() != aDate.getFullYear()
                                ) {
                                  return (
                                    (bDate - aDate) *
                                    (this.state.searchDesc ? -1 : 1)
                                  );
                                }

                                //deuxième critère d'ordre : la rubrique par ordre Sol Solution
                                if (
                                  a.rubrique?.ordreTri != b.rubrique?.ordreTri
                                )
                                  return (
                                    a.rubrique?.ordreTri - b.rubrique?.ordreTri
                                  );

                                //troisième critère d'ordre : la rubrique par ordre lexicographique
                                const nameA = a.rubrique?.code?.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.rubrique?.code?.toUpperCase(); // ignore upper and lowercase

                                if (a.rubrique == null || nameA < nameB) {
                                  return -1;
                                }
                                if (b.rubrique == null || nameA > nameB) {
                                  return 1;
                                }

                                // names must be equal
                                return 0;
                              })
                              .map((heure) => (
                                <LigneHeure
                                  key={heure.id}
                                  heure={heure}
                                  handleChange={this.props.handleChange}
                                  editing={this.props.editingHeuresPersonnel}
                                  deleteLineHeurePersonnel={
                                    this.props.deleteLineHeurePersonnel
                                  }
                                  copierLigneHeure={this.props.copierLigneHeure}
                                  collerLigneHeure={this.props.collerLigneHeure}
                                />
                              ))
                          )}
                        </tbody>
                      </table>
                    </>
                  }
                />
              </FormWithValidation>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Heures.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Heures };
