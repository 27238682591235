import React from "react";
import { produce } from "immer";
import { Input } from "_components/Input";
import { Box, ButtonIcon, DialogForm, FormWithValidation } from "_components";
import { ArticleService, TypesLicenceService } from "_services";
import {
  faCheck,
  faTimes,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

class GestionLicences extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: "modeleLicence",

      materielsSousLicences: null,
      savedMaterielsSousLicences: null,
      modelesLicences: null,
      savedModelesLicences: null,
      modules: null,
      savedModules: null,

      newMaterielSousLicence: null,
      newModeleLicence: null,
      newModule: null,

      loading: true,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.setOpenDialogCreationMatSousLicence =
      this.setOpenDialogCreationMatSousLicence.bind(this);
    this.setOpenDialogCreationModeleLicence =
      this.setOpenDialogCreationModeleLicence.bind(this);
  }

  componentDidMount() {
    this.setState({
      newMaterielSousLicence: this.createNewObject("materielSousLicence"),
      newModeleLicence: this.createNewObject("modeleLicence"),
    });

    ArticleService.getAllModules().then((res) => {
      this.setState({
        modules: res.data,
      });

      ArticleService.getAllModelesLicences().then((res) => {
        this.setState({
          modelesLicences: res.data,
        });

        ArticleService.getAllMaterielsSousLicence().then((res) =>
          this.setState({
            materielsSousLicences: res.data,
            loading: false,
          })
        );
      });
    });
  }

  setOpenDialogCreationMatSousLicence(openDialogFunc) {
    this.setState({ openDialogCreationMatSousLicenceFunc: openDialogFunc });
  }

  setOpenDialogCreationModeleLicence(openDialogFunc) {
    this.setState({ openDialogCreationModeleLicenceFunc: openDialogFunc });
  }

  handleClickEdition() {
    this.setState({
      savedMaterielsSousLicences: [...this.state.materielsSousLicences],
      savedModelesLicences: [...this.state.modelesLicences],
      savedModules: [...this.state.modules],
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.handleChange(this.state.selected, null);
    this.props.handleEditing(false);
  }

  handleClickValidate(event) {
    event.preventDefault();
    this.handleUpdate();
    this.props.handleEditing(false);
  }

  handleClickAdd() {
    switch (this.state.selected) {
      case "materielSousLicence":
        this.state.openDialogCreationMatSousLicenceFunc();
        break;
      case "modeleLicence":
        this.state.openDialogCreationModeleLicenceFunc();
        break;
      default:
    }
  }

  /**
   * Permet de mettre a jour l'etat des liens sans devoir les recharger
   * @param {*} accessor permettant d'identifier l'action à effectuer
   * @param {*} value
   */
  handleChange(accessor, value, callbackFunction) {
    if (accessor === "materielSousLicence") {
      //Undo
      this.setState(
        produce((prevState) => {
          prevState.materielsSousLicences =
            prevState.savedMaterielsSousLicences;
        })
      );
    } else if (accessor === "modeleLicence") {
      //Undo
      this.setState(
        produce((prevState) => {
          prevState.modelesLicences = prevState.savedModelesLicences;
        })
      );
    } else if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      var id = accessorSplit[1];
      var accessor = accessorSplit[2];

      if (accessorSplit[0] === "materielSousLicences") {
        // Format materielSousLicences.id.accessor
        this.setState(
          produce((prevState) => {
            let prev = prevState.materielsSousLicences.find(
              (el) => el.aR_Ref_Materiel == id
            );
            prev[accessor] = value;
          }),
          callbackFunction
        );
      } else if (accessorSplit[0] === "modelesLicences") {
        // Format modelesLicences.id.accessor
        this.setState(
          produce((prevState) => {
            let prev = prevState.modelesLicences.find((el) => el.aR_Ref == id);
            prev[accessor] = value;
          }),
          callbackFunction
        );
      }
    }
  }

  handleUpdate() {
    switch (this.state.selected) {
      case "materielSousLicence":
        this.state.materielsSousLicences.map((materiel) => {
          //On update les éléments un à un, uniquement s'ils ont été modifiés
          const prev = this.state.savedMaterielsSousLicences.find(
            (el) => el.aR_Ref_Materiel == materiel.aR_Ref_Materiel
          );

          if (materiel !== prev) {
            ArticleService.putMaterielSousLicence(
              materiel.aR_Ref_Materiel,
              materiel
            );
          }
        });
        break;
      case "modeleLicence":
        this.state.modelesLicences.map((licence) => {
          //On update les éléments un à un, uniquement s'ils ont été modifiés
          const prev = this.state.savedModelesLicences.find(
            (el) => el.aR_Ref == licence.aR_Ref
          );

          if (licence != prev) {
            ArticleService.putModeleLicence(licence.aR_Ref, licence);
          }
        });
        break;
      default:
    }
  }

  createNewObject(type) {
    switch (type) {
      case "materielSousLicence":
        return {
          aR_Ref_Materiel: null,
          modeleLicence: null,
          quantiteLicence: null,
        };
      case "modeleLicence":
        return {
          aR_Ref: null,
          classification: null,
          defaultModules: [],
        };
      default:
    }
  }

  ArrayDisplay(array) {
    return array?.map((module) => module.nom).join(", ");
  }

  generateDialogForms() {
    return (
      <>
        <DialogForm
          classNameButton="btn btn-success"
          tooltip="Ajout d'un matériel sous licence"
          dialogTitle="Ajout d'un matériel sous licence"
          labelCancelButton="Annuler"
          labelValidateButton="Créer"
          setOpenDialog={this.setOpenDialogCreationMatSousLicence}
          onValidate={() => {
            ArticleService.postMaterielSousLicence(
              this.state.newMaterielSousLicence
            ).then((res) => {
              this.setState({
                ...this.state,
                materielsSousLicences: [
                  ...this.state.materielsSousLicences,
                  res.data,
                ],
              });
            });
            this.setState({
              newMaterielSousLicence: this.createNewObject(this.state.selected),
            });
          }}
          onClose={() => {
            this.setState({
              newMaterielSousLicence: this.createNewObject(this.state.selected),
            });
          }}
          body={
            <div
              id="PopupCommande"
              className={"col-lg-12 row"}
              style={{ minWidth: "1000px" }}
            >
              <Input
                type="selectSearchDisabledValues"
                label="Référence materiel sous licence"
                value=""
                valueFieldToDisplay="aR_Ref"
                optionFieldToDisplay="aR_Ref"
                service={ArticleService.getAll}
                disableOption={(article) =>
                  this.state.materielsSousLicences.some(
                    (materiel) => materiel.aR_Ref_Materiel == article.aR_Ref
                  )
                }
                required={true}
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newMaterielSousLicence.aR_Ref_Materiel =
                        value?.aR_Ref;
                    })
                  );
                }}
              />
              <Input
                type="selectSearch"
                label="Référence licence"
                value=""
                valueFieldToDisplay={"aR_Ref"}
                optionFieldToDisplay={"aR_Ref"}
                service={ArticleService.getAllModelesLicences}
                required={true}
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newMaterielSousLicence.modeleLicence = value;
                    })
                  );
                }}
              />
              <Input
                type="decimal"
                label="Quantité licences"
                value={this.state.newMaterielSousLicence.quantiteLicence}
                required={true}
                showClearButton={false}
                numberOfDecimals={0}
                handleBlur={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newMaterielSousLicence.quantiteLicence = value;
                    })
                  );
                }}
              />
            </div>
          }
        />

        <DialogForm
          classNameButton="btn btn-success"
          tooltip="Ajout d'un modèle de licence"
          dialogTitle="Ajout d'un modèle de licence"
          labelCancelButton="Annuler"
          labelValidateButton="Créer"
          setOpenDialog={this.setOpenDialogCreationModeleLicence}
          onValidate={() => {
            ArticleService.postModeleLicence(this.state.newModeleLicence).then(
              (res) => {
                this.setState({
                  ...this.state,
                  modelesLicences: [...this.state.modelesLicences, res.data],
                });
              }
            );
            this.setState({
              newModeleLicence: this.createNewObject(this.state.selected),
            });
          }}
          onClose={() => {
            this.setState({
              newModeleLicence: this.createNewObject(this.state.selected),
            });
          }}
          body={
            <div
              id="PopupCommande"
              className={"col-lg-12 row"}
              style={{ minWidth: "1000px" }}
            >
              <Input
                type="selectSearchDisabledValues"
                label="Référence licence"
                value=""
                valueFieldToDisplay="aR_Ref"
                optionFieldToDisplay="aR_Ref"
                service={ArticleService.getAll}
                disableOption={(article) =>
                  this.state.modelesLicences.some(
                    (modele) => modele.aR_Ref == article.aR_Ref
                  )
                }
                required={true}
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newModeleLicence.aR_Ref = value.aR_Ref;
                    })
                  );
                }}
              />
              <Input
                type="selectSearch"
                label="Type de licence"
                value=""
                valueFieldToDisplay="designation"
                optionFieldToDisplay="designation"
                service={TypesLicenceService.getAllClassification}
                required={true}
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newModeleLicence.classification = value;
                    })
                  );
                }}
              />
              <Input
                type="multipleSelect"
                label="Modules"
                value={this.state.newModeleLicence.defaultModules}
                valueFieldToDisplay="nom"
                optionFieldToDisplay="nom"
                service={ArticleService.getAllModules}
                required={true}
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.newModeleLicence.defaultModules = value;
                    })
                  );
                }}
              />
            </div>
          }
        />
      </>
    );
  }

  generateRefTable() {
    switch (this.state.selected) {
      case "materielSousLicence":
        return (
          <>
            <thead>
              <tr>
                <th className="ps-2 position-sticky bg-light">
                  Article sous licence
                </th>
                <th className="ps-2 position-sticky bg-light">Licence</th>
                <th className="ps-2 position-sticky bg-light">Quantite</th>
              </tr>
            </thead>
            <tbody>
              {this.state.materielsSousLicences?.map((materiel) => {
                return !this.props.editing ? (
                  <tr key={materiel.aR_Ref_Materiel}>
                    <td>{materiel.aR_Ref_Materiel}</td>
                    <td>{materiel.modeleLicence.aR_Ref}</td>
                    <td>{materiel.quantiteLicence}</td>
                  </tr>
                ) : (
                  <tr key={materiel.aR_Ref_Materiel}>
                    <td>{materiel.aR_Ref_Materiel}</td>
                    <td>
                      <Input
                        type="selectSearch"
                        value={materiel.modeleLicence}
                        accessor={
                          "materielSousLicences." +
                          materiel.aR_Ref_Materiel +
                          ".modeleLicence"
                        }
                        valueFieldToDisplay="aR_Ref"
                        optionFieldToDisplay="aR_Ref"
                        service={ArticleService.getAllModelesLicences}
                        required={true}
                        showClearButton={false}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        type="decimal"
                        value={materiel.quantiteLicence}
                        accessor={
                          "materielSousLicences." +
                          materiel.aR_Ref_Materiel +
                          ".quantiteLicence"
                        }
                        required={true}
                        showClearButton={false}
                        numberOfDecimals={0}
                        handleBlur={this.handleChange}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        );
      case "modeleLicence":
        return (
          <>
            <thead>
              <tr>
                <th className="ps-2 position-sticky bg-light">Licence</th>
                <th className="ps-2 position-sticky bg-light">
                  Type de licence
                </th>
                <th className="ps-2 position-sticky bg-light">
                  Modules par défaut
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.modelesLicences?.map((modele) => {
                return !this.props.editing ? (
                  <tr key={modele.aR_Ref}>
                    <td>{modele.aR_Ref}</td>
                    <td>{modele.classification?.designation}</td>
                    <td>{this.ArrayDisplay(modele.defaultModules)}</td>
                  </tr>
                ) : (
                  <tr key={modele.aR_Ref}>
                    <td>{modele.aR_Ref}</td>
                    <td>
                      <Input
                        type="selectSearch"
                        value={modele.classification ?? ""}
                        service={TypesLicenceService.getAllClassification}
                        optionFieldToDisplay="designation"
                        valueFieldToDisplay="designation"
                        accessor={
                          "modelesLicences." + modele.aR_Ref + ".classification"
                        }
                        required={true}
                        showClearButton={false}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        type="multipleSelect"
                        value={modele.defaultModules}
                        accessor={
                          "modelesLicences." + modele.aR_Ref + ".defaultModules"
                        }
                        valueFieldToDisplay="nom"
                        optionFieldToDisplay="nom"
                        service={ArticleService.getAllModules}
                        required={true}
                        showClearButton={false}
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        );
      default:
        return <div>Erreur dans le chargement de la page</div>;
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Box
              header={
                <>
                  <div className="col-6 align-self-center py-2">
                    Gestion des matériels avec licence
                  </div>
                  <div className="col-6 text-end py-2">
                    <ButtonIcon
                      tooltip={"Création"}
                      icon={faPlus}
                      iconSize="sm"
                      onClick={this.handleClickAdd}
                      className="btn btn-success addReferentiel"
                      style={
                        this.state.selected == "module" || this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                    <ButtonIcon
                      icon={faCheck}
                      className="btn btn-success text-light ms-1"
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                      onClick={this.handleClickValidate}
                    />
                    <ButtonIcon
                      icon={faTimes}
                      className="btn btn-danger text-light ms-1"
                      onClick={this.handleClickUndo}
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                    <ButtonIcon
                      icon={faPen}
                      className="btn btn-secondary text-light ms-1"
                      onClick={this.handleClickEdition}
                      style={
                        this.state.selected == "module" || this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                  </div>
                </>
              }
              body={
                <>
                  <div className="col-3 align-self-center py-2">
                    <Input
                      type="selectSearch"
                      value={{
                        option: "modeleLicence",
                        nom: "Modèles de licence",
                      }}
                      accessor="option"
                      valueFieldToDisplay={"nom"}
                      optionFieldToDisplay={"nom"}
                      showValidator={false}
                      showClearButton={false}
                      disabled={this.props.editing}
                      handleChange={(acc, value) => {
                        if (value) {
                          this.setState({
                            selected: value.option,
                          });
                        }
                      }}
                      options={[
                        {
                          option: "materielSousLicence",
                          nom: "Matériels sous licence",
                        },
                        { option: "modeleLicence", nom: "Modèles de licence" },
                      ]}
                    />
                  </div>
                  <FormWithValidation
                    id="FormulaireMaterielLicences"
                    onSubmit={this.handleClickValidate}
                  >
                    <table className="table table-sm table-striped m-0">
                      {this.generateRefTable()}
                    </table>
                  </FormWithValidation>
                </>
              }
            />
          </div>
        </div>
        {this.state.newMaterielSousLicence && this.state.newModeleLicence
          ? this.generateDialogForms()
          : null}
      </>
    );
  }
}

export { GestionLicences };
