import http from "http-common";

const CoefficientService = class {
  /**
   * Fonction permettant de récuperer toutes les coefficients
   * @returns la liste de toutes les coefficients
   */
  static getAll(params) {
    let url = "/Coefficients";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une coefficient via son id
   * @param {*} id, id de la coefficient à récupérer
   * @returns la coefficient souhaitée
   */
  static getById(id) {
    return http.get("/Coefficients/" + id);
  }

  /**
   * Fonction permettant de récupérer des coefficients l'id du statut et de la position
   * @param {*} id, id de la coefficient à récupérer
   * @returns la coefficient souhaitée
   */
  static getByIdStatutEtPosition(idStatut, idPosition) {
    return http.get("/personnels/statuts/" + idStatut + "/" + idPosition);
  }
};

export { CoefficientService };
