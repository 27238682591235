import React from "react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Validation des props
import { PropTypes } from "prop-types";
import { isEqual, throttle } from "lodash";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

/**
 * Génère un composant bouton avec une icône et un texte optionnel à l'interieur
 */
class ButtonIcon extends React.Component {
  constructor(props) {
    super(props);

    this.RealOnclick = this.RealOnclick.bind(this);
  }

  shouldComponentUpdate(prevProps) {
    return !isEqual(prevProps, this.props);
  }
  componentDidMount() {
    this.RealOnclick = throttle(
      this.RealOnclick,
      this.props.throttleOnClick ? 1000 : 100,
      {
        leading: true,
        trailing: false,
      }
    );
  }

  RealOnclick(event) {
    this.props.onClick(event);
  }

  render() {
    var renderButton = (
      <button
        type={this.props.type}
        form={this.props.form}
        onClick={this.RealOnclick}
        className={"solwayFont " + this.props.className}
        style={this.props.style}
        id={this.props.id}
        disabled={this.props.disabled}
        tabIndex={this.props.tabIndex}
      >
        {this.props.icon ? (
          <FontAwesomeIcon size={this.props.iconSize} icon={this.props.icon} />
        ) : null}
        {this.props.textOption ? this.props.textOption : null}
      </button>
    );

    if (this.props.tooltip) {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 1000, hide: 100 }}
          overlay={<Tooltip id="tooltip-top">{this.props.tooltip}</Tooltip>}
        >
          <span>{renderButton} </span>
        </OverlayTrigger>
      );
    } else {
      return renderButton;
    }
  }
}

/**
 * Validation des props :
 * icon : icone de fontawesome
 * iconSize : une des tailles gérées par fontawesome ["lg","xs","sm","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]
 * textOption : texte à ajouter à côté de l'icône
 * onClick : fonction appliquée lors du click sur le bouton
 * className : classes à appliquer au bouton
 * tooltip : contenu du tooltip
 * id : id du bouton
 */
ButtonIcon.propTypes = {
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  textOption: PropTypes.any,
  type: PropTypes.string,
  form: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  throttleOnClick: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  onClick: () => {
    return null;
  },
  className: "",
  textOption: null,
  type: "button",
  disabled: false,
  tabIndex: "0",
  throttleOnClick: true,
};
export { ButtonIcon };
