import http from "http-common";

const AttributionSpecifiqueService = class {
  /**
   * Fonction permettant de récuperer toutes les attributions spécifiques
   * @returns la liste de toutes les attributions spécifiques
   */
  static getAll(params) {
    let url = "/AttributionSpecifiques";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une attribution spécifique via son id
   * @param {*} id, id de la attribution spécifique à récupérer
   * @returns la attribution spécifique souhaitée
   */
  static getById(id) {
    return http.get("/AttributionSpecifiques/" + id);
  }
};

export { AttributionSpecifiqueService };
