import React from "react";

import { App } from "App";

import { BoxInformationsInline, WarningBar } from "_components";
import { JourNuitService } from "_services";

class ChantierAffaire extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Chantier");
    if (right == "RW" || right == "RO") {
      const isAffaireEmpty = !Object.entries(this.props.affaire).length;
      if (isAffaireEmpty) {
        return (
          <div className="alert alert-danger mt-2 mx-auto" role="alert">
            Une erreur a été rencontrée ! (Les informations de l'affaire ne sont
            pas disponibles)
          </div>
        );
      } else {
        const informationsChantier = [
          // si presta l'input est ajouté sinon on met du vide ¯\_(ツ)_/¯
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Date chantier terminé",
                  accessor: "dateFinChantier",
                  type: "date",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Chantier terminé",
                  accessor: "chantierTermine",
                  type: "checkbox",
                  functionAppliedToValue: (value) => {
                    return value ? "Oui" : "Non";
                  },
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Délai de remise du rapport (en semaine)",
                  accessor: "delaisSemaineRemiseRapport",
                  type: "decimal",
                  colSize: 6,
                  handleBlur: this.props.handleChange,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Date prévisionnelle de remise du rapport final",
                  accessor: "datePrevisionnelleRemiseRapportFinal",
                  type: "date",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Date de remise du rapport final",
                  accessor: "dateRemiseRapport",
                  type: "date",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Commentaire rapport final",
                  accessor: "commentaireRapportFinal",
                  type: "textArea",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta
            ? [
                {
                  label: "Présence de rapport intermediaire",
                  accessor: "presenceRapportIntermediaire",
                  type: "checkbox",
                  functionAppliedToValue: (value) => {
                    return value ? "Oui" : "Non";
                  },
                  colSize: 12,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta &&
          this.props.affaire?.presenceRapportIntermediaire
            ? [
                {
                  label: "Date de remise du rapport intermédiaire 1",
                  accessor: "datePrevisionnelleRemiseRapportIntermediaire1",
                  type: "date",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta &&
          this.props.affaire?.presenceRapportIntermediaire
            ? [
                {
                  label: "Commentaire rapport intermédiaire 1",
                  accessor: "commentaireRapportInter1",
                  type: "textArea",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta &&
          this.props.affaire?.presenceRapportIntermediaire
            ? [
                {
                  label: "Date de remise du rapport intermédiaire 2",
                  accessor: "datePrevisionnelleRemiseRapportIntermediaire2",
                  type: "date",
                  colSize: 6,
                },
              ]
            : []),
          ...(this.props.affaire?.produitInterne?.isPresta &&
          this.props.affaire?.presenceRapportIntermediaire
            ? [
                {
                  label: "Commentaire rapport intermédiaire 2",
                  accessor: "commentaireRapportInter2",
                  type: "textArea",
                  colSize: 6,
                },
              ]
            : []),
          {
            label: "Laboratoire",
            accessor: "labo",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          ...(this.props.affaire?.labo
            ? [
                {
                  label: "Date laboratoire prévisionnelle",
                  accessor: "dateLaboratoirePrevisionnelle",
                  type: "date",
                  colSize: 6,
                },
                {
                  label: "Date laboratoire",
                  accessor: "dateLaboratoire",
                  type: "date",
                  colSize: 6,
                },
                {
                  label: "Commentaire labo",
                  accessor: "commentaireLabo",
                  type: "textArea",
                  colSize: 12,
                },
              ]
            : []),

          {
            label: "Plan",
            accessor: "plan",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          {
            label: "NB de Lots",
            accessor: "nbLot",
            type: "decimal",
            numberOfDecimals: 0,
            colSize: 6,
            handleBlur: this.props.handleChange,
          },
          {
            label: "Date de visite du site",
            accessor: "dateVisiteChantier",
            type: "date",
            colSize: 6,
          },
          {
            label: "Superficie",
            accessor: "superficie",
            type: "text",
            colSize: 6,
          },
          {
            label: "Niveau",
            accessor: "niveau",
            type: "decimal",
            numberOfDecimals: 0,
            colSize: 6,
            handleBlur: this.props.handleChange,
          },
          {
            label: "Sous-sol",
            accessor: "sousSol",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          {
            label: "Site facile d'accès",
            accessor: "siteFacileDAcces",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          {
            label: "Descente charge",
            accessor: "descenteCharge",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          {
            label: "DICT",
            accessor: "dict",
            type: "checkbox",
            functionAppliedToValue: (value) => {
              return value ? "Oui" : "Non";
            },
            colSize: 6,
          },
          {
            label: "Date DICT",
            accessor: "dateDICT",
            type: "date",
            colSize: 6,
          },

          {
            label: "Nombre de KM supplémentaire",
            accessor: "nombreKmSupp",
            type: "decimal",
            numberOfDecimals: 2,
            colSize: 6,
            handleBlur: this.props.handleChange,
          },
          {
            label: "Nom Contact+",
            accessor: "contactBonus.nomContact",
            type: "text",
            colSize: 6,
          },
          {
            label: "Email Contact+",
            accessor: "contactBonus.email",
            type: "email",
            colSize: 6,
          },
          {
            label: "Telephone Contact+",
            accessor: "contactBonus.telephone",
            type: "text",
            colSize: 6,
          },
          {
            label: "Commentaire sur le chantier",
            accessor: "commentaireChantier",
            type: "text",

            colSize: 12,
          },
        ];

        const informationsVoie = [
          {
            label: "Nb de jours",
            accessor: "nbJour",
            type: "decimal",
            handleBlur: this.props.handleChange,
            colSize: 6,
          },
          {
            label: "Nb de nuits",
            accessor: "nbNuit",
            type: "decimal",
            handleBlur: this.props.handleChange,

            colSize: 6,
          },
          {
            label: "Voie",
            accessor: "voie",
            type: "text",
            colSize: 6,
          },
          {
            label: "Linéaire (Km)",
            type: "decimal",
            accessor: "kmLineaire",
            handleBlur: this.props.handleChange,

            colSize: 6,
          },
          {
            label: "Ligne",
            accessor: "numLigne",
            type: "decimal",
            handleBlur: this.props.handleChange,
            colSize: 6,
          },
          {
            label: "Nombre de sondages",
            accessor: "nombreDeSondages",
            handleBlur: this.props.handleChange,

            type: "decimal",
            colSize: 6,
          },
          {
            label: "Nombre d'Ouvrages",
            accessor: "nombreDOuvrages",
            type: "decimal",
            handleBlur: this.props.handleChange,

            colSize: 6,
          },
          {
            label: "PK début",
            accessor: "pK_Debut",
            type: "text",
            functionAppliedToValue: (value) => {
              return value ? <div>{value}</div> : null;
            },
            colSize: 6,
          },
          {
            label: "PK fin",
            accessor: "pK_Fin",
            type: "text",
            functionAppliedToValue: (value) => {
              return value ? <div>{value}</div> : null;
            },
            colSize: 6,
          },
          {
            label: "Travail",
            accessor: "travail",
            type: "selectSearch",
            colSize: 6,
            service: JourNuitService.getAll,
            functionAppliedToValue: (value) => {
              return value ? value.designation : null;
            },
            optionFieldToDisplay: ["designation"],
            valueFieldToDisplay: ["designation"],
          },
        ];

        let adresseChantier = [];
        if (this.props.affaire?.produitInterne?.isPresta)
          adresseChantier = [
            {
              label: "Adresse Chantier",
              type: "address",
              functionAppliedToValue: () => {
                return (
                  <>
                    {this.props.affaire.adresseChantier?.intitule ?? ""}
                    <br />
                    {this.props.affaire.adresseChantier?.voie ?? ""}
                    <br />
                    {this.props.affaire.adresseChantier?.complement ?? ""}
                    <br />
                    {(this.props.affaire.adresseChantier?.cp ?? "") +
                      " " +
                      (this.props.affaire.adresseChantier?.ville ?? "")}
                    <br />
                    {this.props.affaire.adresseChantier?.pays?.nom_Fr ?? ""}
                  </>
                );
              },
              accessor: "adresseChantier",
              colSize: 6,
            },
          ];

        return (
          <>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <BoxInformationsInline
                  title="Information Chantier"
                  datas={informationsChantier}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  handleUpdate={this.props.handleUpdate}
                  colSize="3"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  headerToast="Affaire"
                  NoEdition={right == "RO"}
                />
              </div>
              <div className="col-md-12 col-lg-6">
                {this.props.affaire?.produitInterne?.isPresta ? (
                  <BoxInformationsInline
                    title="Adresse Chantier"
                    datas={adresseChantier}
                    state={this.props.affaire}
                    stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                    service={this.props.service}
                    handleChange={this.props.handleChange}
                    colSize="3"
                    handleEditing={this.props.handleEditing}
                    editing={this.props.editing}
                    headerToast="Affaire"
                    NoEdition={right == "RO"}
                    handleUpdate={this.props.handleUpdate}
                  />
                ) : null}
                <BoxInformationsInline
                  title="Information Voie"
                  datas={informationsVoie}
                  state={this.props.affaire}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  handleBlur={this.props.handleChange}
                  colSize="3"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  headerToast="Affaire"
                  NoEdition={right == "RO"}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { ChantierAffaire };
