import { sanitizeValue } from "_utils/DecimalFunctions.js";

function computePersonnel(newCalculette) {
  // ===== Personnel =====
  // Personnel_ResponsableService
  newCalculette.personnel_ResponsableService_Total =
    newCalculette.personnel_ResponsableService_Devis +
    newCalculette.personnel_ResponsableService_Preparation +
    newCalculette.personnel_ResponsableService_AmeneRepli +
    newCalculette.personnel_ResponsableService_MesuresOuFormation +
    newCalculette.personnel_ResponsableService_Labo +
    newCalculette.personnel_ResponsableService_RapportOuAdministratif;

  newCalculette.personnel_ResponsableService_TotalCouts =
    sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_ResponsableService_Total);

  // Personnel_ResponsablePole
  newCalculette.personnel_ResponsablePole_Total =
    newCalculette.personnel_ResponsablePole_Devis +
    newCalculette.personnel_ResponsablePole_Preparation +
    newCalculette.personnel_ResponsablePole_AmeneRepli +
    newCalculette.personnel_ResponsablePole_MesuresOuFormation +
    newCalculette.personnel_ResponsablePole_Labo +
    newCalculette.personnel_ResponsablePole_RapportOuAdministratif;

  newCalculette.personnel_ResponsablePole_TotalCouts =
    sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_ResponsablePole_Total);

  // Personnel_ChargeDAffaire
  newCalculette.personnel_ChargeDAffaire_Total =
    newCalculette.personnel_ChargeDAffaire_Devis +
    newCalculette.personnel_ChargeDAffaire_Preparation +
    newCalculette.personnel_ChargeDAffaire_AmeneRepli +
    newCalculette.personnel_ChargeDAffaire_MesuresOuFormation +
    newCalculette.personnel_ChargeDAffaire_Labo +
    newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif;

  newCalculette.personnel_ChargeDAffaire_TotalCouts =
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_Total);

  // Personnel_Techniciens
  newCalculette.personnel_Techniciens_Total =
    newCalculette.personnel_Techniciens_Devis +
    newCalculette.personnel_Techniciens_Preparation +
    newCalculette.personnel_Techniciens_AmeneRepli +
    newCalculette.personnel_Techniciens_MesuresOuFormation +
    newCalculette.personnel_Techniciens_Labo +
    newCalculette.personnel_Techniciens_RapportOuAdministratif;

  newCalculette.personnel_Techniciens_TotalCouts =
    sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) *
    (sanitizeValue(newCalculette.personnel_Techniciens_Total) -
      sanitizeValue(newCalculette.personnel_Techniciens_Labo) +
      sanitizeValue(newCalculette.personnel_Techniciens_Labo) *
        (1 +
          sanitizeValue(newCalculette.general_CoefficientTemps_Laboratoire)));

  // Personnel_Assistante
  newCalculette.personnel_Assistante_Total =
    newCalculette.personnel_Assistante_Devis +
    newCalculette.personnel_Assistante_Preparation +
    newCalculette.personnel_Assistante_AmeneRepli +
    newCalculette.personnel_Assistante_MesuresOuFormation +
    newCalculette.personnel_Assistante_Labo +
    newCalculette.personnel_Assistante_RapportOuAdministratif;

  newCalculette.personnel_Assistante_TotalCouts =
    sanitizeValue(newCalculette.personnel_Assistante_Total) *
    sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire);

  newCalculette.personnel_HeuresDeChantierDeNuit_TotalCouts =
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit);

  // Personnel_HeuresDimanche
  newCalculette.personnel_HeuresDimanche_TotalCouts =
    sanitizeValue(newCalculette.personnel_HeuresDimanche_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_HeuresDimanche);

  // Personnel_HeuresSupp
  newCalculette.personnel_HeuresSupp_TotalCouts =
    sanitizeValue(newCalculette.personnel_HeuresSupp_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_HeuresSupp);
  // Personnel_HeuresFeriees
  newCalculette.personnel_HeuresFeriees_TotalCouts =
    sanitizeValue(newCalculette.personnel_HeuresFeriees_CoutUnitaire) *
    sanitizeValue(newCalculette.personnel_HeuresFeriees);

  // Personnel_SousTotaux
  newCalculette.personnel_Total =
    newCalculette.personnel_ResponsableService_Total +
    newCalculette.personnel_ResponsablePole_Total +
    newCalculette.personnel_ChargeDAffaire_Total +
    newCalculette.personnel_Techniciens_Total +
    newCalculette.personnel_Assistante_Total;

  newCalculette.personnel_TotalCouts =
    sanitizeValue(newCalculette.personnel_ResponsableService_TotalCouts) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_TotalCouts) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_TotalCouts) +
    sanitizeValue(newCalculette.personnel_Techniciens_TotalCouts) +
    sanitizeValue(newCalculette.personnel_Assistante_TotalCouts);

  return newCalculette;
}

function computePrime(newCalculette) {
  // ===== Prime ====
  // Prime_PrimeDeDepla5j
  newCalculette.prime_PrimeDeDepla5j =
    (sanitizeValue(newCalculette.general_NombreDeJoursDeDeplacements_Tech) == 5
      ? sanitizeValue(newCalculette.general_NombreDeTechnicienSurChantier)
      : 0) +
    (sanitizeValue(
      newCalculette.general_NombreDeJoursDeDeplacements_ChargeAffaire
    ) == 5
      ? sanitizeValue(newCalculette.general_NombreDeChargeAffaireSurChantier)
      : 0);

  newCalculette.prime_PrimeDeDepla5j_TotalCouts =
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j);

  // Prime_PrimeDeDepla4j
  newCalculette.prime_PrimeDeDepla4j =
    (sanitizeValue(newCalculette.general_NombreDeJoursDeDeplacements_Tech) == 4
      ? sanitizeValue(newCalculette.general_NombreDeTechnicienSurChantier)
      : 0) +
    (sanitizeValue(
      newCalculette.general_NombreDeJoursDeDeplacements_ChargeAffaire
    ) == 4
      ? sanitizeValue(newCalculette.general_NombreDeChargeAffaireSurChantier)
      : 0);

  newCalculette.prime_PrimeDeDepla4j_TotalCouts =
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j);

  // Prime_PrimeDeDepla
  newCalculette.prime_PrimeDeDeplacement =
    sanitizeValue(newCalculette.general_NombreDeTechnicienSurChantier) *
      sanitizeValue(newCalculette.general_NombreDeJoursDeDeplacements_Tech) +
    sanitizeValue(newCalculette.general_NombreDeChargeAffaireSurChantier) *
      sanitizeValue(
        newCalculette.general_NombreDeJoursDeDeplacements_ChargeAffaire
      );

  newCalculette.prime_PrimeDeDeplacement_TotalCouts =
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement);

  // Prime_PrimeDeNuit
  newCalculette.prime_PrimeDeNuit =
    sanitizeValue(
      newCalculette.general_NombreDeTechnicienSurChantier +
        newCalculette.general_NombreDeChargeAffaireSurChantier
    ) * sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);

  newCalculette.prime_PrimeDeNuit_TotalCouts =
    sanitizeValue(newCalculette.prime_PrimeDeNuit_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_PrimeDeNuit);

  // Prime_Weekend

  newCalculette.prime_Weekend_TotalCouts =
    sanitizeValue(newCalculette.prime_Weekend) *
    sanitizeValue(newCalculette.prime_Weekend_CoutUnitaire);

  // Prime_MajorationNuit
  newCalculette.prime_MajorationHeuresChantierNuit =
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit) *
    sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire);

  newCalculette.prime_MajorationHeuresChantierNuit_TotalCouts =
    sanitizeValue(
      newCalculette.prime_MajorationHeuresChantierNuit_CoutUnitaire
    ) * sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit);

  // Prime_RecupNuit
  newCalculette.prime_RecupHeureDeNuit =
    sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier) *
    3 *
    sanitizeValue(
      newCalculette.general_NombreDeTechnicienSurChantier +
        newCalculette.general_NombreDeChargeAffaireSurChantier
    );

  newCalculette.prime_RecupHeureDeNuit_TotalCouts =
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit);

  // Prime_MajoHeuresSupp
  newCalculette.prime_MajorationHeuresSupp =
    sanitizeValue(newCalculette.personnel_HeuresSupp) *
    sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire);

  newCalculette.prime_MajorationHeuresSupp_TotalCouts =
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp);

  // Prime_MajoHeuresSupp
  newCalculette.prime_MajorationHeuresDimanche =
    sanitizeValue(newCalculette.personnel_HeuresDimanche) *
    sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire);

  newCalculette.prime_MajorationHeuresDimanche_TotalCouts =
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche);

  // Prime_MajoHeuresFeriee
  newCalculette.prime_MajorationHeuresFeriee =
    sanitizeValue(newCalculette.personnel_HeuresFeriees) *
    sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire);

  newCalculette.prime_MajorationHeuresFeriee_TotalCouts =
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_CoutUnitaire) *
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee);

  newCalculette.prime_TotalCouts =
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j_TotalCouts) +
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j_TotalCouts) +
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement_TotalCouts) +
    sanitizeValue(newCalculette.prime_PrimeDeNuit_TotalCouts) +
    sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit_TotalCouts) +
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit_TotalCouts) +
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp_TotalCouts) +
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_TotalCouts) +
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_TotalCouts) +
    sanitizeValue(newCalculette.prime_Weekend_TotalCouts);

  return newCalculette;
}

function computeDepla(newCalculette) {
  // ===== Deplacements =====
  // Deplacements_Essence
  newCalculette.deplacements_Essence_TotalCouts =
    sanitizeValue(newCalculette.deplacements_Essence_CoutUnitaire) *
    sanitizeValue(newCalculette.general_NombreDeKmARParcourus);

  // Deplacements_ForfaitNuit

  newCalculette.deplacements_ForfaitNuit_TotalCouts =
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_CoutUnitaire) *
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_AmeneRepli);

  // Deplacements_ForfaitRestaurant

  newCalculette.deplacements_ForfaitRestaurant_TotalCouts =
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_CoutUnitaire) *
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_AmeneRepli);

  // Deplacements_Peages
  newCalculette.deplacements_Peages_TotalCouts =
    sanitizeValue(newCalculette.deplacements_Peages_CoutUnitaire) *
    sanitizeValue(newCalculette.general_NombreDeKmARParcourus);

  // Deplacements_VehiculesFraisFixesParJour

  if (
    newCalculette.type == "PHY" ||
    newCalculette.type == "3C+PHY" ||
    newCalculette.type == "3CO"
  ) {
    newCalculette.deplacements_VehiculeFraisFixesParJour_AmeneRepli = 0;
    newCalculette.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation =
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) +
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);

    newCalculette.deplacements_VehiculeFraisFixesParJour_Total =
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) +
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);
  } else {
    newCalculette.deplacements_VehiculeFraisFixesParJour_AmeneRepli =
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_ResponsableService_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_Techniciens_AmeneRepli);

    newCalculette.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation =
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_MesuresOuFormation) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_MesuresOuFormation
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsableService_MesuresOuFormation
      ) +
      sanitizeValue(newCalculette.personnel_Techniciens_MesuresOuFormation);

    newCalculette.deplacements_VehiculeFraisFixesParJour_Total =
      newCalculette.deplacements_VehiculeFraisFixesParJour_AmeneRepli +
      newCalculette.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation;
  }

  newCalculette.deplacements_VehiculeFraisFixesParJour_TotalCouts =
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
    ) *
    sanitizeValue(newCalculette.deplacements_VehiculeFraisFixesParJour_Total);

  // Deplacements_TrainAvion
  newCalculette.deplacements_TrainAvion_TotalCouts = sanitizeValue(
    newCalculette.deplacements_TrainAvion_CoutUnitaire
  );

  // Deplacements_SousTotaux
  newCalculette.deplacements_TotalCouts =
    sanitizeValue(newCalculette.deplacements_Essence_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_Peages_TotalCouts) +
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_TotalCouts
    ) +
    sanitizeValue(newCalculette.deplacements_TrainAvion_TotalCouts);

  return newCalculette;
}

function computeMat(newCalculette) {
  // ===== Materiels =====
  // Materiels_SystemeDInformations

  if (
    newCalculette.type == "ENDO" ||
    newCalculette.type == "R&D" ||
    newCalculette.type == "DO" ||
    newCalculette.type == "ETU+ENDO"
  ) {
    newCalculette.materiels_SystemeDInformations_Devis =
      sanitizeValue(newCalculette.personnel_ResponsableService_Devis) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_Devis) +
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_Devis) +
      sanitizeValue(newCalculette.personnel_Techniciens_Devis) +
      sanitizeValue(newCalculette.personnel_Assistante_Devis);

    newCalculette.materiels_SystemeDInformations_Rapport =
      sanitizeValue(
        newCalculette.personnel_ResponsableService_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_Techniciens_RapportOuAdministratif
      ) +
      sanitizeValue(newCalculette.personnel_Assistante_RapportOuAdministratif);

    newCalculette.materiels_SystemeDInformations_Total =
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Rapport) +
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Devis);

    newCalculette.materiels_SystemeDInformations_TotalCouts =
      sanitizeValue(newCalculette.materiels_SystemeDInformations_CoutUnitaire) *
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Total);
  } else if (newCalculette.type == "PHY" || newCalculette.type == "3C+PHY") {
    newCalculette.materiels_SystemeDInformations_Labo =
      sanitizeValue(newCalculette.personnel_ResponsableService_Labo) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_Labo) +
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_Labo) +
      sanitizeValue(newCalculette.personnel_Techniciens_Labo) +
      sanitizeValue(newCalculette.personnel_Assistante_Labo);

    newCalculette.materiels_SystemeDInformations_Rapport =
      sanitizeValue(
        newCalculette.personnel_ResponsableService_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_Techniciens_RapportOuAdministratif
      ) +
      sanitizeValue(newCalculette.personnel_Assistante_RapportOuAdministratif);

    newCalculette.materiels_SystemeDInformations_Total =
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Rapport) +
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Labo);

    newCalculette.materiels_SystemeDInformations_TotalCouts =
      sanitizeValue(newCalculette.materiels_SystemeDInformations_CoutUnitaire) *
      sanitizeValue(newCalculette.materiels_SystemeDInformations_Total);
  } else {
    newCalculette.materiels_SystemeDInformations_Devis =
      sanitizeValue(newCalculette.personnel_ResponsableService_Devis) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_Devis) +
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_Devis) +
      sanitizeValue(newCalculette.personnel_Techniciens_Devis) +
      sanitizeValue(newCalculette.personnel_Assistante_Devis);

    newCalculette.materiels_SystemeDInformations_Labo =
      sanitizeValue(newCalculette.personnel_ResponsableService_Labo) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_Labo) +
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_Labo) +
      sanitizeValue(newCalculette.personnel_Techniciens_Labo) +
      sanitizeValue(newCalculette.personnel_Assistante_Labo);

    newCalculette.materiels_SystemeDInformations_Rapport =
      sanitizeValue(
        newCalculette.personnel_ResponsableService_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif
      ) +
      sanitizeValue(
        newCalculette.personnel_Techniciens_RapportOuAdministratif
      ) +
      sanitizeValue(newCalculette.personnel_Assistante_RapportOuAdministratif);

    newCalculette.materiels_SystemeDInformations_TotalCouts =
      sanitizeValue(newCalculette.materiels_SystemeDInformations_CoutUnitaire) *
      sanitizeValue(
        sanitizeValue(newCalculette.materiels_SystemeDInformations_Devis) +
          sanitizeValue(newCalculette.materiels_SystemeDInformations_Labo) +
          sanitizeValue(newCalculette.materiels_SystemeDInformations_Rapport)
      );
  }
  // Materiels_A
  if (
    newCalculette.type == "ENDO" ||
    newCalculette.type == "R&D" ||
    newCalculette.type == "DO" ||
    newCalculette.type == "ETU+ENDO"
  ) {
    newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation =
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_MesuresOuFormation) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_MesuresOuFormation
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsableService_MesuresOuFormation
      ) +
      sanitizeValue(newCalculette.personnel_Techniciens_MesuresOuFormation);

    newCalculette.materiels_MaterielIndustriel_A_Total = sanitizeValue(
      newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation
    );

    newCalculette.materiels_MaterielIndustriel_A_TotalCouts =
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_Total);
  } else if (
    newCalculette.type == "PHY" ||
    newCalculette.type == "3C+PHY" ||
    newCalculette.type == "3CO"
  ) {
    newCalculette.materiels_MaterielIndustriel_A_AmeneRepli = 0;
    newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation =
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) +
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);

    newCalculette.materiels_MaterielIndustriel_A_Total =
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) +
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);

    newCalculette.materiels_MaterielIndustriel_A_TotalCouts =
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_Total);
  } else {
    newCalculette.materiels_MaterielIndustriel_A_AmeneRepli =
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_ResponsablePole_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_ResponsableService_AmeneRepli) +
      sanitizeValue(newCalculette.personnel_Techniciens_AmeneRepli);

    newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation =
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_MesuresOuFormation) +
      sanitizeValue(
        newCalculette.personnel_ResponsablePole_MesuresOuFormation
      ) +
      sanitizeValue(
        newCalculette.personnel_ResponsableService_MesuresOuFormation
      ) +
      sanitizeValue(newCalculette.personnel_Techniciens_MesuresOuFormation);

    newCalculette.materiels_MaterielIndustriel_A_Total =
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_AmeneRepli) +
      sanitizeValue(
        newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation
      );
    newCalculette.materiels_MaterielIndustriel_A_TotalCouts =
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_Total);
  }

  // Materiels_SousTraitanceOuLocation
  newCalculette.materiels_SousTraitanceOuLocation_Total =
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Devis) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Preparation) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_AmeneRepli) +
    sanitizeValue(
      newCalculette.materiels_SousTraitanceOuLocation_MesuresOuFormation
    ) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Labo) +
    sanitizeValue(
      newCalculette.materiels_SousTraitanceOuLocation_RapportOuAdministratif
    );
  newCalculette.materiels_SousTraitanceOuLocation_TotalCouts =
    sanitizeValue(
      newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
    ) * sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Total);

  // Materiels_Consommables
  newCalculette.materiels_Consommables_MesuresOuFormation = 1;
  newCalculette.materiels_Consommables_Total = 1;
  newCalculette.materiels_Consommables_TotalCouts =
    sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) *
    sanitizeValue(newCalculette.materiels_Consommables_Total);

  // Materiels_SousTotaux
  newCalculette.materiels_TotalCouts =
    sanitizeValue(newCalculette.materiels_SystemeDInformations_TotalCouts) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_TotalCouts) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_TotalCouts) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_TotalCouts) +
    sanitizeValue(newCalculette.materiels_Consommables_TotalCouts);

  return newCalculette;
}

function computeAutre(newCalculette) {
  // ===== Autres ======
  // Autres_A
  newCalculette.autres_A_TotalCouts =
    (sanitizeValue(newCalculette.autres_A_Devis) +
      sanitizeValue(newCalculette.autres_A_Preparation) +
      sanitizeValue(newCalculette.autres_A_AmeneRepli) +
      sanitizeValue(newCalculette.autres_A_MesuresOuFormation) +
      sanitizeValue(newCalculette.autres_A_Labo) +
      sanitizeValue(newCalculette.autres_A_RapportOuAdministratif)) *
    sanitizeValue(newCalculette.autres_A_CoutUnitaire);

  // Autres_B
  newCalculette.autres_B_TotalCouts =
    (sanitizeValue(newCalculette.autres_B_Devis) +
      sanitizeValue(newCalculette.autres_B_Preparation) +
      sanitizeValue(newCalculette.autres_B_AmeneRepli) +
      sanitizeValue(newCalculette.autres_B_MesuresOuFormation) +
      sanitizeValue(newCalculette.autres_B_Labo) +
      sanitizeValue(newCalculette.autres_B_RapportOuAdministratif)) *
    sanitizeValue(newCalculette.autres_B_CoutUnitaire);

  // Autres_C
  newCalculette.autres_C_TotalCouts =
    (sanitizeValue(newCalculette.autres_C_Devis) +
      sanitizeValue(newCalculette.autres_C_Preparation) +
      sanitizeValue(newCalculette.autres_C_AmeneRepli) +
      sanitizeValue(newCalculette.autres_C_MesuresOuFormation) +
      sanitizeValue(newCalculette.autres_C_Labo) +
      sanitizeValue(newCalculette.autres_C_RapportOuAdministratif)) *
    sanitizeValue(newCalculette.autres_C_CoutUnitaire);

  // Autres_D
  newCalculette.autres_D_TotalCouts =
    (sanitizeValue(newCalculette.autres_D_Devis) +
      sanitizeValue(newCalculette.autres_D_Preparation) +
      sanitizeValue(newCalculette.autres_D_AmeneRepli) +
      sanitizeValue(newCalculette.autres_D_MesuresOuFormation) +
      sanitizeValue(newCalculette.autres_D_Labo) +
      sanitizeValue(newCalculette.autres_D_RapportOuAdministratif)) *
    sanitizeValue(newCalculette.autres_D_CoutUnitaire);

  // Autres_E
  newCalculette.autres_E_TotalCouts =
    (sanitizeValue(newCalculette.autres_E_Devis) +
      sanitizeValue(newCalculette.autres_E_Preparation) +
      sanitizeValue(newCalculette.autres_E_AmeneRepli) +
      sanitizeValue(newCalculette.autres_E_MesuresOuFormation) +
      sanitizeValue(newCalculette.autres_E_Labo) +
      sanitizeValue(newCalculette.autres_E_RapportOuAdministratif)) *
    sanitizeValue(newCalculette.autres_E_CoutUnitaire);

  // Autres_SousTotaux
  newCalculette.autres_TotalCouts =
    sanitizeValue(newCalculette.autres_A_TotalCouts) +
    sanitizeValue(newCalculette.autres_B_TotalCouts) +
    sanitizeValue(newCalculette.autres_C_TotalCouts) +
    sanitizeValue(newCalculette.autres_D_TotalCouts) +
    sanitizeValue(newCalculette.autres_E_TotalCouts);

  return newCalculette;
}

function computeTotal(newCalculette) {
  newCalculette.total_Devis =
    sanitizeValue(
      sanitizeValue(newCalculette.personnel_ResponsableService_Devis) *
        sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire)
    ) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_Devis) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_Devis) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_Devis) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_Devis) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Devis) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_Devis) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_Devis) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_Devis) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_Devis) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_Devis) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_Devis) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(newCalculette.deplacements_VehiculeFraisFixesParJour_Devis) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_SystemeDInformations_Devis) *
      sanitizeValue(newCalculette.materiels_SystemeDInformations_CoutUnitaire);

  newCalculette.total_Preparation =
    sanitizeValue(newCalculette.personnel_ResponsableService_Preparation) *
      sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_Preparation) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_Preparation) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_Preparation) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_Preparation) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Preparation) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_Preparation) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_Preparation) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_Preparation) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_Preparation) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_Preparation) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_Preparation) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_Preparation
    ) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      );

  newCalculette.total_AmeneRepli =
    sanitizeValue(newCalculette.personnel_ResponsableService_AmeneRepli) *
      sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_AmeneRepli) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_AmeneRepli) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_AmeneRepli) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_AmeneRepli) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_AmeneRepli) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_AmeneRepli) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_AmeneRepli) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_AmeneRepli) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_AmeneRepli) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_AmeneRepli) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_AmeneRepli) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_AmeneRepli
    ) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_AmeneRepli) *
      sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_CoutUnitaire) +
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_AmeneRepli) *
      sanitizeValue(newCalculette.deplacements_ForfaitNuit_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_AmeneRepli) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_AmeneRepli) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_C_AmeneRepli) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_D_AmeneRepli) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire);

  newCalculette.total_Mesures =
    sanitizeValue(
      newCalculette.personnel_ResponsableService_MesuresOuFormation
    ) *
      sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_MesuresOuFormation) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_MesuresOuFormation) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_MesuresOuFormation) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_MesuresOuFormation) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_HeuresFeriees) *
      sanitizeValue(newCalculette.personnel_HeuresFeriees_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit) *
      sanitizeValue(
        newCalculette.personnel_HeuresDeChantierDeNuit_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.personnel_HeuresDimanche) *
      sanitizeValue(newCalculette.personnel_HeuresDimanche_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_HeuresSupp) *
      sanitizeValue(newCalculette.personnel_HeuresSupp_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_SousTraitanceOuLocation_MesuresOuFormation
    ) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_MesuresOuFormation) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_MesuresOuFormation) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_MesuresOuFormation) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_MesuresOuFormation) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_MesuresOuFormation) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_MesuresOuFormation) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation
    ) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.deplacements_TrainAvion_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_Essence_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_Peages_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_Mesures) *
      sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation
    ) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_MaterielIndustriel_B_MesuresOuFormation
    ) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_MaterielIndustriel_C_MesuresOuFormation
    ) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_C_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_MaterielIndustriel_D_MesuresOuFormation
    ) *
      sanitizeValue(newCalculette.materiels_MaterielIndustriel_D_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit) *
      sanitizeValue(
        newCalculette.prime_MajorationHeuresChantierNuit_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche) *
      sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee) *
      sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp) *
      sanitizeValue(newCalculette.prime_MajorationHeuresSupp_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j) *
      sanitizeValue(newCalculette.prime_PrimeDeDepla4j_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j) *
      sanitizeValue(newCalculette.prime_PrimeDeDepla5j_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement) *
      sanitizeValue(newCalculette.prime_PrimeDeDeplacement_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_PrimeDeNuit) *
      sanitizeValue(newCalculette.prime_PrimeDeNuit_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit) *
      sanitizeValue(newCalculette.prime_RecupHeureDeNuit_CoutUnitaire) +
    sanitizeValue(newCalculette.prime_Weekend) *
      sanitizeValue(newCalculette.prime_Weekend_CoutUnitaire);

  newCalculette.total_Labo =
    sanitizeValue(newCalculette.personnel_ResponsableService_Labo) *
      sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ResponsablePole_Labo) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_Labo) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_Labo) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_Labo) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Labo) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_Labo) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_Labo) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_Labo) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_Labo) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_Labo) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_Labo) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(newCalculette.deplacements_VehiculeFraisFixesParJour_Labo) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      );

  newCalculette.total_Rapport =
    sanitizeValue(
      newCalculette.personnel_ResponsableService_RapportOuAdministratif
    ) *
      sanitizeValue(newCalculette.personnel_ResponsableService_CoutUnitaire) +
    sanitizeValue(
      newCalculette.personnel_ResponsablePole_RapportOuAdministratif
    ) *
      sanitizeValue(newCalculette.personnel_ResponsablePole_CoutUnitaire) +
    sanitizeValue(
      newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif
    ) *
      sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Techniciens_RapportOuAdministratif) *
      sanitizeValue(newCalculette.personnel_Techniciens_CoutUnitaire) +
    sanitizeValue(newCalculette.personnel_Assistante_RapportOuAdministratif) *
      sanitizeValue(newCalculette.personnel_Assistante_CoutUnitaire) +
    sanitizeValue(
      newCalculette.materiels_SousTraitanceOuLocation_RapportOuAdministratif
    ) *
      sanitizeValue(
        newCalculette.materiels_SousTraitanceOuLocation_CoutUnitaire
      ) +
    sanitizeValue(newCalculette.materiels_Consommables_RapportOuAdministratif) *
      sanitizeValue(newCalculette.materiels_Consommables_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_A_RapportOuAdministratif) *
      sanitizeValue(newCalculette.autres_A_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_B_RapportOuAdministratif) *
      sanitizeValue(newCalculette.autres_B_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_C_RapportOuAdministratif) *
      sanitizeValue(newCalculette.autres_C_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_D_RapportOuAdministratif) *
      sanitizeValue(newCalculette.autres_D_CoutUnitaire) +
    sanitizeValue(newCalculette.autres_E_RapportOuAdministratif) *
      sanitizeValue(newCalculette.autres_E_CoutUnitaire) +
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_RapportOuAdministratif
    ) *
      sanitizeValue(
        newCalculette.deplacements_VehiculeFraisFixesParJour_CoutUnitaire
      );

  newCalculette.total_Total =
    sanitizeValue(newCalculette.total_Devis) +
    sanitizeValue(newCalculette.total_Preparation) +
    sanitizeValue(newCalculette.total_AmeneRepli) +
    sanitizeValue(newCalculette.total_Mesures) +
    sanitizeValue(newCalculette.total_Labo) +
    sanitizeValue(newCalculette.total_Rapport);

  return newCalculette;
}

function computeMarge(newCalculette) {
  newCalculette.personnel_ResponsableService_Marge =
    sanitizeValue(newCalculette.personnel_ResponsableService_TotalCouts) *
    sanitizeValue(newCalculette.personnel_ResponsableService_CoefMulti);
  newCalculette.personnel_ResponsablePole_Marge =
    sanitizeValue(newCalculette.personnel_ResponsablePole_TotalCouts) *
    sanitizeValue(newCalculette.personnel_ResponsablePole_CoefMulti);
  newCalculette.personnel_ChargeDAffaire_Marge =
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_TotalCouts) *
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_CoefMulti);
  newCalculette.personnel_Techniciens_Marge =
    sanitizeValue(newCalculette.personnel_Techniciens_TotalCouts) *
    sanitizeValue(newCalculette.personnel_Techniciens_CoefMulti);
  newCalculette.personnel_Assistante_Marge =
    sanitizeValue(newCalculette.personnel_Assistante_TotalCouts) *
    sanitizeValue(newCalculette.personnel_Assistante_CoefMulti);
  newCalculette.personnel_HeuresDeChantierDeNuit_Marge =
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit_TotalCouts) *
    sanitizeValue(newCalculette.personnel_HeuresDeChantierDeNuit_CoefMulti);
  newCalculette.personnel_HeuresDimanche_Marge =
    sanitizeValue(newCalculette.personnel_HeuresDimanche_TotalCouts) *
    sanitizeValue(newCalculette.personnel_HeuresDimanche_CoefMulti);
  newCalculette.personnel_HeuresSupp_Marge =
    sanitizeValue(newCalculette.personnel_HeuresSupp_TotalCouts) *
    sanitizeValue(newCalculette.personnel_HeuresSupp_CoefMulti);
  newCalculette.personnel_HeuresFeriees_Marge =
    sanitizeValue(newCalculette.personnel_HeuresFeriees_TotalCouts) *
    sanitizeValue(newCalculette.personnel_HeuresFeriees_CoefMulti);

  newCalculette.prime_MajorationHeuresChantierNuit_Marge =
    sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit_TotalCouts) *
    sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit_CoefMulti);
  newCalculette.prime_PrimeDeNuit_Marge =
    sanitizeValue(newCalculette.prime_PrimeDeNuit_TotalCouts) *
    sanitizeValue(newCalculette.prime_PrimeDeNuit_CoefMulti);
  newCalculette.prime_PrimeDeDeplacement_Marge =
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement_TotalCouts) *
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement_CoefMulti);
  newCalculette.prime_PrimeDeDepla4j_Marge =
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j_TotalCouts) *
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j_CoefMulti);
  newCalculette.prime_PrimeDeDepla5j_Marge =
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j_TotalCouts) *
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j_CoefMulti);
  newCalculette.prime_RecupHeureDeNuit_Marge =
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit_TotalCouts) *
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit_CoefMulti);
  newCalculette.prime_MajorationHeuresSupp_Marge =
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp_TotalCouts) *
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp_CoefMulti);
  newCalculette.prime_MajorationHeuresDimanche_Marge =
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_TotalCouts) *
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_CoefMulti);
  newCalculette.prime_Weekend_Marge =
    sanitizeValue(newCalculette.prime_Weekend_TotalCouts) *
    sanitizeValue(newCalculette.prime_Weekend_CoefMulti);
  newCalculette.prime_MajorationHeuresFeriee_Marge =
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_TotalCouts) *
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_CoefMulti);

  newCalculette.deplacements_Essence_Marge =
    sanitizeValue(newCalculette.deplacements_Essence_TotalCouts) *
    sanitizeValue(newCalculette.deplacements_Essence_CoefMulti);
  newCalculette.deplacements_ForfaitNuit_Marge =
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_TotalCouts) *
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_CoefMulti);
  newCalculette.deplacements_ForfaitRestaurant_Marge =
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_TotalCouts) *
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_CoefMulti);
  newCalculette.deplacements_VehiculeFraisFixesParJour_Marge =
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_TotalCouts
    ) *
    sanitizeValue(
      newCalculette.deplacements_VehiculeFraisFixesParJour_CoefMulti
    );
  newCalculette.deplacements_Peages_Marge =
    sanitizeValue(newCalculette.deplacements_Peages_TotalCouts) *
    sanitizeValue(newCalculette.deplacements_Peages_CoefMulti);
  newCalculette.deplacements_TrainAvion_Marge =
    sanitizeValue(newCalculette.deplacements_TrainAvion_TotalCouts) *
    sanitizeValue(newCalculette.deplacements_TrainAvion_CoefMulti);

  newCalculette.materiels_Consommables_Marge =
    sanitizeValue(newCalculette.materiels_Consommables_TotalCouts) *
    sanitizeValue(newCalculette.materiels_Consommables_CoefMulti);
  newCalculette.materiels_MaterielIndustriel_A_Marge =
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_TotalCouts) *
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_CoefMulti);
  newCalculette.materiels_MaterielIndustriel_B_Marge =
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_TotalCouts) *
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_CoefMulti);
  newCalculette.materiels_MaterielIndustriel_C_Marge =
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_C_TotalCouts) *
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_C_CoefMulti);
  newCalculette.materiels_MaterielIndustriel_D_Marge =
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_D_TotalCouts) *
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_D_CoefMulti);
  newCalculette.materiels_SousTraitanceOuLocation_Marge =
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_TotalCouts) *
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_CoefMulti);
  newCalculette.materiels_SystemeDInformations_Marge =
    sanitizeValue(newCalculette.materiels_SystemeDInformations_TotalCouts) *
    sanitizeValue(newCalculette.materiels_SystemeDInformations_CoefMulti);

  newCalculette.autres_A_Marge =
    sanitizeValue(newCalculette.autres_A_TotalCouts) *
    sanitizeValue(newCalculette.autres_A_CoefMulti);
  newCalculette.autres_B_Marge =
    sanitizeValue(newCalculette.autres_B_TotalCouts) *
    sanitizeValue(newCalculette.autres_B_CoefMulti);
  newCalculette.autres_C_Marge =
    sanitizeValue(newCalculette.autres_C_TotalCouts) *
    sanitizeValue(newCalculette.autres_C_CoefMulti);
  newCalculette.autres_D_Marge =
    sanitizeValue(newCalculette.autres_D_TotalCouts) *
    sanitizeValue(newCalculette.autres_D_CoefMulti);
  newCalculette.autres_E_Marge =
    sanitizeValue(newCalculette.autres_E_TotalCouts) *
    sanitizeValue(newCalculette.autres_E_CoefMulti);

  newCalculette.total_Marge =
    sanitizeValue(newCalculette.personnel_ResponsablePole_Marge) +
    sanitizeValue(newCalculette.personnel_ResponsableService_Marge) +
    sanitizeValue(newCalculette.personnel_ChargeDAffaire_Marge) +
    sanitizeValue(newCalculette.personnel_Techniciens_Marge) +
    sanitizeValue(newCalculette.personnel_Assistante_Marge) +
    sanitizeValue(newCalculette.prime_PrimeDeNuit_Marge) +
    sanitizeValue(newCalculette.prime_PrimeDeDeplacement_Marge) +
    sanitizeValue(newCalculette.prime_PrimeDeDepla4j_Marge) +
    sanitizeValue(newCalculette.prime_PrimeDeDepla5j_Marge) +
    sanitizeValue(newCalculette.prime_RecupHeureDeNuit_Marge) +
    sanitizeValue(newCalculette.prime_MajorationHeuresChantierNuit_Marge) +
    sanitizeValue(newCalculette.prime_MajorationHeuresSupp_Marge) +
    sanitizeValue(newCalculette.prime_MajorationHeuresDimanche_Marge) +
    sanitizeValue(newCalculette.prime_Weekend_Marge) +
    sanitizeValue(newCalculette.prime_MajorationHeuresFeriee_Marge) +
    sanitizeValue(newCalculette.deplacements_Essence_Marge) +
    sanitizeValue(newCalculette.deplacements_ForfaitNuit_Marge) +
    sanitizeValue(newCalculette.deplacements_ForfaitRestaurant_Marge) +
    sanitizeValue(newCalculette.deplacements_VehiculeFraisFixesParJour_Marge) +
    sanitizeValue(newCalculette.deplacements_Peages_Marge) +
    sanitizeValue(newCalculette.deplacements_TrainAvion_Marge) +
    sanitizeValue(newCalculette.materiels_Consommables_Marge) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_A_Marge) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_B_Marge) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_C_Marge) +
    sanitizeValue(newCalculette.materiels_MaterielIndustriel_D_Marge) +
    sanitizeValue(newCalculette.materiels_SousTraitanceOuLocation_Marge) +
    sanitizeValue(newCalculette.materiels_SystemeDInformations_Marge) +
    sanitizeValue(newCalculette.autres_A_Marge) +
    sanitizeValue(newCalculette.autres_B_Marge) +
    sanitizeValue(newCalculette.autres_C_Marge) +
    sanitizeValue(newCalculette.autres_D_Marge) +
    sanitizeValue(newCalculette.autres_E_Marge);

  newCalculette.total_Devis_Marge =
    sanitizeValue(newCalculette.total_Devis) *
    sanitizeValue(newCalculette.total_Devis_CoefMulti);
  newCalculette.total_Preparation_Marge =
    sanitizeValue(newCalculette.total_Preparation) *
    sanitizeValue(newCalculette.total_Preparation_CoefMulti);
  newCalculette.total_AmeneRepli_Marge =
    sanitizeValue(newCalculette.total_AmeneRepli) *
    sanitizeValue(newCalculette.total_AmeneRepli_CoefMulti);
  newCalculette.total_Mesures_Marge =
    sanitizeValue(newCalculette.total_Mesures) *
    sanitizeValue(newCalculette.total_Mesures_CoefMulti);
  newCalculette.total_Labo_Marge =
    sanitizeValue(newCalculette.total_Labo) *
    sanitizeValue(newCalculette.total_Labo_CoefMulti);
  newCalculette.total_Rapport_Marge =
    sanitizeValue(newCalculette.total_Rapport) *
    sanitizeValue(newCalculette.total_Rapport_CoefMulti);

  newCalculette.total_Total_Marge =
    sanitizeValue(newCalculette.total_Devis_Marge) +
    sanitizeValue(newCalculette.total_Preparation_Marge) +
    sanitizeValue(newCalculette.total_AmeneRepli_Marge) +
    sanitizeValue(newCalculette.total_Mesures_Marge) +
    sanitizeValue(newCalculette.total_Labo_Marge) +
    sanitizeValue(newCalculette.total_Rapport_Marge);

  return newCalculette;
}

function computeLogique1(newCalculette) {
  if (newCalculette.financier_ModeCalcul == "Jour / Nuit") {
    newCalculette.pV_HT =
      sanitizeValue(newCalculette.financier_PrixJour) *
        sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) +
      sanitizeValue(newCalculette.financier_PrixNuit) *
        sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier);
  } else if (newCalculette.financier_ModeCalcul == "Km") {
    newCalculette.pV_HT =
      sanitizeValue(newCalculette.financier_PrixMetre) *
      sanitizeValue(newCalculette.general_NombreDeKmAuscultesParcourus);
  }

  return newCalculette;
}

function computeLogique2(newCalculette) {
  newCalculette.pV_HT =
    (newCalculette.total_Marge != 0
      ? newCalculette.total_Marge
      : newCalculette.total_Total_Marge) +
    newCalculette.exploitation_Assurance_TotalCouts +
    newCalculette.exploitation_Alea_TotalCouts;

  return newCalculette;
}

function computeLogique3(newCalculette) {
  return newCalculette;
}

function computeHeureChantierPersonnel(newCalculette, accessor) {
  // Personnel_HeuresDeChantierDeNuit
  if (
    accessor == "general_NombreDeNuitsDeChantier" ||
    accessor == "general_NombreDeJoursDeChantier" ||
    accessor == "personnel_Techniciens_MesuresOuFormation" ||
    accessor == "personnel_Techniciens_AmeneRepli"
  ) {
    if (
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier) > 0 &&
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) == 0
    ) {
      newCalculette.personnel_HeuresDeChantierDeJour = 0;
      newCalculette.personnel_HeuresDeChantierDeNuit =
        newCalculette.personnel_Techniciens_MesuresOuFormation +
        newCalculette.personnel_Techniciens_AmeneRepli;
    } else if (
      sanitizeValue(newCalculette.general_NombreDeNuitsDeChantier) == 0 &&
      sanitizeValue(newCalculette.general_NombreDeJoursDeChantier) > 0
    ) {
      newCalculette.personnel_HeuresDeChantierDeNuit = 0;
      newCalculette.personnel_HeuresDeChantierDeJour = sanitizeValue(
        newCalculette.personnel_Techniciens_MesuresOuFormation +
          newCalculette.personnel_Techniciens_AmeneRepli
      );
    }
  }

  return newCalculette;
}

function computeCalculette(prevCalculette, accessor, value) {
  var newCalculette = {
    ...prevCalculette,
    [accessor]:
      accessor == "type" ||
      accessor == "modeCalcul" ||
      accessor == "financier_ModeCalcul"
        ? value
        : Number(parseFloat(value).toFixed(2)),
  };

  if (accessor == "personnel_CoefMulti") {
    newCalculette.personnel_ResponsableService_CoefMulti = value;
    newCalculette.personnel_ResponsablePole_CoefMulti = value;
    newCalculette.personnel_ChargeDAffaire_CoefMulti = value;
    newCalculette.personnel_Techniciens_CoefMulti = value;
    newCalculette.personnel_Assistante_CoefMulti = value;
    newCalculette.personnel_HeuresDeChantierDeNuit_CoefMulti = value;
    newCalculette.personnel_HeuresDeChantierDeJour_CoefMulti = value;
    newCalculette.personnel_HeuresDimanche_CoefMulti = value;
    newCalculette.personnel_HeuresSupp_CoefMulti = value;
    newCalculette.personnel_HeuresFeriees_CoefMulti = value;
  }
  if (accessor == "prime_CoefMulti") {
    newCalculette.prime_MajorationHeuresChantierNuit_CoefMulti = value;
    newCalculette.prime_PrimeDeNuit_CoefMulti = value;
    newCalculette.prime_PrimeDeDeplacement_CoefMulti = value;
    newCalculette.prime_PrimeDeDepla4j_CoefMulti = value;
    newCalculette.prime_PrimeDeDepla5j_CoefMulti = value;
    newCalculette.prime_RecupHeureDeNuit_CoefMulti = value;
    newCalculette.prime_MajorationHeuresSupp_CoefMulti = value;
    newCalculette.prime_MajorationHeuresDimanche_CoefMulti = value;
    newCalculette.prime_Weekend_CoefMulti = value;
    newCalculette.prime_MajorationHeuresFeriee_CoefMulti = value;
  }
  if (accessor == "deplacements_CoefMulti") {
    newCalculette.deplacements_Essence_CoefMulti = value;
    newCalculette.deplacements_ForfaitNuit_CoefMulti = value;
    newCalculette.deplacements_ForfaitRestaurant_CoefMulti = value;
    newCalculette.deplacements_VehiculeFraisFixesParJour_CoefMulti = value;
    newCalculette.deplacements_Peages_CoefMulti = value;
    newCalculette.deplacements_TrainAvion_CoefMulti = value;
  }
  if (accessor == "materiels_CoefMulti") {
    newCalculette.materiels_Consommables_CoefMulti = value;
    newCalculette.materiels_MaterielIndustriel_A_CoefMulti = value;
    newCalculette.materiels_MaterielIndustriel_B_CoefMulti = value;
    newCalculette.materiels_MaterielIndustriel_C_CoefMulti = value;
    newCalculette.materiels_MaterielIndustriel_D_CoefMulti = value;
    newCalculette.materiels_SousTraitanceOuLocation_CoefMulti = value;
    newCalculette.materiels_SystemeDInformations_CoefMulti = value;
  }
  if (accessor == "autres_CoefMulti") {
    newCalculette.autres_A_CoefMulti = value;
    newCalculette.autres_B_CoefMulti = value;
    newCalculette.autres_C_CoefMulti = value;
    newCalculette.autres_D_CoefMulti = value;
    newCalculette.autres_E_CoefMulti = value;
  }
  if (accessor == "total_CoefMultiCommun") {
    newCalculette.total_Devis_CoefMulti = value;
    newCalculette.total_Preparation_CoefMulti = value;
    newCalculette.total_AmeneRepli_CoefMulti = value;
    newCalculette.total_Mesures_CoefMulti = value;
    newCalculette.total_Labo_CoefMulti = value;
    newCalculette.total_Rapport_CoefMulti = value;
  }

  newCalculette.exploitation_Assurance_TotalCouts = 0;
  newCalculette.exploitation_Alea_TotalCouts = 0;
  newCalculette = computeHeureChantierPersonnel(newCalculette, accessor);
  newCalculette = computePersonnel(newCalculette);
  newCalculette = computePrime(newCalculette);
  newCalculette = computeDepla(newCalculette);
  newCalculette = computeMat(newCalculette);
  newCalculette = computeAutre(newCalculette);
  newCalculette = computeTotal(newCalculette);
  newCalculette = computeMarge(newCalculette);

  if (newCalculette.modeCalcul == "Logique 1 : Accord cadre")
    newCalculette = computeLogique1(newCalculette);
  else if (newCalculette.modeCalcul == "Logique 2 : Prix de revient")
    newCalculette = computeLogique2(newCalculette);
  else if (newCalculette.modeCalcul == "Logique 3 : Prix de vente")
    newCalculette = computeLogique3(newCalculette);

  // Exploitation

  newCalculette.totalCouts =
    sanitizeValue(newCalculette.personnel_TotalCouts) +
    sanitizeValue(newCalculette.prime_TotalCouts) +
    sanitizeValue(newCalculette.deplacements_TotalCouts) +
    sanitizeValue(newCalculette.materiels_TotalCouts) +
    sanitizeValue(newCalculette.autres_TotalCouts);

  if (newCalculette.type == "PHY" || newCalculette.type == "3C+PHY") {
    newCalculette.exploitation_Assurance_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Assurance_CoutUnitaire / 100) *
      sanitizeValue(newCalculette.totalCouts);

    // Exploitation_Alea
    newCalculette.exploitation_Alea_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Alea_CoutUnitaire / 100) *
      sanitizeValue(
        newCalculette.totalCouts +
          newCalculette.exploitation_Assurance_TotalCouts
      );
  } else if (
    newCalculette.type == "ENDO" ||
    newCalculette.type == "R&D" ||
    newCalculette.type == "DO" ||
    newCalculette.type == "ETU+ENDO"
  ) {
    newCalculette.exploitation_Assurance_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Assurance_CoutUnitaire / 100) *
      (sanitizeValue(newCalculette.pV_HT) * 0.8);

    // Exploitation_Alea
    newCalculette.exploitation_Alea_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Alea_CoutUnitaire / 100) *
      sanitizeValue(
        newCalculette.totalCouts +
          newCalculette.exploitation_Assurance_TotalCouts
      );
  } else {
    // Total

    newCalculette.exploitation_Assurance_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Assurance_CoutUnitaire / 100) *
      sanitizeValue(newCalculette.pV_HT);

    // Exploitation_Alea
    newCalculette.exploitation_Alea_TotalCouts =
      sanitizeValue(newCalculette.exploitation_Alea_CoutUnitaire / 100) *
      sanitizeValue(
        newCalculette.totalCouts +
          newCalculette.exploitation_Assurance_TotalCouts
      );
  }
  newCalculette.exploitation_TotalCouts =
    sanitizeValue(newCalculette.exploitation_Assurance_TotalCouts) +
    sanitizeValue(newCalculette.exploitation_Alea_TotalCouts);

  newCalculette.totalCouts =
    sanitizeValue(newCalculette.totalCouts) +
    sanitizeValue(newCalculette.exploitation_TotalCouts);

  if (newCalculette.modeCalcul == "Logique 2 : Prix de revient")
    newCalculette = computeLogique2(newCalculette);

  // Parts
  newCalculette.personnel_PartCouts =
    100 *
    (sanitizeValue(newCalculette.personnel_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  newCalculette.deplacements_PartCouts =
    100 *
    (sanitizeValue(newCalculette.deplacements_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  newCalculette.prime_PartCouts =
    100 *
    (sanitizeValue(newCalculette.prime_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  newCalculette.materiels_PartCouts =
    100 *
    (sanitizeValue(newCalculette.materiels_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  newCalculette.autres_PartCouts =
    100 *
    (sanitizeValue(newCalculette.autres_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  newCalculette.exploitation_PartCouts =
    100 *
    (sanitizeValue(newCalculette.exploitation_TotalCouts) /
      sanitizeValue(newCalculette.totalCouts));

  // Marge
  newCalculette.marge =
    sanitizeValue(newCalculette.pV_HT) -
    sanitizeValue(newCalculette.totalCouts);

  newCalculette.partMarge =
    100 *
    sanitizeValue(
      sanitizeValue(newCalculette.pV_HT - newCalculette.totalCouts) /
        newCalculette.pV_HT
    );

  return newCalculette;
}

function importModeleIntoCalculette(newCalculette, cal) {
  newCalculette.general_NombreDeTechnicienSurChantier =
    cal.general_NombreDeTechnicienSurChantier;
  newCalculette.general_NombreDeChargeAffaireSurChantier =
    cal.general_NombreDeChargeAffaireSurChantier;
  newCalculette.general_NombreDeJoursDeChantier =
    cal.general_NombreDeJoursDeChantier;
  newCalculette.general_NombreDeNuitsDeChantier =
    cal.general_NombreDeNuitsDeChantier;
  newCalculette.general_NombreDeJoursDeDeplacements_Tech =
    cal.general_NombreDeJoursDeDeplacements_Tech;
  newCalculette.general_NombreDeJoursDeDeplacements_ChargeAffaire =
    cal.general_NombreDeJoursDeDeplacements_ChargeAffaire;

  newCalculette.general_NombreDeKmARParcourus =
    cal.general_NombreDeKmARParcourus;
  newCalculette.general_NombreDeKmAuscultesParcourus =
    cal.general_NombreDeKmAuscultesParcourus;
  newCalculette.general_NombreSondagePandoscope =
    cal.general_NombreSondagePandoscope;
  newCalculette.general_NombreDeKmATraiterBureau =
    cal.general_NombreDeKmATraiterBureau;

  newCalculette.personnel_ResponsableService_Devis =
    cal.personnel_ResponsableService_Devis;
  newCalculette.personnel_ResponsableService_Preparation =
    cal.personnel_ResponsableService_Preparation;
  newCalculette.personnel_ResponsableService_AmeneRepli =
    cal.personnel_ResponsableService_AmeneRepli;
  newCalculette.personnel_ResponsableService_MesuresOuFormation =
    cal.personnel_ResponsableService_MesuresOuFormation;
  newCalculette.personnel_ResponsableService_Labo =
    cal.personnel_ResponsableService_Labo;
  newCalculette.personnel_ResponsableService_RapportOuAdministratif =
    cal.personnel_ResponsableService_RapportOuAdministratif;

  newCalculette.personnel_ResponsablePole_Devis =
    cal.personnel_ResponsablePole_Devis;
  newCalculette.personnel_ResponsablePole_Preparation =
    cal.personnel_ResponsablePole_Preparation;
  newCalculette.personnel_ResponsablePole_AmeneRepli =
    cal.personnel_ResponsablePole_AmeneRepli;
  newCalculette.personnel_ResponsablePole_MesuresOuFormation =
    cal.personnel_ResponsablePole_MesuresOuFormation;
  newCalculette.personnel_ResponsablePole_Labo =
    cal.personnel_ResponsablePole_Labo;
  newCalculette.personnel_ResponsablePole_RapportOuAdministratif =
    cal.personnel_ResponsablePole_RapportOuAdministratif;

  newCalculette.personnel_ChargeDAffaire_Devis =
    cal.personnel_ChargeDAffaire_Devis;
  newCalculette.personnel_ChargeDAffaire_Preparation =
    cal.personnel_ChargeDAffaire_Preparation;
  newCalculette.personnel_ChargeDAffaire_AmeneRepli =
    cal.personnel_ChargeDAffaire_AmeneRepli;
  newCalculette.personnel_ChargeDAffaire_MesuresOuFormation =
    cal.personnel_ChargeDAffaire_MesuresOuFormation;
  newCalculette.personnel_ChargeDAffaire_Labo =
    cal.personnel_ChargeDAffaire_Labo;
  newCalculette.personnel_ChargeDAffaire_RapportOuAdministratif =
    cal.personnel_ChargeDAffaire_RapportOuAdministratif;

  newCalculette.personnel_Techniciens_Devis = cal.personnel_Techniciens_Devis;
  newCalculette.personnel_Techniciens_Preparation =
    cal.personnel_Techniciens_Preparation;
  newCalculette.personnel_Techniciens_AmeneRepli =
    cal.personnel_Techniciens_AmeneRepli;
  newCalculette.personnel_Techniciens_MesuresOuFormation =
    cal.personnel_Techniciens_MesuresOuFormation;
  newCalculette.personnel_Techniciens_Labo = cal.personnel_Techniciens_Labo;
  newCalculette.personnel_Techniciens_RapportOuAdministratif =
    cal.personnel_Techniciens_RapportOuAdministratif;

  newCalculette.personnel_Assistante_Devis = cal.personnel_Assistante_Devis;
  newCalculette.personnel_Assistante_Preparation =
    cal.personnel_Assistante_Preparation;
  newCalculette.personnel_Assistante_AmeneRepli =
    cal.personnel_Assistante_AmeneRepli;
  newCalculette.personnel_Assistante_MesuresOuFormation =
    cal.personnel_Assistante_MesuresOuFormation;
  newCalculette.personnel_Assistante_Labo = cal.personnel_Assistante_Labo;
  newCalculette.personnel_Assistante_RapportOuAdministratif =
    cal.personnel_Assistante_RapportOuAdministratif;

  newCalculette.personnel_HeuresDimanche = cal.personnel_HeuresDimanche;
  newCalculette.personnel_HeuresFeriees = cal.personnel_HeuresFeriees;
  newCalculette.personnel_HeuresSupp = cal.personnel_HeuresSupp;

  newCalculette.prime_Weekend = cal.prime_Weekend;

  newCalculette.deplacements_ForfaitNuit_AmeneRepli =
    cal.deplacements_ForfaitNuit_AmeneRepli;

  newCalculette.deplacements_ForfaitRestaurant_AmeneRepli =
    cal.deplacements_ForfaitRestaurant_AmeneRepli;
  newCalculette.deplacements_ForfaitRestaurant_Mesures =
    cal.deplacements_ForfaitRestaurant_Mesures;

  newCalculette.deplacements_VehiculeFraisFixesParJour_Devis =
    cal.deplacements_VehiculeFraisFixesParJour_Devis;
  newCalculette.deplacements_VehiculeFraisFixesParJour_Preparation =
    cal.deplacements_VehiculeFraisFixesParJour_Preparation;
  newCalculette.deplacements_VehiculeFraisFixesParJour_AmeneRepli =
    cal.deplacements_VehiculeFraisFixesParJour_AmeneRepli;
  newCalculette.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation =
    cal.deplacements_VehiculeFraisFixesParJour_MesuresOuFormation;
  newCalculette.deplacements_VehiculeFraisFixesParJour_Labo =
    cal.deplacements_VehiculeFraisFixesParJour_Labo;
  newCalculette.deplacements_VehiculeFraisFixesParJour_RapportOuAdministratif =
    cal.deplacements_VehiculeFraisFixesParJour_RapportOuAdministratif;
  newCalculette.deplacements_TrainAvion_CoutUnitaire =
    cal.deplacements_TrainAvion_CoutUnitaire;

  newCalculette.materiels_SystemeDInformations_Devis =
    cal.materiels_SystemeDInformations_Devis;
  newCalculette.materiels_MaterielIndustriel_A_AmeneRepli =
    cal.materiels_MaterielIndustriel_A_AmeneRepli;
  newCalculette.materiels_MaterielIndustriel_A_MesuresOuFormation =
    cal.materiels_MaterielIndustriel_A_MesuresOuFormation;
  newCalculette.materiels_MaterielIndustriel_B_AmeneRepli =
    cal.materiels_MaterielIndustriel_B_AmeneRepli;
  newCalculette.materiels_MaterielIndustriel_B_MesuresOuFormation =
    cal.materiels_MaterielIndustriel_B_MesuresOuFormation;

  newCalculette.materiels_SousTraitanceOuLocation_Devis =
    cal.materiels_SousTraitanceOuLocation_Devis;
  newCalculette.materiels_SousTraitanceOuLocation_Preparation =
    cal.materiels_SousTraitanceOuLocation_Preparation;
  newCalculette.materiels_SousTraitanceOuLocation_AmeneRepli =
    cal.materiels_SousTraitanceOuLocation_AmeneRepli;
  newCalculette.materiels_SousTraitanceOuLocation_MesuresOuFormation =
    cal.materiels_SousTraitanceOuLocation_MesuresOuFormation;
  newCalculette.materiels_SousTraitanceOuLocation_Labo =
    cal.materiels_SousTraitanceOuLocation_Labo;
  newCalculette.materiels_SousTraitanceOuLocation_RapportOuAdministratif =
    cal.materiels_SousTraitanceOuLocation_RapportOuAdministratif;

  newCalculette.materiels_Consommables_Devis = cal.materiels_Consommables_Devis;
  newCalculette.materiels_Consommables_Preparation =
    cal.materiels_Consommables_Preparation;
  newCalculette.materiels_Consommables_AmeneRepli =
    cal.materiels_Consommables_AmeneRepli;
  newCalculette.materiels_Consommables_MesuresOuFormation =
    cal.materiels_Consommables_MesuresOuFormation;
  newCalculette.materiels_Consommables_Labo = cal.materiels_Consommables_Labo;
  newCalculette.materiels_Consommables_RapportOuAdministratif =
    cal.materiels_Consommables_RapportOuAdministratif;

  newCalculette.autres_A_Label = cal.autres_A_Label;
  newCalculette.autres_A_Devis = cal.autres_A_Devis;
  newCalculette.autres_A_Preparation = cal.autres_A_Preparation;
  newCalculette.autres_A_AmeneRepli = cal.autres_A_AmeneRepli;
  newCalculette.autres_A_MesuresOuFormation = cal.autres_A_MesuresOuFormation;
  newCalculette.autres_A_Labo = cal.autres_A_Labo;
  newCalculette.autres_A_RapportOuAdministratif =
    cal.autres_A_RapportOuAdministratif;

  newCalculette.autres_B_Label = cal.autres_B_Label;
  newCalculette.autres_B_Devis = cal.autres_B_Devis;
  newCalculette.autres_B_Preparation = cal.autres_B_Preparation;
  newCalculette.autres_B_AmeneRepli = cal.autres_B_AmeneRepli;
  newCalculette.autres_B_MesuresOuFormation = cal.autres_B_MesuresOuFormation;
  newCalculette.autres_B_Labo = cal.autres_B_Labo;
  newCalculette.autres_B_RapportOuAdministratif =
    cal.autres_B_RapportOuAdministratif;

  newCalculette.autres_C_Label = cal.autres_C_Label;
  newCalculette.autres_C_Devis = cal.autres_C_Devis;
  newCalculette.autres_C_Preparation = cal.autres_C_Preparation;
  newCalculette.autres_C_AmeneRepli = cal.autres_C_AmeneRepli;
  newCalculette.autres_C_MesuresOuFormation = cal.autres_C_MesuresOuFormation;
  newCalculette.autres_C_Labo = cal.autres_C_Labo;
  newCalculette.autres_C_RapportOuAdministratif =
    cal.autres_C_RapportOuAdministratif;

  newCalculette.autres_D_Label = cal.autres_D_Label;
  newCalculette.autres_D_Devis = cal.autres_D_Devis;
  newCalculette.autres_D_Preparation = cal.autres_D_Preparation;
  newCalculette.autres_D_AmeneRepli = cal.autres_D_AmeneRepli;
  newCalculette.autres_D_MesuresOuFormation = cal.autres_D_MesuresOuFormation;
  newCalculette.autres_D_Labo = cal.autres_D_Labo;
  newCalculette.autres_D_RapportOuAdministratif =
    cal.autres_D_RapportOuAdministratif;

  newCalculette.autres_E_Label = cal.autres_E_Label;
  newCalculette.autres_E_Devis = cal.autres_E_Devis;
  newCalculette.autres_E_Preparation = cal.autres_E_Preparation;
  newCalculette.autres_E_AmeneRepli = cal.autres_E_AmeneRepli;
  newCalculette.autres_E_MesuresOuFormation = cal.autres_E_MesuresOuFormation;
  newCalculette.autres_E_Labo = cal.autres_E_Labo;
  newCalculette.autres_E_RapportOuAdministratif =
    cal.autres_E_RapportOuAdministratif;

  return computeCalculette(newCalculette, "update", "update");
}

export { computeCalculette, importModeleIntoCalculette };
