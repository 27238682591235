import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCopy } from "@fortawesome/free-solid-svg-icons";
import "./FicheConsultation.css";

import { InformationsConsultation } from ".";
import { WarningBar, AlertDialog, DialogForm } from "_components";

import { ConsultationService, DevisService, ReussiteService } from "_services";
import {
  // faEnvelopeOpenText,
  faFileInvoiceDollar,
  faUserFriends,
  faPrint,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { Helmet } from "react-helmet";
import produce from "immer";
import * as FileSaver from "file-saver";
import { App } from "App";
import { Input } from "_components/Input";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ToLocaleDateString } from "_utils";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheConsultation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      tabs: ["Général"],
      active: "Général",
      editing: false,
      consultation: {},
      openedDialogDupplicationDevis: false,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.createNewContact = this.createNewContact.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.getChampsObligatoireVide = this.getChampsObligatoireVide.bind(this);
    this.setOpenDialogImpression = this.setOpenDialogImpression.bind(this);
    this.setOpenDialogReussite = this.setOpenDialogReussite.bind(this);
    this.duplicateConsultation = this.duplicateConsultation.bind(this);
  }

  setOpenDialogImpression(openDialogFunc) {
    this.setState({ openDialogImpressionFunc: openDialogFunc });
  }

  setOpenDialogReussite(openDialogFunc) {
    this.setState({ openDialogReussiteFunc: openDialogFunc });
  }

  duplicateConsultation(idconsultation) {
    return ConsultationService.duplicate(idconsultation).then((res) => {
      this.props.history.push({
        pathname: "/consultations/" + res.data.id,
      });
      this.props.history.go();
    });
  }

  componentDidMount() {
    if (sessionStorage.getItem("ConsultationOnglet") == null) {
      sessionStorage.setItem("ConsultationOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ConsultationOnglet") });
    }

    let { id } = this.props.match.params;
    ConsultationService.getById(id).then((res) => {
      this.setState({ consultation: res.data, loading: false });
    });
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callbackFunction) {
    if (accessor === "consultation") {
      //Undo
      this.setState({ consultation: value }, callbackFunction);
    } else {
      //Update
      if (accessor.includes(".")) {
        var accessorSplit = accessor.split(".");

        // Nested property (separated by a '.')
        this.setState(
          produce((prevState) => {
            prevState.consultation[accessorSplit[0]][accessorSplit[1]] = value;
          }),
          callbackFunction
        );
      } else {
        if (accessor == "statut") {
          if (value.designation == "Clôturé") {
            this.setState(
              produce((prevState) => {
                prevState.consultation["actif"] = false;
              })
            );
          } else {
            this.setState(
              produce((prevState) => {
                prevState.consultation["actif"] = true;
              })
            );
          }
        }
        // Shallow property
        this.setState(
          produce((prevState) => {
            prevState.consultation[accessor] = value;
          }),
          callbackFunction
        );
      }
    }
  }

  handleDelete() {
    return ConsultationService.delete(this.state.consultation).then(() => {
      this.props.history.push({ pathname: "/consultations" });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  createNewContact() {
    this.props.history.push({
      pathname: "/contacts/add",
      state: { client: this.state.consultation.client },
    });
  }

  /**
   * Permet de renvoyer un tableau contenant le nom des champs vides obligatoires
   */
  getChampsObligatoireVide() {
    let tab = [];
    if (!this.state.consultation?.client) {
      tab.push("Client");
    }
    // if (!this.state.consultation?.contact && this.state.consultation.) { ? si particulier
    //   tab.push("Contact");
    // }
    if (!this.state.consultation?.nom_Consultation) {
      tab.push("Nom Consultation");
    }
    if (!this.state.consultation?.source) {
      tab.push("Source");
    }
    if (!this.state.consultation?.produit_Interne) {
      tab.push("Produit Interne");
    }
    if (
      !this.state.consultation?.famille_Affaire &&
      this.state.consultation?.produit_Interne?.isPresta
    ) {
      tab.push("Famille d'affaires");
    }
    if (!this.state.consultation?.commercial) {
      tab.push("Commercial");
    }

    return tab;
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ConsultationOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div className="ms-auto align-self-center">
          <div className="text-end d-flex">
            <div>
              <DialogForm
                tooltip="Imprimer"
                classNameButton="btn btn-success"
                dialogTitle="Impression"
                labelValidateButton="Valider"
                setOpenDialog={this.setOpenDialogImpression}
                validation={() => {
                  if (this.state.impression != null) return true;
                  else {
                    App.Toaster.current?.createToast({
                      body: "Vous devez sélectionner un modèle",
                      header: "Echec",
                      type: "failure",
                    });

                    return false;
                  }
                }}
                onValidate={() => {
                  ConsultationService.print(
                    this.state.impression.designation,
                    this.state.consultation.id
                  ).then((res) => {
                    var blob = new Blob([res?.data], {
                      type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    FileSaver.saveAs(
                      blob,
                      this.state.consultation.nom_Consultation + ".docx"
                    );
                  });
                }}
                onClose={() => {}}
                body={
                  <div id="PopupCommande">
                    <div>
                      Veuillez choisir un modèle d'impression <br />
                    </div>

                    <Input
                      label="Modèle"
                      accessor="impression"
                      type="selectSearch"
                      value={this.state.impression}
                      valueFieldToDisplay="designation"
                      optionFieldToDisplay="designation"
                      functionAppliedToValue={(value) => {
                        if (value) return value.designation;
                        return null;
                      }}
                      handleChange={(acc, value) =>
                        this.setState({ impression: value })
                      }
                      options={[
                        {
                          id: 0,
                          designation: "Pochette PHY",
                        },
                        {
                          id: 1,
                          designation: "Pochette ETU",
                        },
                        {
                          id: 2,
                          designation: "Pochette ENDO",
                        },
                      ]}
                      required={true}
                    />
                  </div>
                }
              />
            </div>
            <div>
              <DialogForm
                classNameButton="btn btn-success"
                dialogTitle="Réussite"
                labelValidateButton="Valider"
                setOpenDialog={this.setOpenDialogReussite}
                validation={() => {
                  if (this.state.reussite != null) return true;
                  else {
                    App.Toaster.current?.createToast({
                      body: "Vous devez sélectionner un taux de réussite ET une date",
                      header: "Echec",
                      type: "failure",
                    });

                    return false;
                  }
                }}
                onValidate={() => {
                  DevisService.postFromConsult(
                    this.state.consultation.id,
                    this.state.reussite?.id,
                    this.state.date
                  ).then((retour) =>
                    this.props.history.push("/devis/" + retour.data.id)
                  );
                }}
                onClose={() => {}}
                body={
                  <div>
                    <div>
                      Veuillez choisir un taux de réussite pour le devis <br />
                    </div>

                    <Input
                      label="Réussite"
                      type="selectSearch"
                      value={this.state.reussite}
                      valueFieldToDisplay="valeur"
                      optionFieldToDisplay="valeur"
                      service={ReussiteService.getAll}
                      functionAppliedToValue={(value) => {
                        if (value) return value.designation;
                        return null;
                      }}
                      handleChange={(acc, value) =>
                        this.setState({ reussite: value })
                      }
                      required={true}
                    />
                    <Input
                      label="Date de commande prévisionnelle"
                      type="date"
                      value={this.state.date}
                      functionAppliedToValue={(value) => {
                        if (value)
                          return <div>{ToLocaleDateString(value)}</div>;
                        return null;
                      }}
                      handleChange={(acc, value) =>
                        this.setState({ date: value })
                      }
                      required={true}
                    />
                  </div>
                }
              />
            </div>
            <div>
              <Dialog
                open={this.state.openedDialogDupplicationConsultation}
                onClose={() =>
                  this.setState({
                    openedDialogDupplicationConsultation: false,
                  })
                }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <p>Voulez-vous dupliquer cette consultation ?</p>
                </DialogContent>
                <DialogActions>
                  <button
                    onClick={() =>
                      this.setState({
                        openedDialogDupplicationConsultation: false,
                      })
                    }
                    className="btn btn-danger"
                    type="button"
                  >
                    Non
                  </button>
                  <button
                    className="btn btn-primary"
                    autoFocus
                    type="button"
                    onClick={() =>
                      this.duplicateConsultation(this.state.consultation.id)
                    }
                  >
                    Oui
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsConsultation
            consultation={this.state.consultation}
            stateFieldNameToUpdate="consultation"
            handleChange={this.handleChange}
            service={ConsultationService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          ></InformationsConsultation>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isConsultationEmpty = !Object.entries(this.state.consultation).length;
    const creationContact = App.RightsGuard?.current?.hasRight(
      "Contact",
      "Creation"
    );

    return (
      <>
        <Helmet>
          <title>
            {"Consultation : "
              .concat(this.state.consultation.reference)
              .concat(" | ")
              .concat(this.state.consultation.nom_Consultation ?? "")}
          </title>
        </Helmet>
        <AlertDialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title="Il manque des champs obligatoires"
          body={
            <>
              <div>Il manque des champs à remplir pour créer un devis : </div>
              <div> {this.getChampsObligatoireVide().map((e) => e + ", ")}</div>
            </>
          }
          valider="Ok"
          handleClose={() => this.setState({ open: false })}
        />
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Consultation : "
                  .concat(this.state.consultation.reference)
                  .concat(" | ")
                  .concat(this.state.consultation.nom_Consultation ?? "")}
              </h4>
              <div className="d-flex">
                <Link
                  to="/consultations"
                  className="btn btn-primary nowrap ms-1"
                >
                  Base Consultation
                </Link>
                <ButtonIcon
                  icon={faCopy}
                  tooltip={"Dupliquer cette consultation"}
                  className="btn btn-success text-light ms-1"
                  onClick={() => {
                    this.setState({
                      openedDialogDupplicationConsultation: true,
                    });
                  }}
                />
                <ButtonIcon
                  icon={faFileInvoiceDollar}
                  className="btn btn-success text-light ms-1"
                  type="submit"
                  onClick={() => {
                    if (this.getChampsObligatoireVide()?.length == 0) {
                      this.state?.openDialogReussiteFunc();
                    } else {
                      this.setState({ open: true });
                    }
                  }}
                  tooltip="Créer un nouveau devis"
                ></ButtonIcon>
                {creationContact == "RW" ? (
                  <ButtonIcon
                    icon={faUserFriends}
                    className="btn btn-success text-light ms-1 "
                    type="submit"
                    onClick={this.createNewContact}
                    tooltip="Créer un nouveau contact"
                  ></ButtonIcon>
                ) : null}
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression pochette"
                  onClick={() => {
                    this.state.openDialogImpressionFunc();
                  }}
                />

                <ButtonIcon
                  icon={faDownload}
                  className="btn btn-success text-light ms-1"
                  tooltip="Extraire cette consultation"
                  onClick={() => {
                    ConsultationService.ExtractByID(
                      this.state?.consultation?.id
                    )
                      .then((res) => {
                        var blob = new Blob([res?.data], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(
                          blob,
                          this.state.consultation.nom_Consultation + ".xlsx"
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                        App.Toaster.current?.createToast({
                          body: "Impossible de générer le fichier",
                          header: "Echec",
                          type: "failure",
                        });
                      });
                  }}
                />
                {/* <ButtonIconWithValidation
                  icon={faTrash}
                  onClick={this.handleDelete}
                  className="form-control btn btn-danger ms-1"
                  tooltip="Supprimer cette consultation"
                  alertTitle=" Suppression"
                  alertBody={
                    <div>
                      Souhaitez-vous réellement supprimer cette consultation ?{" "}
                      <br />
                      (il sera supprimé pour tous ses clients)
                    </div>
                  }
                  alertButtonValidationText="Oui, je veux supprimer."
                ></ButtonIconWithValidation> */}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={this.state.consultation.actif}
              content={"Cette consultation n'est pas active"}
            >
              {" "}
            </WarningBar>
            {!isConsultationEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheConsultation.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheConsultation };
