import React from "react";

//import { App } from "App";

import { Input } from "_components/Input";

function returnColor(stringValue) {
  return stringValue == "NA"
    ? "#dc3545"
    : stringValue == "RO"
    ? "#ffc107"
    : stringValue == "RW"
    ? "#198754"
    : "black";
}

function LigneDroit(props) {
  return (
    <tr className={"lignedroits"}>
      <td>
        <div>{props.droit.module}</div>
      </td>
      <td>
        <div>{props.droit.onglet}</div>
      </td>
      <td
        className="col2 "
        style={{ backgroundColor: returnColor(props.droit.dgg) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".dgg"}
          value={props.droit.dgg}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col3"
        style={{ backgroundColor: returnColor(props.droit.dco) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".dco"}
          value={props.droit.dco}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col4"
        style={{ backgroundColor: returnColor(props.droit.dst) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".dst"}
          value={props.droit.dst}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col5"
        style={{ backgroundColor: returnColor(props.droit.ddo) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".ddo"}
          value={props.droit.ddo}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col6"
        style={{ backgroundColor: returnColor(props.droit.det) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".det"}
          value={props.droit.det}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col7"
        style={{ backgroundColor: returnColor(props.droit.drd) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".drd"}
          value={props.droit.drd}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col8"
        style={{ backgroundColor: returnColor(props.droit.aad) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".aad"}
          value={props.droit.aad}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col9"
        style={{ backgroundColor: returnColor(props.droit.agg) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".agg"}
          value={props.droit.agg}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col10"
        style={{ backgroundColor: returnColor(props.droit.arh) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".arh"}
          value={props.droit.arh}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col11"
        style={{ backgroundColor: returnColor(props.droit.rdo) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rdo"}
          value={props.droit.rdo}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col12"
        style={{ backgroundColor: returnColor(props.droit.rco) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rco"}
          value={props.droit.rco}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col13"
        style={{ backgroundColor: returnColor(props.droit.ret) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".ret"}
          value={props.droit.ret}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col14"
        style={{ backgroundColor: returnColor(props.droit.rqs) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rqs"}
          value={props.droit.rqs}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col15"
        style={{ backgroundColor: returnColor(props.droit.rrd) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rrd"}
          value={props.droit.rrd}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>

      <td
        className="col16"
        style={{ backgroundColor: returnColor(props.droit.rrh) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rrh"}
          value={props.droit.rrh}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col17"
        style={{ backgroundColor: returnColor(props.droit.rfi) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rfi"}
          value={props.droit.rfi}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col18"
        style={{ backgroundColor: returnColor(props.droit.rst) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".rst"}
          value={props.droit.rst}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col19"
        style={{ backgroundColor: returnColor(props.droit.sco) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".sco"}
          value={props.droit.sco}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col20"
        style={{ backgroundColor: returnColor(props.droit.sdo) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".sdo"}
          value={props.droit.sdo}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col21"
        style={{ backgroundColor: returnColor(props.droit.set) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".set"}
          value={props.droit.set}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col22"
        style={{ backgroundColor: returnColor(props.droit.sin) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".sin"}
          value={props.droit.sin}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col23"
        style={{ backgroundColor: returnColor(props.droit.sfi) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".sfi"}
          value={props.droit.sfi}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col24"
        style={{ backgroundColor: returnColor(props.droit.srd) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".srd"}
          value={props.droit.srd}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col25"
        style={{ backgroundColor: returnColor(props.droit.sqs) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".sqs"}
          value={props.droit.sqs}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col26"
        style={{ backgroundColor: returnColor(props.droit.udo) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".udo"}
          value={props.droit.udo}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col27"
        style={{ backgroundColor: returnColor(props.droit.uco) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".uco"}
          value={props.droit.uco}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col28"
        style={{ backgroundColor: returnColor(props.droit.urd) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".urd"}
          value={props.droit.urd}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col29"
        style={{ backgroundColor: returnColor(props.droit.uet) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".uet"}
          value={props.droit.uet}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td
        className="col30"
        style={{ backgroundColor: returnColor(props.droit.ust) }}
      >
        <Input
          accessor={"droits." + props.droit.id + ".ust"}
          value={props.droit.ust}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
    </tr>
  );
}

export { LigneDroit };
