import React from "react";
import { PropTypes } from "prop-types";

/**
 * Génère un composant boite
 * Cette boite possède un header et un body qui lui sont passés par les props
 */
class Box extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const content = (
      <>
        <div
          className="shadow m-2 mb-4 d-flex flex-grow-1"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={
              this.props.boxSize === "small"
                ? "bg-secondary row justify-content-between text-uppercase fw-bold text-light solwayFont titleFont"
                : "bg-primary row justify-content-between text-uppercase fw-bold text-light solwayFont titleFont"
            }
            style={{
              border: "1px solid black",
              borderBottom: "none",
              borderRadius: "15px 15px 0 0 ",
            }}
          >
            {this.props.header}
          </div>
          <div
            className={
              "scrollPetitEcran row tableauBodyInformations bg-white " +
              (this.props.noPaddingBottom ? "" : "pb-2 ") +
              (this.props.rowClass ?? "")
            }
            style={{
              flexGrow: 1,
              border: "1px solid black",
              placeContent: "flex-start",
              borderRadius: " 0 0 15px 15px",
            }}
          >
            {this.props.body}
          </div>
        </div>
      </>
    );
    return this.props.boxSize === "small" ? (
      <small className="d-flex flex-grow-1" style={{ fontSize: "smaller" }}>
        {content}
      </small>
    ) : (
      content
    );
  }
}

/**
 * @param {string} boxSize, taille de la boite (normal ou small) par défaut normal
 * @param {object} header, contenu du header (bandeau)
 * @param {object} body,  contenu du body
 * @param {bool} noPaddingBottom, ajout ou non de padding apres le body
 */
Box.propTypes = {
  boxSize: PropTypes.string,
  header: PropTypes.object,
  body: PropTypes.any,
  noPaddingBottom: PropTypes.bool,
  rowClass: PropTypes.string,
};

export { Box };
