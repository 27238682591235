import React from "react";
import { App } from "App";

import { Input } from "_components/Input";

import { Box, WarningBar } from "_components";
import { CommandeService } from "_services";

import { AccordionCommande } from "./AccordionCommande";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class InfoCommandes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callbackForceCommandes: null,
    };

    this.setForceUpdateCommandes = this.setForceUpdateCommandes.bind(this);
  }

  // pour set le refresh auto de la liste
  setForceUpdateCommandes(callbackFunction) {
    this.setState({
      callbackForceCommandes: callbackFunction,
    });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Commandes");
    if (right == "RW" || right == "RO") {
      if (this.props.loadingDevisCommandes) {
        return (
          <div className="d-flex justify-content-center py-2">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        );
      }
      return (
        <>
          {right == "RO" ? (
            <></>
          ) : (
            <div
              className="d-flex flex-row align-items-center mt-2"
              style={{
                width: "600px",
              }}
            >
              <div
                style={{
                  width: "280px",
                  flexShrink: "0",
                }}
              >
                Ajouter une commande existante :
              </div>
              <Input
                type="selectSearch"
                setForceUpdateFunction={this.setForceUpdateCommandes}
                service={() => {
                  return CommandeService.getCommandesByClientButNotInAnyAffaire(
                    {
                      size: 25,
                      actif: true,
                      num_Client: this.props.affaire?.client?.id,
                    }
                  );
                }}
                valueFieldToDisplay={["reference", "nom_Commande"]}
                optionFieldToDisplay={["reference", "nom_Commande"]}
                handleChange={(v, value) => {
                  if (value != null) this.props.handleCommandes(value, "post");
                  this.state.callbackForceCommandes();
                }}
              ></Input>
            </div>
          )}
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Commandes</div>
              </div>
            }
            body={
              <table className="table table-sm table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.affaire.commandes?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>Num</th>
                    <th>Date</th>
                    <th>Réf. article</th>
                    <th>Description</th>
                    <th>Quantité</th>
                    <th>Prix Unitaire</th>
                    <th>Prix HT</th>
                    <th>Prix TTC</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.affaire.commandes
                    ?.slice()
                    ?.sort(
                      (a, b) =>
                        new Date(b.date_Create) - new Date(a.date_Create)
                    )
                    .map((commande, index) => (
                      <AccordionCommande
                        index={index}
                        commande={commande}
                        key={index}
                        handleCommandes={(com, str) => {
                          this.props.handleCommandes(com, str);
                          this.state.callbackForceCommandes();
                        }}
                      />
                    ))}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { InfoCommandes };
