import React from "react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Validation des props
import { PropTypes } from "prop-types";

/**
 * Génère un composant bouton avec une icône et un texte optionnel à l'interieur et un texte en dessous du bouton
 */
class ButtonIconWithSmall extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id={this.props.id} className="form-group ms-1">
        <button
          onClick={this.props.onClick}
          className={"solwayFont " + this.props.className}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={this.props.tooltip}
        >
          <FontAwesomeIcon size={this.props.iconSize} icon={this.props.icon} />{" "}
          {this.props.textOption}
        </button>
        <small className="form-text text-muted d-block text-center mt-0">
          {this.props.smallText}
        </small>
      </div>
    );
  }
}

/**
 * Validation des props :
 * icon : icone de fontawesome
 * iconSize : taille gérées par fontawesome [xs, sm, lg, 2x, 3x, ...]
 * smallText : texte écrit en petit sous le bouton
 * onClick : fonction appliquée lors du click sur le bouton
 * id : id de la division comportant le bouton
 * className : classes à appliquer au bouton
 * textOption : texte à ajouter à côté de l'icône
 * tooltip : contenu du tooltip
 */
ButtonIconWithSmall.propTypes = {
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  smallText: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  textOption: PropTypes.any,
  tooltip: PropTypes.any,
};

export { ButtonIconWithSmall };
