import http from "http-common";

const FonctionPersonnelService = class {
  /**
   * Fonction permettant de récuperer toutes les fonctions personnel
   * @returns la liste de toutes les fonctions personnel
   */
  static getAll(params) {
    let url = "/FonctionsPersonnel";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une fonction personnel via son id
   * @param {*} id, id de la fonction personnel à récupérer
   * @returns la fonction personnel souhaitée
   */
  static getById(id) {
    return http.get("/FonctionsPersonnel/" + id);
  }
};

export { FonctionPersonnelService };
