import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./InformationsContact.css";
import { BoxInformationsInline } from "_components/FicheComponents/BoxInformationsInline";
import {
  IfNullToText,
  ToLocaleDateString,
  RedirectToDetailsNewTab,
  RedirectToDetails,
} from "_utils";
import {
  // PaysService,
  FonctionService,
  ContactService,
  ClientService,
  ServiceContactService,
  CiviliteService,
} from "_services";
import {
  // BoxMapLeaflet,
  Box,
  ButtonIcon,
  ButtonIconWithValidation,
  AlertDialog,
  BoxAdresse,
  WarningBar,
} from "_components";
import { faTrash, faPen, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Input } from "_components/Input";

class InformationsContact extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      erreur: false,
      edition: false,
    };

    this.renderClient = this.renderClient.bind(this);
    this.handleClickClient = this.handleClickClient.bind(this);
    this.handleClickNewTabClient = this.handleClickNewTabClient.bind(this);
    this.handleClickDeleteContactClient =
      this.handleClickDeleteContactClient.bind(this);
    this.handleAddClient = this.handleAddClient.bind(this);
  }

  handleClickNewTabClient(idClient) {
    RedirectToDetailsNewTab("/clients/", idClient);
  }

  handleClickDeleteContactClient(idClient) {
    if (this.props.contact.clients && this.props.contact.clients.length > 1) {
      ContactService.delete(this.props.contact, idClient).then(() => {
        this.props.updateClient({ id: idClient }, "delete");
      });
    } else {
      this.setState({ erreur: true });
    }
  }

  handleClickClient(idClient) {
    RedirectToDetails(this.props.history, "/clients/", idClient);
  }

  handleAddClient(acc, selectOption) {
    if (selectOption != null)
      ContactService.link(this.props.contact, selectOption.id)
        .then(() => {
          this.props.updateClient(selectOption, "link");
        })
        .catch((error) => {
          console.error(error);
        });
  }

  renderClient() {
    const right = App.RightsGuard?.current?.hasRight("Contact", "General");

    return (
      <Box
        header={
          <>
            <div className="col-8 align-self-center py-2">
              Liste des clients
            </div>
            {right == "RW" ? (
              <div className="col-4 text-end p-1 align-self-center">
                <div role="group" className="me-3">
                  <ButtonIcon
                    icon={faCheck}
                    className="btn btn-success btn-sm text-light ms-1"
                    onClick={() => {
                      this.props.handleEditing(false);
                      this.setState({ edition: false });
                    }}
                    style={
                      !this.props.editing
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                    tooltip="Arrêter l'édition"
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faPen}
                    className="btn btn-secondary btn-sm text-light ms-1"
                    onClick={() => {
                      this.props.handleEditing(true);
                      this.setState({ edition: true });
                    }}
                    style={
                      this.props.editing
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                  ></ButtonIcon>
                </div>
              </div>
            ) : null}
          </>
        }
        body={
          <>
            {this.props.contact.clients.map((client, index) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between mt-2"
                  key={index}
                >
                  <a href={"/clients/" + client.id}>
                    <span>{client.nom_Client}</span>
                    <span style={{ marginLeft: "30px" }}>
                      {client.adresse_Livraison_Principale?.cp
                        ? client.adresse_Livraison_Principale.cp + " "
                        : ""}
                      {client.adresse_Livraison_Principale?.ville
                        ? client.adresse_Livraison_Principale.ville + " "
                        : ""}
                    </span>
                  </a>{" "}
                  <div className="text-end">
                    <ButtonIconWithValidation
                      icon={faTrash}
                      className="btn btn-danger btn-sm text-light ms-1"
                      onClick={() => {
                        this.handleClickDeleteContactClient(client.id);
                      }}
                      style={
                        right != "RW" || !this.state.edition
                          ? { display: "none" }
                          : { minWidth: "32px" }
                      }
                      alertTitle="Suppression"
                      alertBody="Souhaitez-vous réellement supprimer ce client pour ce contact ?"
                      alertButtonValidationText="Oui, je veux supprimer."
                    ></ButtonIconWithValidation>
                  </div>
                </div>
              );
            })}
            {right == "RO" || !this.state.edition ? (
              <></>
            ) : (
              <div>
                <div>Ajouter un client :</div>
                <Input
                  type="selectSearch"
                  service={(p) =>
                    ClientService.getAll({ global: p, size: 100 })
                  }
                  valueFieldToDisplay={["nom_Client", "email"]}
                  optionFieldToDisplay={["nom_Client", "email"]}
                  handleChange={this.handleAddClient}
                ></Input>
              </div>
            )}
          </>
        }
      />
    );
  }

  render() {
    let canbeDistributor = false;
    this.props.contact.clients?.map((client) => {
      canbeDistributor =
        canbeDistributor || client.etat.qualification == "Distributeur";
    });

    const right = App.RightsGuard?.current?.hasRight("Contact", "General");
    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Civilité",
          accessor: "civilite",
          type: "selectSearch",
          service: CiviliteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 4,
        },
        {
          label: "Nom",
          accessor: "nom_Contact",
          type: "text",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return <div>{value?.toUpperCase()}</div>;
          },
        },
        {
          label: "Prénom",
          accessor: "prenom_Contact",
          type: "text",
          colSize: 4,
        },
        {
          label: "Email",
          accessor: "email",
          type: "email",
          colSize: 4,
        },
        {
          label: "Email bis",
          accessor: "email2",
          type: "email",
          colSize: 4,
        },
        {
          label: "LinkedIn",
          accessor: "linkedIn",
          type: "text",
          colSize: 4,
          functionAppliedToValue: (value) => {
            if (value)
              return (
                <a
                  href={value}
                  target="_blank"
                  data-text={"voir le profil"}
                  className="fixWidthHover"
                >
                  voir le profil
                </a>
              );
            else return null;
          },
        },
        {
          label: "Portable",
          accessor: "portable",
          type: "text",
          colSize: 4,
        },
        {
          label: "Standard",
          accessor: "standard",
          type: "text",
          colSize: 4,
        },
        {
          label: "Ligne directe",
          accessor: "ligne_Directe",
          type: "text",
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "À supprimer",
          accessor: "a_Supprimer",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];
      const informationsFonction = [
        {
          label: "Fonction",
          accessor: "fonction",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: FonctionService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Commentaire",
          accessor: "commentaire",
          type: "textArea",
          functionAppliedToValue: (el) => {
            if (el != null) {
              return <div className="keepWhiteSpace">{el}</div>;
            }
          },
          colSize: 6,
        },
        {
          label: "Service",
          accessor: "service",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: ServiceContactService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
      ];

      const informationsAbonnements = [
        {
          label: "Newsletter",
          accessor: "newsletter",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Informations gratuites",
          accessor: "voeux",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Comm. commerciales",
          accessor: "vip",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];

      const informationsUtilisateur = [
        {
          label: "Apprenant",
          accessor: "apprenant",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },

        {
          label: "Geoshare",
          accessor: "userGeoshare",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },

        {
          label: "Materiel",
          accessor: "userMateriel",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },

        {
          label: "WEBsprint",
          accessor: "userWEBSprint",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        ...(canbeDistributor
          ? [
              {
                label: "Est distributeur",
                accessor: "isDistributor",
                type: "checkbox",
                functionAppliedToValue: (value) => {
                  return value ? "Oui" : "Non";
                },
                colSize: 4,
              },
            ]
          : []),
        ,
      ];
      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(this.props.contact.date_Create) +
                  " par " +
                  IfNullToText(this.props.contact.user_Create?.nom, "inconnu") +
                  " " +
                  IfNullToText(this.props.contact.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(this.props.contact.date_Modif) +
                  " par " +
                  IfNullToText(this.props.contact.user_Modif?.nom, "inconnu") +
                  " " +
                  IfNullToText(this.props.contact.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];

      return (
        <>
          <AlertDialog
            title="Erreur"
            body="Vous ne pouvez pas supprimer le dernier client de ce contact. Les contacts orphelins sont interdis."
            valider="Ok"
            open={this.state.erreur}
            handleClose={() => {
              this.setState({ erreur: false });
            }}
          />
          <div className="row">
            <div className="col-12">{this.renderClient()}</div>
            <div className="col-12">
              <BoxInformationsInline
                title="général"
                datas={informationsGeneral}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Fonction"
                datas={informationsFonction}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Abonnements"
                datas={informationsAbonnements}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Compétence utilisateur"
                datas={informationsUtilisateur}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxAdresse
                title="Adresse"
                datas={[
                  {
                    value: this.props.contact.adresse,
                    label: "Adresse du contact",
                    functionAppliedToValue: () => {
                      return (
                        <>
                          {this.props.contact.adresse?.intitule ?? ""}
                          <br />
                          {this.props.contact.adresse?.voie ?? ""}
                          <br />
                          {this.props.contact.adresse?.complement ?? ""}
                          <br />
                          {(this.props.contact.adresse?.cp ?? "") +
                            " " +
                            (this.props.contact.adresse?.ville ?? "")}
                          <br />
                          {this.props.contact.adresse?.pays?.nom_Fr ?? ""}
                          <br />
                        </>
                      );
                    },
                    accessor: "adresse",
                    colSize: 12,
                    type: "address",
                    optionFieldToDisplay: "nom_Fr",
                  },
                ]}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              {/* <BoxInformationsInline
              title="Adresse"
              datas={informationsAdresse}
              state={this.props.contact}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              colSize="4"
              editing={this.props.editing}
              handleEditing={this.props.handleEditing}
            /> */}
              {/* <BoxMapLeaflet
              editing={this.props.editing}
              handleEditing={this.props.handleEditing}
              voie={voie}
              ville={ville}
              codePostal={codePostal}
              pays={pays}
            /> */}
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.contact}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

InformationsContact.propTypes = {
  contact: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsContact };
